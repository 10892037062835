import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import MainHeader from './MainHeader'

import FAQ from './Components/FAQ'

import MainFooter from './MainFooter'



import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'

import pricing from './css/Pricing.module.scss'


import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';



const faqlist = [
  { title: 'Can I switch from the free plan to the premium plan at any time?', description: "Absolutely! We understand that your needs may change over time, and we want to give you the freedom to switch between our premium and free plans as per your requirements. You can easily manage your plan by logging into your account and accessing your account setting." },
  { title: 'Are there any limitations on the number of surveys or responses in the premium plan?', description: "No, there are no limitations on the number of surveys or responses you can create or collect with our premium plan. We believe in empowering our users to gather as much feedback and insights as they need, without any restrictions." },
  { title: 'Can I cancel my premium subscription at any time?', description: "Absolutely! We understand that circumstances may change, and you may need to adjust your subscription. With Pepperform, you have full control over your premium subscription, and you can cancel it at any time. Simply visit your account settings and follow the straightforward cancellation process. Please note that you will continue to have access to the premium features until the end of your current billing period, and after that, your subscription will be switched to our free plan." },
]




function Pricing() {
  const [scrollY, setScrollY] = useState(0);
  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  useEffect(() => {

    // Add a scroll event listener to update the scrollY state
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const abc = window.innerHeight;
    };

    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://pepperform.net/pricing" />
    <meta name="keywords" content="survey pricing, subscription plans, survey tool costs, pricing options, affordable surveys" />
    <meta name="description" content="Explore Pepperform's pricing options and subscription plans for our powerful survey tool. Find the best pricing plan that suits your needs and start gathering valuable insights today." />
    <meta property="og:title" content="Survey Pricing and Subscription Plans | Pepperform" />
    <title>Survey Pricing and Subscription Plans | Pepperform</title>
    <meta property="og:description" content="Explore Pepperform's pricing options and subscription plans for our powerful survey tool. Find the best pricing plan that suits your needs and start gathering valuable insights today." />
    </Helmet>
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader  scrolled={scrollY > 100 && true} page="features" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        
        <section className={pricing.intro}>
            <h1>Unlock Your Potential with Premium</h1>
            <p>Choose the plan that suits you best - our free plan for basic functionality or unlock advanced features with our premium plan.</p>
            {/* <GoogleOAuthProvider clientId="665765714587-rgpdeb4smooac8tug0d78dgssrq0dd87.apps.googleusercontent.com">..
            
            <GoogleLogin
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />;
            </GoogleOAuthProvider>; */}
        <section className={pricing.container}>
            <div className={pricing.box}>
                <h2>Free</h2>
                <h3>$0/month</h3>
                <p>Create basic surveys</p>

                <h4>What you get</h4>
                <ul>
                    <li>20 questions per form</li>
                    <li>15+ field types</li>
                    <li>Unlimited responses</li>
                    <li>Basic customisation</li>
                    <li>Upload your logo</li>
                    <li>3 Free Forms</li>
                </ul>
            </div>
            <div className={pricing.box +" "+ pricing.highlight}>
                <h2 className={pricing.highlight}>Premium</h2>
                <h3 className={pricing.highlight}>$18/month</h3>
                <p>Access premium customisation</p>

                <h4>What you get</h4>

                <ul>
                    <li>Unlimited questions per form</li>
                    <li>All field types</li>
                    <li>Unlimited responses</li>
                    <li>Redirection</li>
                    <li>Premium Customer Support</li>
                    <li>Unlimited forms</li>
                </ul>
            </div>
        </section>
        </section>

        <FAQ headlline={"yes"} source={faqlist} />
        <MainFooter />

      </main>

    </div>
    </>
  );
}

export default Pricing;
