import React, { useState } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import blog from './css/Blog.module.scss'
import mainImage from './images/cxarticle.jpg'


const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Pre-Webinar Survey: Tell Us What You Want to Learn",category: "template",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "Pre-Webinar Survey: Tell Us What You Want to Learn", link: "https://pepperform.net/template/feedback/Pre-Webinar-Survey" }] },
]





function Article5() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
      <header className={blog.header}>
            <h1>5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints</h1>
            <p>
            <h2>What is a customer success managers? </h2>
            A Customer Success Manager is your ultimate ally in the world of business. They go beyond the transactional aspect and become a strategic and supportive partner for your customers throughout their entire journey. Their primary goal is to foster strong relationships and build customer loyalty that extends far beyond a single purchase. With their expertise and dedication, Customer Success Managers ensure long-term client retention and satisfaction. They are the driving force behind maximizing customer value and helping your business thrive.
            </p>

            <img src={mainImage} alt="Plant" loading="lazy" />

          </header>
      <main className={blog.wrapper}>
        <section className={blog.blog_post}>

          <article>
            <h1>What traits make the best customer success managers?</h1>
                <p>Effective Customer Success Managers possess a range of key qualities and skills to drive customer satisfaction, retention, and overall business success.
                    <ul>
                        <li>Strong project management abilities, balancing tasks across sales and support teams, and assigning work strategically.</li>
                        <li>Proactive mindset, identifying potential issues and taking timely actions to prevent customer dissatisfaction and reduce churn rates.</li>
                        <li>High emotional intelligence, understanding and empathizing with customers and team members, building strong relationships.</li>
                        <li>Embracing automation tools to enhance efficiency, streamlining repetitive tasks, and enabling more focus on strategic customer engagements.</li>
                        <li>Utilizing account intelligence from advanced tools and Customer Success platforms, leveraging data for predicting risks, opportunities, and delivering exceptional outcomes.</li>
                    </ul>
                </p>
                <h1>Questions to Transform Customer Touchpoints</h1>
                <h2>"What sets us apart?"</h2>
                <p>The first question our superhero Customer Managers ask is about the unique The first inquiry in the arsenal of elite Customer Managers focuses on identifying the distinctive factors that set their organization apart. By understanding their unique value proposition, they can uncover the key elements that elicit customer admiration. Whether it's unrivaled expertise, personalized solutions, or seamless processes, recognizing their competitive edge enables them to transform touchpoints into memorable experiences.
                </p>
                <h2>"How can we make it effortless for you?"</h2>
                <p>
                Effortless is the name of the game when it comes to exceptional customer experiences. The best Customer Managers seek to eliminate friction and make every interaction as smooth as silk. They ask customers how they can simplify processes, streamline communication, and ensure a hassle-free journey. By minimizing effort, they pave the way for seamless touchpoints that leave customers feeling valued and appreciated.
                </p>
                <h2>"What's missing from the picture?"</h2>
                <p>
                To truly transform customer touchpoints, our Customer Managers have a keen eye for identifying gaps. They ask customers what's missing or what could be improved to make their experience even better. Whether it's a feature, a service, or a unique offering, this question helps uncover untapped opportunities to exceed customer expectations and set new industry standards.
                </p>
                <h2>"How can we craft delightful surprises?"</h2>
                <p>Elite Customer Managers understand the impact of delightful surprises in forging lasting connections. They actively engage customers to discover opportunities for unexpected moments of delight. From personalized gestures to exclusive benefits, these surprises turn ordinary touchpoints into remarkable ones, fostering emotional bonds and cultivating customer loyalty.
                </p>
                <h2>"What are your ultimate objectives?"</h2>
                <p>
                Champion Customer Managers act as catalysts for their customers' success. By exploring customers' ultimate goals and aspirations, they gain invaluable insights into their desired outcomes. Armed with this understanding, they align touchpoints with customers' objectives, making each interaction purposeful, results-oriented, and deeply impactful.
                </p>
                <h1>Conclusion</h1>
                <p>
                Unlocking the potential of customer touchpoints lies in the hands of exceptional Customer Managers. Empowered by these game-changing questions, they wield their strategic prowess to craft extraordinary experiences that resonate with customers. As fellow professionals, let us embrace these transformative inquiries, elevate our customer interactions, and redefine the landscape of customer success. Together, we can embark on a journey of advancement, unlocking unparalleled levels of customer satisfaction and loyalty.
                </p>
                <p>
                Remember, the power to transform customer touchpoints lies within your grasp. Embrace these strategic questions and unleash your expertise to create customer experiences that leave a lasting impression. Your dedication to excellence will set you apart as a visionary Customer Manager, driving sustainable growth and prosperity.

                </p>
          </article>
        </section>
        <section className={blog.reco} >
          <div className={blog.quote}>
          Customer insights are the compass that guides product managers on their journey toward building exceptional products.
          </div>
              {/* {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })} */}

        </section>
      </main>
      {/* <TemplatesCarousel /> */}
      <MainFooter/>
      </div>

    </div>
  );
}

export default Article5