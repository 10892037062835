import React, { useState, useEffect } from 'react';

import Fade from 'react-reveal/Fade';

import MainHeader from './MainHeader'
import { Helmet } from 'react-helmet';

import reportImage from './images/report2.png'

import ArticleBrowser from './Components/ArticleBrowsers'
import FAQ from './Components/FAQ'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'
import styles from "./css/NewHomepage.module.scss";
import unlimited from "./images/unlimited.svg";
import notification from "./images/notification.svg";
import lifebuoy from "./images/lifebuoy.svg";
import formIcon from "./images/contact-form.svg";

import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import plaintext from './css/PlainTextBlock.module.scss'
import twocolblock from './css/TwoColBlock.module.scss'
import featurehighlight from './css/FeatureHighlight.module.scss'


import drag from './images/fields.gif'
import feature_highlight from './images/mockuuups-1.jpg'
import mockupImage from './images/classic.jpg'
import customerSupportImage from './images/hero_desktop_1.jpg'


const articlefeatures = [
  // {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
  // {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
  // {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
  {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
  {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
  {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
  {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
  {title:"Customer Success Stories: How Forms Can Capture and Share Success Stories", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/customer-success-stories-how-forms-can-capture-and-share-success-stories" }] },  
]


const faqlist = [
  { title: 'Why use Pepperform?', description: "Pepperform is the go-to online form builder for creating engaging, and visually captivating forms. Whether you're creating interactive surveys, contact lists, shopping carts, or quizzes, Pepperform offers a versatile platform with powerful design tools and data management tools." },
  { title: 'How do I create and send an online customer survey?', description: "Creating and sending an online customer survey with Pepperform is a simple and efficient process. Start by signing in to your account or creating a new one if you don't have an existing account. Once logged in, click on the Create Form button to begin building your survey. You have the option to start from scratch or use a pre-designed template. Customize your survey by adding various types of questions to gather the desired information. Personalize the survey's appearance by customizing the branding, including your logos and colors. Once your survey is ready, click on the Share button to start collecting responses. You can easily track and monitor the survey results in real-time through your reporting dashboard." },
  { title: 'How much does Pepperform cost?', description: "We offer both free and premium plans, allowing you to choose the option that best fits your requirements." },
  { title: 'What is the benefit of an online survey builder?', description: "Online surveys are a game-changer for those who want to do their own research. They're a time and money saver, delivering speedy results. You don't need developers or tech skills to get your survey up and ready to share. With just a few clicks, you can create engaging surveys that perfectly fit your needs." },
  { title: 'How can Pepperform help improve our customer satisfaction levels?', description: "By collecting their insights, you gain a deeper understanding of their needs and preferences, allowing you to tailor your products, services, and customer experiences to meet their expectations. Pepperform offers a comprehensive set of features and tools designed to enhance customer satisfaction. With our intuitive survey builder, you can create engaging and personalized surveys to gather valuable feedback from your customers." },
  { title: 'What support options are available if we encounter any issues with Pepperform?', description: "At Pepperform, we prioritize customer support and ensure that assistance is readily available when you need it. Our dedicated support team is ready to help you with any questions, technical issues, or concerns you may have." },
]


function SolutionContact() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  const [scrollY, setScrollY] = useState(0);
  const [thumbnails, setThumbnails] = useState([]); // Define the thumbnails state variable
  const [browserHeight, setBrowserHeight] = useState(false); // Initialize with false for each word

  useEffect(() => {

    // Add a scroll event listener to update the scrollY state
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const abc = window.innerHeight;
      setBrowserHeight(abc);
    };

    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    document.title = 'Transform Your Contact Forms into a Lead Magnet';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <div className={featurehighlight.content_solution}>
        <Helmet>
            <link rel="canonical" href="https://pepperform.net/Solution/Contact-Forms" />
            <meta name="description" content="Your contact forms are more than just a way to collect information – they're your direct line to your prospects. That's why we've crafted Pepperform to be more than a solution; our rich blocks will transform for your contact forms into a rich experience." />
            <meta property="og:title" content="Transform Contact Forms into a Brand Experience" />
            <meta property="og:description" content="Your contact forms are more than just a way to collect information – they're your direct line to your prospects. That's why we've crafted Pepperform to be more than a solution; our rich blocks will transform for your contact forms into a rich experience." />
        </Helmet>
        <MainHeader  scrolled={scrollY > 100 && true} page="solution" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        <section 
        style={{backgroundSize:'cover',background:`url('https://images.unsplash.com/photo-1677611998429-1baa4371456b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2832&q=80')`}}
        className={plaintext.containerHero + " " + plaintext.hero}>
          <div className={plaintext.containerHero__content}>
            <div>
              <h2>Contact forms</h2>
              <h1>Transform Your Contact Forms into a Lead Magnet</h1>
            </div>
            <div>
              {/* <h3>Effortless Feedback Capture with Customizable Contact Form Templates</h3> */}
              <p>We know that contact forms are more than just a way to collect information – they're your direct line to your prospects. That's why we've crafted Pepperform to be more than a solution; our rich blocks will transform for your contact forms into a rich experience.</p>
            </div>
          </div>
        </section>

        <section className={featurehighlight.wrapper}>
        {/* <div className={featurehighlight.container}>
            <div className={featurehighlight.images}>
              <img src={feature_highlight} alt="Feature highlight" loading="lazy" />
            </div>
            <header className={featurehighlight.header}>
              <Fade up distance={"32px"}>
                <h2>Give your customer a voice</h2>
              </Fade>
              <Fade up distance={"32px"} delay="250">
                <h3 style={{lineHeight:1.65}}>Unveiling the Power of Contact Forms: Direct Dialogue, Deeper Connection</h3>
              <p>Imagine your online product as a bustling marketplace of ideas, where visitors' thoughts can be heard through the medium of contact forms. This provides an avenue for individuals to engage with your brand on their own terms, without the pressure of immediate response.</p>
              <p>Our contact forms permit visitors to communicate with your brand based on their conditions and act as a platform for genuine connections. By bridging the gap between you and your customers, this open discussion uncovers preferences, issues, and objectives that can guide your items and strengthen your brand.
                
              </p>
              <p><a style={{color:"white"}} href="https://app.pepperform.net/templates">View this template</a> to see what you can create with our Survey Builder tool.</p>

              </Fade>
            </header>

          </div> */}

          <div className={featurehighlight.container} style={{paddingBottom:'0'}}>
          <Fade down><h2>Why Choose Pepperform for your Contact Forms?</h2>
          <img className={featurehighlight.image_report} src={reportImage} alt="An image of the Pepperform reporting feature" loading="lazy" />
          </Fade>

          </div>

          <header className={featurehighlight.feature_content}>
            <div>
            <Fade left distance="32px">
                <h3>1. Add your brand</h3>
                <p>Your brand is unique, and your contact forms should reflect that. Pepperform empowers you with customization options that let you tell your story your way. From captivating visuals to compelling text, it's all within your control.</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>2. A human centered design</h3>
                <p>User experience matters. Pepperform prioritizes ease of use, ensuring that visitors to your site can reach out without hesitation. We believe that the smoother the experience, the more meaningful the connection.</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>3. Insights That Matter</h3>
                <p>Data is the heartbeat of your online strategy. Pepperform's robust analytics and reporting tools provide you with actionable insights. Understand your audience better, and make decisions based on data, not guesswork.</p>
              </Fade>
            </div>
            </header>

            <div className={featurehighlight.fullImage}>
              <img src={mockupImage} alt="Professionals viewing and collaborating over their Pepperform Survey" loading="lazy" />
            </div> 
            <div className={`${styles.screenWidePanel}`}>
        <div>
          <div
            className={styles.screenWidePanel_content__small}

          >
            <h2>Our Freemium Doesn't Suck</h2>
            <p>
              A lot of free online survey form services these days are too
              limited. You're pushed to upgrade to paid plans to access even
              basic features. At Pepperform, we provide you with access to a
              free, unlimited version that includes all the meaningful features.
            </p>
          </div>
          <ul className={styles.freemiumDetails}>
            <li>
              <img src={formIcon} />
              <p>
                <strong>5 Free Forms</strong>
                Create 5 forms for free, no questions asked.
              </p>
            </li>
            <li>
              <img src={unlimited} />
              <p>
                <strong>Unlimited Responses</strong>
                Collect as many responses as you need
              </p>
            </li>
            <li>
              <img src={notification} />
              <p>
                <strong>Be notified</strong>
                Access the full reporting features
              </p>
            </li>
            <li>
              <img src={lifebuoy} />
              <p>
                <strong>Be supported</strong>
                Our support team is here to help
              </p>
            </li>
          </ul>
          <a href="https://app.pepperform.net/register" className={styles.primaryCta}>Sign up FREE</a>
          <span className={styles.primaryCta_helper}>
            No credit card required
          </span>
        </div>
      </div>
        </section>
        <section className={twocolblock.container + " " + twocolblock.left_image}>

          <div className={twocolblock.image_area} style={{ backgroundColor: "#676584" }}>
            <div className={twocolblock.image}>
              <img src={drag} alt="Customise to your heart's content" />
            </div>
            <div className={twocolblock.image_description_container}>
              <div className={twocolblock.image_description}>
                <h2>24</h2>
                <p>Question types</p>
              </div>
              <div className={twocolblock.image_description}>
                <h2>Unlimited</h2>
                <p>Responses</p>
              </div>
            </div>
          </div>
          <div className={twocolblock.description_area}>
              <Fade left distance="32px">
                <h2>Start with a Template or Craft it all from scratch</h2>
              </Fade>
              <Fade left distance="32px" delay="250">
                <p style={{paddingTop:"80px"}}>
                With our Contact Form Templates, you can simplify collecting client feedback and improve customer engagement. You can design surveys altered to your requirements and gather smart information during the entire client experience.

                <a href="https://app.pepperform.net/templates">Browse our Contact Form Templates</a>
                </p>
              </Fade>
          </div>

        </section>
        {/* <ArticleBrowser articles={articlefeatures} /> */}
        {/* <TemplatesCarousel title="feedback" /> */}

        <section className={twocolblock.container + " " + twocolblock.right_image} >
          
          <div className={twocolblock.description_area}>
            <h2>Craft Compelling Narratives</h2>
            <p>Pepperform empowers you to go beyond the ordinary and transform your contact forms into engaging narratives. With our platform, it's not just about asking questions; it's about telling your story. Seamlessly integrate text, images, and videos into your forms to captivate your audience and convey your message effectively. 
            <a href="https://app.pepperform.net/templates">Browse our Contact Form Templates</a>

              .</p>
          </div>
          <div className={twocolblock.image_area} style={{ backgroundColor: '#a4c3c4' }}>
            <div className={twocolblock.image +" "+twocolblock.image_full }>
              <img src={customerSupportImage} alt="Pepperform Slideshow Survey on a Desktop app" loading='lazy' />
            </div>

          </div>

        </section>
        <FAQ source={faqlist} />
        <MainFooter />

      </main>
      </div>
    </div>
  );
}

export default SolutionContact;
