import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import mainNav from './css/MainNav.module.scss';
import MainHeaderSimple from './MainHeaderSimple';
import layout from './css/Layout.module.scss';
import questionstyle from './css/Questions.module.scss';
import cat1 from './images/cat/1.png';
import cat2 from './images/cat/2.png';
import cat3 from './images/cat/3.png';
import cat4 from './images/cat/4.png';
import cat5 from './images/cat/5.png';
import cat6 from './images/cat/6.png';
import check from './images/cat/check.svg';
import copy from './images/cat/copy.svg';
import happycat from './images/cat/happycat.png';
import catfriend from './images/cat/catfriend.png';
import fish from './images/cat/fish1.png';
import fish2 from './images/cat/fish2.png';
import paw from './images/cat/paw.png';
import nerve from './images/cat/nerve.png';
import scissors from './images/cat/scissors.png';
import scissors2 from './images/cat/scissors2.png';

const cats = [cat1, cat2, cat3, cat4, cat5];


const questions = [
  {
    "title": "What goals do you want to achieve with our product?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "What features are you here for?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "What made you select our product?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "What is your budget for this project?",
    "category": "Decision makers",
    "tone": "Formal"
  },
  {
    "title": "Who will be the primary contact for this project?",
    "category": "Decision makers",
    "tone": "Formal"
  },
  {
    "title": "What is the deadline for this project?",
    "category": "Decision makers",
    "tone": "Formal"
  },
  {
    "title": "What excites you most about our product?",
    "category": "Adoption goals",
    "tone": "Excited"
  },
  {
    "title": "How many products have you tried before this one?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "Who will be making the purchase decision in your team?",
    "category": "Decision makers",
    "tone": "Formal"
  },
  {
    "title": "What products have you tried before signing up with us?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "What is your job title?",
    "category": "Decision makers",
    "tone": "Formal"
  },
  {
    "title": "Who are your target audience?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "Why did you choose to work with us?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "Will you use the product alone or with a team?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "Does anyone else in your team want to join the trial?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "What kinds of support are you interested in?",
    "category": "Support needs",
    "tone": "Supportive"
  },
  {
    "title": "What features are you excited to trial?",
    "category": "Adoption goals",
    "tone": "Excited"
  },
  {
    "title": "What are the problems that we can help with?",
    "category": "Pain points",
    "tone": "Supportive"
  },
  {
    "title": "Do you have any KPIs for this project?",
    "category": "Adoption goals",
    "tone": "Formal"
  },
  {
    "title": "Do you think anyone else is interested in joining the trial?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "Have you worked with a similar product/service in the past? If so, what made you choose us?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "First, let’s set up your team. List out all of your team’s emails here so that we can send them access to the trial.",
    "category": "Support needs",
    "tone": "Formal"
  },
  {
    "title": "👋 Welcome [Name], select a goal, so that we can tailor your trial experience for it.",
    "category": "Adoption goals",
    "tone": "Friendly"
  },
  {
    "title": "We want you to get what you’re here for quicker. What is your goal?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "Looks like your trial is underway! Are you interested in any extra support? We won’t be charging you for anything.",
    "category": "Support needs",
    "tone": "Friendly"
  },
  {
    "title": "We saw you started a launch page. Can we offer you any help in completing it?",
    "category": "Support needs",
    "tone": "Supportive"
  },
  {
    "title": "Your trial, your way. What can we do to make the trial yours?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "Hey [Name], we haven’t seen any activity for a few weeks. How are you doing with your trial?",
    "category": "Adoption goals",
    "tone": "Friendly"
  },
  {
    "title": "Nice to see you again! ✨ Are you interested in trying our new [Feature name] feature?",
    "category": "Adoption goals",
    "tone": "Friendly"
  },
  {
    "title": "Now that you’re trialing our product, what actions are you able to take that you couldn’t do before?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "What other workarounds did you use before coming to us?",
    "category": "Pain points",
    "tone": "Supportive"
  },
  {
    "title": "What can we do to personalize your experience?",
    "category": "Adoption goals",
    "tone": "Supportive"
  },
  {
    "title": "Ok [Name], what are you here for?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "What pain points are you trying to solve with our product?",
    "category": "Pain points",
    "tone": "Formal"
  },
  {
    "title": "Are you trialing any other products at the moment?",
    "category": "Adoption goals",
    "tone": "Casual"
  },
  {
    "title": "What is preventing you from achieving [Customer Goal]?",
    "category": "Pain points",
    "tone": "Formal"
  },
  {
    "title": "Why is your current solution not working for you?",
    "category": "Pain points",
    "tone": "Formal"
  }
];


function QuestionBank() {
  const filterQuestions = () => {
    let filtered = questions.filter((question) => {
      // Filter by selected category
      if (selectedCategory !== 'All' && question.category !== selectedCategory) {
        return false;
      }

      // Filter by search term
      return question.title.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return filtered;
  };

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All'); // Initialize with 'All'
  const [filteredQuestions, setFilteredQuestions] = useState(questions); // Declare filteredQuestions as a state variable
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolled1, setIsScrolled1] = useState(false);
  const [isScrolled2, setIsScrolled2] = useState(false);
  const [isScrolled3, setIsScrolled3] = useState(false);
  const [isScrolled4, setIsScrolled4] = useState(false);
  const [visibleCats, setVisibleCats] = useState(2); // Number of visible cats based on scroll position
  const [moveCat, setMoveCat] = useState(false); // Number of visible cats based on scroll position
  const [showAdoptionGoalsCat, setShowAdoptionGoalsCat] = useState(false);
  const [isCopiedList, setIsCopiedList] = useState(new Array(filteredQuestions.length).fill(false));
  const [showFish, setShowFish] = useState(false);
  const [showFish2, setShowFish2] = useState(false);
  const [showFish3, setShowFish3] = useState(false);
  const [showPainCat, setShowPainCat] = useState(false);
  const [showSupportCat, setShowSupportCat] = useState(false);
  const [showDecisionCat, setShowDecisionCat] = useState(false);
  const [showHappyCat, setShowHappyCat] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThresholdCat3 = 40; // Show cat3
      const scrollThresholdCat4 = 60; // Show cat4 and cat5
      const scrollThresholdCat5 = 80; // Show cat4 and cat5
      const scrollThresholdCat6 = 130; // Show cat4 and cat5
      const scrollThresholdCat7 = 170; // Show cat4 and cat5
      const scrollThresholdCat8 = 220; // Show cat4 and cat5
      const scrollThresholdCat9 = 280; // Show cat4 and cat5
      const scrollThresholdCat10 = 320; // Show cat4 and cat5
  
      if (scrollTop < scrollThresholdCat3) {
        setMoveCat(false);
        setVisibleCats(2); 
        setShowFish(false)
        setShowFish2(false)
        setShowPainCat(false)
        setShowAdoptionGoalsCat(false);
        setShowSupportCat(false);
        setShowDecisionCat(false);
        setShowHappyCat(false);
      } else if (scrollTop >= scrollThresholdCat3 && scrollTop < scrollThresholdCat4) {
        setVisibleCats(3); 
        setMoveCat(false);
        setShowAdoptionGoalsCat(false);
        setShowFish(true)
        setShowPainCat(false)
        setShowFish2(false)
        setShowDecisionCat(false);
        setShowHappyCat(false);
      } else if (scrollTop >= scrollThresholdCat4 && scrollTop < scrollThresholdCat5)  {
        setVisibleCats(4); 
        setMoveCat(false);
        setShowFish(true)
        setShowFish2(false)
        setShowPainCat(false)
        setShowAdoptionGoalsCat(false);
        setShowDecisionCat(false);
        setShowHappyCat(false);
      }else if (scrollTop >= scrollThresholdCat5 && scrollTop < scrollThresholdCat6)  {
        setVisibleCats(4); 
        setMoveCat(true);
        setShowFish(true)
        setShowFish2(false)
        setShowPainCat(false)
        setShowAdoptionGoalsCat(true);
        setShowSupportCat(false);
        setShowDecisionCat(false);
        setShowHappyCat(false);

      } else if (scrollTop >= scrollThresholdCat6 && scrollTop < scrollThresholdCat7)  {
        setVisibleCats(4); 
        setMoveCat(true);
        setShowFish(false)
        setShowAdoptionGoalsCat(false);
        setShowPainCat(true)
        setShowFish2(true)
        setShowSupportCat(false);
        setShowDecisionCat(false);
        setShowHappyCat(false);
      }
      else if (scrollTop >= scrollThresholdCat7 && scrollTop < scrollThresholdCat8)  {
        setVisibleCats(4); 
        setMoveCat(true);
        setShowFish(false)
        setShowAdoptionGoalsCat(false);
        setShowPainCat(false);
        setShowFish2(false);
        setShowFish3(true);
        setShowSupportCat(true);
        setShowHappyCat(false);
        setShowDecisionCat(false);
      }
      else if (scrollTop >= scrollThresholdCat8 && scrollTop < scrollThresholdCat9)  {
        setVisibleCats(4); 
        setMoveCat(true);
        setShowFish(false)
        setShowAdoptionGoalsCat(false);
        setShowPainCat(false);
        setShowFish2(false);
        setShowFish3(false);
        setShowSupportCat(false);
        setShowDecisionCat(true);
        setShowHappyCat(false);
      }
      else if (scrollTop >= scrollThresholdCat9 && scrollTop < scrollThresholdCat10)  {
        setVisibleCats(4); 
        setMoveCat(true);
        setShowFish(false)
        setShowAdoptionGoalsCat(false);
        setShowPainCat(false);
        setShowFish2(false);
        setShowFish3(false);
        setShowSupportCat(false);
        setShowDecisionCat(false);
        setShowHappyCat(true);
      }
    };
  
    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);
  
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visibleCats]);
  
  
  useEffect(() => {
    document.title = '37 Customer Onboarding Question Examples';
  },[]);
  
  useEffect(() => {
    
    // Update the filtered questions whenever searchTerm or selectedCategory changes
    setFilteredQuestions(filterQuestions());
  }, [searchTerm, selectedCategory]);

  
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // Text animate
      const scrollThresholdButton = 20; 
      const scrollThresholdButtonMax = 460; 
      if (scrollTop > 300) {
        setIsScrolled(true);
      } else if (scrollTop <= scrollThresholdButton ) {
        setIsScrolled(false);
        setIsScrolled1(false);
        setIsScrolled2(false);
        setIsScrolled3(false);
        setIsScrolled4(false);
      }
      else if ( scrollTop > 98 && scrollTop < scrollThresholdButtonMax&& scrollTop < scrollThresholdButtonMax && !isScrolled1) {
        setIsScrolled1(true);
      }
      else if ( scrollTop > 128 && scrollTop < scrollThresholdButtonMax&& scrollTop < scrollThresholdButtonMax && !isScrolled2) {
        setIsScrolled2(true);
      }
      else if (scrollTop > 178 && scrollTop < scrollThresholdButtonMax&& scrollTop < scrollThresholdButtonMax && !isScrolled3) {
        setIsScrolled3(true);
      }
      else if (scrollTop > 190 && scrollTop < scrollThresholdButtonMax&& scrollTop < scrollThresholdButtonMax && !isScrolled4) {
        setIsScrolled4(true);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled,isScrolled1,isScrolled2,isScrolled3,isScrolled4]);
  const copyToClipboard = (text, index) => {
    // Create a temporary input element to copy text to the clipboard
    const tempInput = document.createElement('input');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  
    // Set the copied state for the specific question at the given index to true
    const updatedIsCopiedList = [...isCopiedList];
    updatedIsCopiedList[index] = true;
    setIsCopiedList(updatedIsCopiedList);
  
    // Reset the copied state for the specific question after a certain duration (e.g., 2 seconds)
    setTimeout(() => {
      updatedIsCopiedList[index] = false;
      setIsCopiedList(updatedIsCopiedList);
    }, 2000);
  };
  
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://pepperform.net/onboarding-questions" />
        <meta
          name="keywords"
          content="onboarding questions, examples, microcopy, user onboarding, customer onboarding"
        />
        <meta
          name="description"
          content="Design and customize surveys effortlessly with our free online survey maker. Build, create, and personalize professional surveys with ease. Start now!"
        />
        <meta
          property="og:title"
          content="37 Customer Onboarding Question Examples"
        />
        <meta
          property="og:description"
          content="Design and customize surveys effortlessly with our free online survey maker. Build, create, and personalize professional surveys with ease. Start now!"
        />
      </Helmet>
      <div className={state.isDrawerOpen === true ? `${mainNav.frozePage} ${mainNav.page}` : `App`}>
        <div className={questionstyle.title}>
        <MainHeaderSimple page="/" setNav={setNav} state={state} />
        <header className={questionstyle.header}> 
            <h1>37 Customer Onboarding Question Examples</h1>


        </header>
        <div className={questionstyle.blurb}> 
           <p>
            📌 Press ⌘+D to bookmark this page. Read our full article on this <a href="https://medium.com/illumination/4-questions-you-should-be-asking-during-customer-onboarding-8cb305708f50">here</a>.

            </p> 
        </div>
        <div className={questionstyle.cards}>
          <div className={questionstyle.twocol}>
            <div className={questionstyle.card}>
              <h2>TL;DR</h2>
            <h3>Ask 4 types of questions during onboarding to reduce the knowledge gap</h3>
              <div style={{paddingTop:'32px'}} className={`${questionstyle.scrollfade_text} ${isScrolled ? questionstyle.show : ''}`}>
                <p>
                Good luck! We know you'll nail it 🚀
                </p>
              </div>
              <div className={questionstyle.cat}>
                {visibleCats <= 2 && <img src={cats[0]} className={questionstyle.cat1} alt="Cat 1" />}
                {visibleCats <= 2 && <img src={cats[1]} className={questionstyle.cat2} alt="Cat 2" />}
              </div>
              <div className={questionstyle.cat}>
                {visibleCats == 3 && <img src={cats[2]} className={questionstyle.cat3} alt="Cat 3" />}
              </div>
              <div className={` ${moveCat ? questionstyle.movecat : ''} ${questionstyle.cat}`}>
                {visibleCats >= 4 && <img src={cats[3]} className={`${questionstyle.cat4}`} alt="Cat 4" />}
                {visibleCats >= 4 && <img src={cats[4]} className={`${questionstyle.cat5}`} alt="Cat 5" />}
              </div>
              <div className={questionstyle.cat}>
                {showHappyCat && <img src={happycat} className={questionstyle.happycat} alt="Cat 3" />}
              </div>
            </div>
          </div>
          <div className={questionstyle.fourcol}>


            <div className={`${questionstyle.cardsmall} ${showAdoptionGoalsCat ? questionstyle.active:''}`}>
              {showAdoptionGoalsCat && (
                  <img style={showAdoptionGoalsCat?{opacity:1}:{opacity:0,transition:'all .2s'}} src={cat6} className={`${questionstyle.cat6}`} alt="Cat 6" />
              )}
              <h4>1.</h4>
              <h3>Adoption goals </h3>
              <div className={`${questionstyle.scrollfade_text} ${isScrolled1 ? questionstyle.show : ''}`}>
              <p>What do they aim to achieve with your product?</p>
              </div>
              {showFish && (
                  <img src={fish} className={`${questionstyle.fish1}`} alt="Fish 1" />
              )}
            </div>
            <div className={`${questionstyle.cardsmall} ${showPainCat ? questionstyle.active:''}`}>
              {showFish2 && (
                  <img style={showPainCat?{opacity:1}:{opacity:0,transition:'all .2s'}} src={fish2} className={`${questionstyle.fish2}`} alt="Fish 1" />
              )}
                {showPainCat && (
                  <>
                  <img style={showPainCat?{opacity:1}:{opacity:0,transition:'all .2s'}}  src={paw} className={`${questionstyle.paw}`} alt="Cat 6" />
                  <img style={showPainCat?{opacity:1}:{opacity:0,transition:'all .2s'}}  src={nerve} className={`${questionstyle.nerve}`} alt="Grr..." />
                  </>
              )}
              <h4>2.</h4>
              <h3>Pain points</h3>
              <div className={`${questionstyle.scrollfade_text} ${isScrolled2 ? questionstyle.show : ''}`}>
              <p>What challenges are they facing?</p>
              </div>
            </div>
            <div className={`${questionstyle.cardsmall} ${showSupportCat ? questionstyle.active:''}`}>
            {showSupportCat && (
                  <img style={showSupportCat?{opacity:1}:{opacity:0,transition:'all .2s'}} src={fish2} className={`${questionstyle.fish3}`} alt="Fish 1" />
              )}
                {showSupportCat && (
                  <div className={questionstyle.scissors}>
                  <img style={showSupportCat?{opacity:1}:{opacity:0,transition:'all .2s'}}  src={scissors} className={`${questionstyle.scissors1}`} alt="Cat 6" />
                  <img style={showSupportCat?{opacity:1}:{opacity:0,transition:'all .2s'}}  src={scissors2} className={`${questionstyle.scissors2}`} alt="Cat 6" />
                  </div>
              )}
              <h4>3.</h4>
              <h3>Support needs</h3>
              <div className={`${questionstyle.scrollfade_text} ${isScrolled3 ? questionstyle.show : ''}`}>
              <p>How can we support them when they encounter issues?</p>
              </div>
            </div>
            <div className={`${questionstyle.cardsmall} ${showDecisionCat ? questionstyle.active:''}`}>

              {showDecisionCat && (
                <>
                  <img style={showDecisionCat?{opacity:1}:{opacity:0,transition:'all .2s'}} src={cat1} className={`${questionstyle.cat7}`} alt="Cat 6" />
                  <img style={showDecisionCat?{opacity:1}:{opacity:0,transition:'all .2s'}} src={catfriend} className={`${questionstyle.catfriend}`} alt="Cat 6" />
                </>
              )}
              <h4>4.</h4>
              <h3>Decision Makers</h3>
              <div className={`${questionstyle.scrollfade_text} ${isScrolled4 ? questionstyle.show : ''}`}>
              <p>Who makes the purchasing decision?</p>
              </div>
            </div>


          </div>
        </div>
        </div>

        <main className={layout.body_wrapper}>
        <div className={questionstyle.search}>
            <input
              type="text"
              placeholder="Search for text..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
<div className={questionstyle.filter}>
  <select
                id="categoryFilter"
                onChange={(e) => setSelectedCategory(e.target.value)}
                value={selectedCategory}
              >
    <option value="All">All</option>
    <option value="Adoption goals">Adoption goals</option>
    <option value="Pain points">Pain points</option>
    <option value="User Experience">User Experience</option>
    <option value="Support Needs">Support Needs</option>
    <option value="Decision-Making Role">Decision-Making Role</option>
    <option value="Product Customization">Product Customization</option>
    <option value="Data Security">Data Security</option>
    <option value="Integration Needs">Integration Needs</option>
    <option value="Training and Onboarding">Training and Onboarding</option>
    <option value="Feedback Mechanisms">Feedback Mechanisms</option>
    <option value="Future Expansion">Future Expansion</option>
    <option value="Measuring Success">Measuring Success</option>
    <option value="Product Demos and Training">Product Demos and Training</option>
    <option value="User Community">User Community</option>
  </select>
</div>
          </div>
        <div className={questionstyle.questionList}>
        {filteredQuestions.map((question, index) => (
          <div className={questionstyle.question} onClick={() => copyToClipboard(question.title, index)}>
            <div className={questionstyle.control}>
              <h3 className={questionstyle.category} >{question.category} / </h3>
              <h3 className={questionstyle.tone} >{question.tone}</h3>

            </div>
            <h2 className={`${questionstyle[question.tone]}`}>{question.title}</h2>
            <i className={questionstyle.action}>
                {isCopiedList[index] ? <><img src={check} alt="Checkmark" /> </> : <img src={copy} alt="Copy" />}
              </i>
          </div>
        ))}
        </div>
      </main>
      </div>
    </>
  );
}

export default QuestionBank;
