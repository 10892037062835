import React, { useState, useEffect } from 'react';
import MainHeader from './MainHeader';
import CustomLink from './DumbComponents/CustomLink';
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem';
import TemplatesCarousel from './Components/TemplatesCarousel';
import MainFooter from './MainFooter';
import mainNav from './css/MainNav.module.scss';
import layout from './css/Layout.module.scss';
import blog from './css/Blog.module.scss';
import imageMockup from './images/mockuuups-1.jpg';
import { Helmet } from 'react-helmet';

const Article13 = () => {
  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });

  useEffect(() => {
    document.title = 'Give Your Team a Voice, Be a Better Boss';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
<Helmet>
  <link rel="canonical" href="https://pepperform.net/article1" />
  <meta
    name="keywords"
    content="NPS survey questions, Net Promoter Score, customer feedback, customer satisfaction, survey templates"
  />
  <meta
    name="description"
    content="Explore the top 7 typical customer feedback questions to ask in an NPS survey. Understand customer sentiment, identify areas for improvement, and cultivate a loyal customer base."
  />
  <meta
    property="og:title"
    content="7 Typical Customer Feedback Questions for NPS Surveys | Pepperform Blog"
  />
  <meta
    property="og:description"
    content="Explore the top 7 typical customer feedback questions to ask in an NPS survey. Understand customer sentiment, identify areas for improvement, and cultivate a loyal customer base."
  />
</Helmet>

      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper}>
        <header className={blog.header}>
          <h1>Give Your Team a Voice, Be a Better Boss</h1>
          <p>
            Does this sound familiar? Someone starts a new job, brimming with enthusiasm, only to find themselves contemplating an exit in a few months due to management woes. Sadly, it’s a path many have walked. According to Gallup’s “State of the American Manager Report,” 50% of employees have bid adieu 👋 to their job because of bad management. It’s clear that poor leadership is a one-way ticket to Employee Turnover Town.
          </p>
          <img src={'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*_bzVAO6mBoFO4iH0v4stRw.png'} alt="Plant" loading="lazy" />
        </header>
        <main className={blog.wrapper}>
          <section className={blog.blog_post}>
            <article className={blog.picture}>
              <h1>Become a Manager, Not an Enemy.</h1>
              <p>
              The most exceptional leaders don’t just guide; they solve problems, appreciate diverse viewpoints, and cultivate an environment where every voice resonates. A thriving, efficient team is one where each individual’s voice matters.
            </p> <p>
So, how do we transform from being the manager people want to escape from into the remarkable boss they want to follow? It’s a blend of active listening and fostering a safe space where your team’s voices not only echo in the room but also hold power.
              </p>
            </article>
            <article>
              <h1>4 Things You Can Do Today To Become a Better Manager</h1>
              <ol className={blog.lists_bignumbers}>
                <li>
                  <h3>Acknowledge the Problem and Embrace Empathy</h3>
                  <p>
                    Truth often emerges in the unlikeliest places within an organization: whispers in the corridor, private DMs on Slack, and more. What sets these modes of communication apart? They provide a safe space for teams to express themselves honestly.
                  </p>
                  <p>
                    To calculate the Net Promoter Score, we subtract the percentage of detractors from the percentage of promoters. The resulting score can range from -100 to +100. A higher score indicates a higher level of customer advocacy.
                  </p>
                  <p>
                    NPS is a valuable tool for understanding customer sentiment, identifying areas for improvement, and tracking changes in customer satisfaction over time. It helps us gauge how well we're doing in winning over our customers and earning their loyalty.
                  </p>
                  <p>
                    So, with NPS, we can keep a finger on the pulse of customer satisfaction and continuously strive to make their experience even better.
                  </p>
                </li>
                <img style={{width:'100%'}} src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*1oHHHe19Hhp9nGvPkkOarg.png" />
                <li>
                  <h3>Conflict — Where Strong Leadership Shines Bright</h3>
                  <p>
                    Conflicts can be an opportunity for you to win your team’s trust. Effective leaders tackle conflicts head-on, taking decisive actions and making informed decisions. On the contrary, ineffective leaders often avoid getting involved, take sides, or simply remain passive. What you do in a conflict counts. Remember, your audience isn’t limited to those directly engaged in the conflict; it extends to your entire organization.
                  </p>
                  <p>
                    Radical candor
                  </p>
                  <p>
                    In handling conflicts, one tool that has proven effective is Radical Candor. It’s a concept that promotes honest feedback while showing genuine care for individuals. It’s about addressing issues candidly while maintaining empathy and respect.
                  </p>
                  <p>
                    To put Radical Candor into action, start by fostering an environment where honest conversations are actively encouraged. Create regular feedback sessions where team members can openly share their thoughts and concerns.
                  </p>
                  <p>
                    Be proactive — ask for feedback in the daily huddles
                  </p>
                  <p>
                    Don’t wait until conflicts arise to ask. Keep the flow of your team’s happiness going with a brief weekly survey or during your regular one-on-ones. Consider incorporating the PERMA framework into those conversations, as proposed by Martin Seligman. This framework explores five essential elements for human well-being and flourishing: positive emotions, engagement, relationships, meaning, and achievement. Leaders can use this model not only to encourage team members to thrive but also to gain insights into enhancing their own well-being.
                  </p>
                </li>
                <CustomLink link={'https://app.pepperform.net/template/team-checkup'}>
                    <img src="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*Z8ewO0XQPU9yAWVPHUP5BQ.png" style={{width:"100%"}} />
                    Check out Pepperform’s Employee Wellbeing Survey — PERMA
                </CustomLink>
                <hr></hr>
                <li>
                  <h3>Secure Senior Leadership Commitment to Define Success</h3>
                  <p>
                    To nurture a culture of attentive listening, it’s crucial to gain the support of senior leadership. Their endorsement sends a message throughout the organization, demonstrating that you are serious about transforming the culture.
                  </p>
                  <p>
                    Address Misalignment:
                  </p>
                  <p>
                    Misalignment often triggers resistance from senior leaders. A thriving organization prioritizes impact over mere output. The goal is to assemble a team of missionaries, not mercenaries. As leaders, your focus should revolve around how your team contributes to the company’s vision rather than just measuring how much work is being done.
                  </p>
                  <p>
                    Define and Align on Success:
                  </p>
                  <p>
                    To secure upper management’s buy-in, it’s essential to illustrate why investing in team happiness is worthwhile. Collaborate to establish transparent metrics for enhanced engagement and retention. We measure only to be able to keep track of progress. Consistently measure and report progress to generate momentum. Share success stories widely, using company-wide communication channels like all-hands meetings to underscore the significance of feedback.
                  </p>
                </li>

                <li>
                  Don’t Stop There, Do This Regularly
                  <p>
                    Becoming a better manager is not a one-time effort; it’s a continuous journey of growth and improvement. Commit to regularly asking for feedback. Do it monthly, weekly, or daily.
                  </p>
                </li>
              </ol>
            </article>
          </section>
        </main>
        <MainFooter />
      </div>
    </div>
  );
}

export default Article13;
