import { React } from 'react';
import { useQuery, gql } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';

import styles from './css/MainNav.module.scss';
import button from './css/Button.module.scss';


import Fade from 'react-reveal/Fade';

import NavigationTile from './DumbComponents/NavigationTile';
import NavigationTileMobile from './DumbComponents/NavigationTileMobile';
import TemplateThumbnailTile from './DumbComponents/TemplateThumbnailTile'


export const GET_CATEGORY_TEMPLATES = gql`
query($title: String!){
  categorytemplates(title: $title){
      title
      id
      mytemplate
      featured
      updated_at
      created_at
      template{
        name
        description
        id
        featured
        updated_at
        myform
        previewImage
        shorthand
      }
    }
  }
`;


function MainNavDrawer(props) {
  const {  data } = useQuery(GET_CATEGORY_TEMPLATES, { variables: { title: "Homepage" } });

  return (

      <section className={styles.mainDrawerNav}>
      <nav className={styles.drawerNav}>
        <ul>
        <Fade delay="300">

          {props.lists.map((list, index) => {
            return <div key={index}>
                <NavigationTile link={list.link} title={list.title} description={list.description} key={index} />

                { list.list && list.list.map((list,index)=>{
                 return <NavigationTileMobile link={list.link} title={list.title} description={list.description} key={index} />

                })}         
            </div>
          })}
        </Fade>

        </ul>
        {/* <ul className={styles.drawerNavPopularTemplate}>
          <h2>Most popular template</h2>
          <Fade delay="600">

          <ul className={styles.popularTemplateList}>
            {data && data.categorytemplates.map((template, index) => {
              return <TemplateThumbnailTile index={index} key={index} />
            })}

          </ul>
        </Fade>
        </ul> */}
      </nav>
      <section className={styles.drawerNavRight} onClick={() => props.setNav({ isDrawerOpen: false })}>
        <span className={button.closeButton}><CloseIcon /></span>
      </section>
    </section>
  );
}

export default MainNavDrawer;
