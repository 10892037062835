import React, { useState } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import blog from './css/Blog.module.scss'
import mainImage from './images/agilearticle.jpg'


const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Pre-Webinar Survey: Tell Us What You Want to Learn",category: "template",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "Pre-Webinar Survey: Tell Us What You Want to Learn", link: "https://pepperform.net/template/feedback/Pre-Webinar-Survey" }] },
]





function Article6() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
      <header className={blog.header}>
            <h1>
                A Super Guide to Conducting Team Health Checks for High-Performing Agile Teams
            </h1>
            <p>
                Running regular health and morale checks is crucial for maintaining the effectiveness and well-being of Agile teams. These assessments provide valuable insights into team dynamics, productivity, and employee satisfaction, helping identify areas for improvement and fostering a positive work environment. 
            </p>

            <img src={mainImage} alt="Plant" loading="lazy" />

          </header>
      <main className={blog.wrapper}>
        <section className={blog.blog_post}>

          <article>
            <h1>
                Why do you need a team healthcheck?
            </h1>
                
                <p>
                    The primary purpose of conducting a team health check is to identify areas of improvement, address potential issues, and enhance the overall well-being and performance of the team. 
                </p>
                <p>
                    Team health checks help Agile teams and their leaders to:
                    <ol>
                        <li>Improve Collaboration: By evaluating team health, organizations can identify collaboration gaps or issues and take proactive measures to enhance teamwork, cooperation, and communication within the team.</li>
                        <li>Increase Productivity: A healthy and motivated team is more likely to be productive and deliver high-quality results. By understanding team health, organizations can uncover productivity barriers and implement strategies to optimize team performance.</li>
                        <li>Foster Employee Engagement: Engaged team members are more committed, motivated, and satisfied with their work. Team health checks provide insights into employee engagement levels, enabling organizations to create a supportive environment that encourages growth, learning, and job satisfaction.</li>
                        <li>Enhance Retention and Happiness: Addressing team health concerns and providing support can contribute to higher employee retention rates and overall job satisfaction. Regular health checks allow organizations to identify and address potential factors that may impact employee happiness and retention.</li>
                    </ol>
                </p>
                <h1>Gallup Team Health Check Questions:</h1>
                <p>
                Gallup, a renowned research and analytics company, has developed the Q12 employee engagement survey. This survey consists of 12 powerful questions designed to assess employee engagement and overall team health. Some of the key questions include:
                <ul>
                    <li>Do you have the materials and equipment to do your job right?</li>
                    <li>At work, do you have the opportunity to do what you do best every day?</li>
                    <li>In the last seven days, have you received recognition or praise for doing good work?</li>
                    <li>Does your supervisor, or someone at work, seem to care about you as a person?</li>
                </ul>
                These questions focus on key aspects of employee engagement, satisfaction, and well-being. By incorporating Gallup's Q12 survey into your team health check, you can gain valuable insights into the overall team dynamics and identify areas for improvement. <a href="https://www.gallup.com/workplace/356063/gallup-q12-employee-engagement-survey.aspx">Gallup team health check questions</a>
                </p>
                <h1>Spotify Team Health Check:</h1>
                <p>
                Spotify, a renowned technology company, has introduced its own model for team health checks. Known as the Squad Health Check Model, it provides a framework for teams to evaluate their health across different dimensions. The model consists of a set of statements or questions that teams rate on a scale from 1 to 5, indicating their level of agreement or satisfaction. Some categories covered in the Spotify Team Health Check include:

                <ul>
                    <li>Alignment: Are we clear on our goals and priorities?</li>
                    <li>Collaboration: Are we effectively collaborating and communicating within the team?</li>
                    <li>Autonomy: Do we have the freedom and authority to make decisions?</li>
                    <li>Learning: Are we continuously learning and improving as a team?</li>
                </ul>
                The Spotify Team Health Check model encourages open and honest discussions within teams and helps identify areas where the team can focus on improvement. It promotes self-reflection, encourages transparency, and empowers teams to take ownership of their own health and well-being. <a href="https://engineering.atspotify.com/2014/09/squad-health-check-model/">Spotify Team Health Check</a>
                </p>
                <h1>Conclusion</h1>
                <p>
                    By incorporating these two well-known team health check approaches, you can expand your assessment toolkit and gain valuable insights into team dynamics, engagement, and satisfaction. Remember that team health checks are not one-size-fits-all, and it's essential to tailor the questions and approach to meet the specific needs and context of your team.
                </p>
          </article>
        </section>
        <section className={blog.reco} >
          <div className={blog.quote}>
          By incorporating these two well-known team health check approaches, you can expand your assessment toolkit and gain valuable insights into team dynamics, engagement, and satisfaction. 
          </div>
              {/* {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })} */}

        </section>
      </main>
      {/* <TemplatesCarousel /> */}
      <MainFooter/>
      </div>

    </div>
  );
}

export default Article6