import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import CustomLink from './DumbComponents/CustomLink'
import footer from './css/MainFooter.module.scss'

import footer_image from './images/ipadMock.png'
import logo from './images/logo-white.svg'
import styles from "./css/NewHomepage.module.scss";


function MainFooter(props) {
    const [thumbnails, setThumbnails] = useState([]); // Define the thumbnails state variable
    useEffect(() => {
        document.title =
          "Create Professional Premium Online Surveys with Pepperform";
    
        // Fetch JSON data (replace with your JSON API URL)
        const jsonDataUrl =
          "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40pepperform-official"; // Replace with your JSON API URL
    
        axios
          .get(jsonDataUrl)
          .then((response) => {
            // Extract relevant data and update state with the first 4 items
            const extractedThumbnails = response.data.items.slice(0, 4);
            setThumbnails(extractedThumbnails);
          })
          .catch((error) => {
            console.error("Error fetching JSON data:", error);
          });
      }, []); // Empty dependency array to fetch JSON data once on component mount
    
    return (
        <React.Fragment>
<div className={`${styles.screenWidePanel} ${styles.footer}`}>
        <h2>What we've been up to</h2>
        {/* <p>a</p> */}
        <div className={styles.medium_articles}>
          {thumbnails.map((article, index) => {
            return (
              <div className={styles.medium_article}>
                <a href={article.link}>
                  <span>Blog</span>
                  <h3>{article.title}</h3>
                  <div className={styles.img}>
                    <img alt={article.description} src={article.thumbnail} />
                  </div>
                </a>
              </div>
            );
          })}
        </div>
        <div className={styles.mainfooter}>
          <div className={styles.banner_container}>
            <div className={styles.banner}>
              <header>
                <h2>Create your first Pepperform today</h2>
                <p>
                  Sign up today and create your first free unlimited online
                  surveys.
                </p>
              </header>
              <CustomLink
                link={"https://app.pepperform.net/register"}
                buttonStyle={"cta"}
              >
                Get Started FREE
              </CustomLink>
            </div>
            <img src={footer_image} alt={"Woman with phone"} />
          </div>
          <nav className={styles.footer__nav_container}>
            <ul>
              <li className={styles.footer__header}>About us</li>
              <li>
                {" "}
                <CustomLink link={"/pricing"}>Pricing</CustomLink>
              </li>
              <li>
                {" "}
                <CustomLink link={"/privacy"}>Privacy Policy</CustomLink>
              </li>
              <li>
                {" "}
                <CustomLink link={"/terms-of-service"}>
                  Terms of Service
                </CustomLink>
              </li>
            </ul>
            {/* <ul >
                    <li className={styles.footer__header}>Features</li>
                    <li> <CustomLink link={'/Feature/Editor'}>The Editor</CustomLink></li>
                    <li> <CustomLink link={'/Feature/Customization'}>Design and Customization</CustomLink></li>
                </ul> */}
            <ul>
              <li className={styles.footer__header}>Solution</li>
              <li>
                {" "}
                <CustomLink link={"/Solution/Customer"}>
                  Customers Teams
                </CustomLink>
              </li>
              <li>
                {" "}
                <CustomLink link={"/Solution/Product"}>
                  Product Teams
                </CustomLink>
              </li>
              <li>
                {" "}
                <CustomLink link={"/Solution/Marketing"}>
                  Marketing Teams
                </CustomLink>
              </li>
            </ul>
            <ul>
              <li className={footer.header}>Resources</li>
              <li>
                {" "}
                <CustomLink link={"https://app.pepperform.net/templates"}>
                  Templates
                </CustomLink>
              </li>
              <li>
                {" "}
                <CustomLink link={"/articles"}>Blog</CustomLink>
              </li>
              {/* <li> <CustomLink link={'/the-editor'}>The Editor</CustomLink></li>
                    <li> <CustomLink link={'/the-survey-form'}>The Survey Forms</CustomLink></li>
                    <li> <CustomLink link={'/reporting'}>Reporting</CustomLink></li>
                    <li> <CustomLink link={'/reporting'}>Beta Phase</CustomLink></li>  */}
            </ul>
            <ul className={footer.logo}>
              <li>
                <img src={logo} alt="Visit Pepper Form" loading="lazy" />
              </li>
            </ul>
          </nav>
          <ul className={footer.logo_mobile}>
            <li>
              <img src={logo} alt="Visit Pepper Form" loading="lazy" />
            </li>
          </ul>
        </div>
        </div>
        </React.Fragment>

    );
}

export default MainFooter;
