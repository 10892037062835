import React, { useState, useEffect } from 'react';

import MainHeader from './MainHeader';
import CustomLink from './DumbComponents/CustomLink';
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem';
import TemplatesCarousel from './Components/TemplatesCarousel';
import MainFooter from './MainFooter';
import { Helmet } from 'react-helmet';

import mainNav from './css/MainNav.module.scss';
import layout from './css/Layout.module.scss';
import blog from './css/Blog.module.scss';
import imageMockup from './images/mockuuups-1.jpg';

const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Product", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Other", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
];

function Article18() {
  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });

  useEffect(() => {
    document.title = 'The Ultimate No-Frills CSM Guide to Customer Onboarding';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
            <Helmet>
        <link rel="canonical" href="https://pepperform.net/ultimate-customer-onboarding-guide-for-csm" />
        <meta
          name="description"
          content="We know you only have one shot to make a first impression- that's why nailing the onboarding process is so important. The quicker customers reach this value during onboarding, the more likely they are to stay and become advocates for your product. "
        />
        <meta
          property="og:title"
          content="The Ultimate No-Frills CSM Guide to Customer Onboarding"
        />
        <meta
          property="og:description"
          content="The quicker customers reach this value during onboarding, the more likely they are to stay and become advocates for your product. A poor onboarding experience, on the other hand, will lead to customer churn."
        />
      </Helmet>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
        <header className={blog.header}>
          <h1>The Ultimate No-Frills CSM Guide to Customer Onboarding</h1>
          <p>We know you only have one shot to make a first impression- that's why nailing the onboarding process is so important. Whether you're a Customer Success Manager or part of the onboarding team, your role is to ensure that your customers experience a seamless journey from activation to realizing the full value of your product. A flawless first impression gives you a strong chance to convert.
            <br /><br />
            The quicker customers reach this value during onboarding, the more likely they are to stay and become advocates for your product. A poor onboarding experience, on the other hand, will lead to customer churn.</p>
          <img src={'https://cdn-images-1.medium.com/max/1600/1*ENrbXqFP5cJrI3OpTcXefA.png'} alt="customer and csm" loading="lazy" />
        </header>
        <main className={blog.wrapper}>
          <section className={blog.blog_post}>

            <article className={blog.picture}>
              <h1>What is customer Onboarding?</h1>
              <p>Customer onboarding is the process of guiding customers from the moment they sign up to when they truly grasp the value of your product or service. The goal of effective onboarding is simple: how do we expedite this journey and help customers find the benefits of your products/service as quickly as possible. When customers realize the value of your product, it becomes easier to persuade them that their investment in your product was indeed the  right choice. On the flip side, a lousy onboarding experience one that leaves customers confused or unable to find the value they were promised, can result in churn.
              </p>
            </article>

            <article className={blog.picture}>
              <h2>The Responsibilities of a Customer Success Manager During Onboarding?</h2>
              <p>Now, let's talk about what a Customer Success Manager (CSM) does during onboarding. It's a multifaceted role with a few key responsibilities:
              </p>
              <h3>1. Establish a Program for the Onboarding Process</h3>
              <p>Create and implement a structured onboarding program. Outline key steps, goals, touch points and milestones to collect the necessary info and help customers achieve success quickly. Remember to customize it for each customer's goals.</p>
              <img width={'100%'} src="https://cdn-images-1.medium.com/max/1600/1*zLLUwMr6fRlL6dSvrY2ogg.png" alt="Parts of a Customer Success Onboarding Program" />
              <h3>2. Expectation Management - Advocating for Customers</h3>
              <p>One of the core responsibilities of a CSM is to manage customer expectations. We're the voice of the customers within the company. This involves setting realistic expectations, setting up goals and ensuring that the product or service aligns with what the customer envisions and what was promised to them in the sales phase.</p>
              <h3>3. Establish trust and relationship</h3>
              <p>Building rapport is essential. CSMs foster relationships with customers that go beyond the transactional. These relationships are founded on trust and understanding, allowing for open communication and problem-solving. The better the relationship, the more open customers are to our guidance and collaboration during onboarding.</p>
              <img src="https://cdn-images-1.medium.com/max/1600/1*PT31_yiW7uUdCf-p30CTqw.png" width={'100%'}  alt="Illustration of diverse customers" />

              <h3>4. Establish a Mutually Beneficial Long-Term Partnership</h3>
              <p>We should be in it for the long haul. Onboarding to Customer Success Managers should be more than about the here and now; it's about forging a lasting and mutually beneficial relationship between you and your customers. The CSM's responsibility is to find alignment and build a partnership where both the customer and the company can thrive over the long haul.</p>
              <h3>5. Provide Domain Expertise</h3>
              <p>Good Customer Success Managers are the domain experts in their respective areas. They possess in-depth knowledge of the product or service, industry trends, and best practices. During onboarding, this expertise shines through. CSMs guide customers by answering questions, offering solutions, and helping them unlock the full potential of the product or service. A deep understanding of the domain not only enhances your onboarding but also fosters trust and credibility</p>
              <p>In essence, CSMs wear many hats during onboarding, all with the common goal of ensuring that customers not only get to value swiftly but also feel supported and heard throughout their journey.</p>
              <hr />
              <h2>Four Key Elements of the Best Customer Onboarding Process</h2>
              <h3>1. Customizability – One size does not fit all.</h3>
              <p>The best onboarding processes are highly adaptable to the unique needs of each customer. A rigid, non–human, overly automated onboarding process will be pretty obvious to your csutomer. While having automation, defined outline and product information is essential, remember that every customer progresses at their own pace and has distinct priorities based on their circumstances.</p>
              <h3>2. Demonstrate Value</h3>
              <p>By the time they are starting their onboarding journey, they already believe your product can potentially solve their problem. Your primary mission is to convince customers that they've made the right choice to solve their problem by choosing your product. Your job is to solidify this belief by seamlessly transitioning them into using your product and extracting its full value. Articulate the goals and the value your product will bring. Provide a clear roadmap on how to achieve these goals, using visuals like fishbone diagrams to simplify complex processes.</p>
              <h3>3. You Should Care About Their Long–Term Goal</h3>
              <p>Customers often adopt a product to address short-term challenges, but their loyalty and satisfaction aligns to their long-term goals. To achieve this, you need to make sure that you can find alignment with theirlong-term objectives. Here is an example from my past experience in ed tech. I had a teacher adopting our product to find extra resources for struggling students as a short-term goal (as it could take them hours to find materials to get struggling students to practice), but really their long-term goal was to run a differentiated classroom. They want students to work on their own pace, so the focus the onboarding process wasn't so much about showcasing how to find resources for students who were struggling, we intead tailored it to be about the student experience and how the platform allows individual students to be presented with content in their level. This not only converts the teacher but also turns them into an advocate.</p>
              <a href="https://app.pepperform.net/register">
              <img width={'100%'}  src="https://cdn-images-1.medium.com/max/1600/1*BYn6HcegTje3sEe2m17ekg.png" style={{width:'100%'}} alt="Illustration of diverse customers" />
              </a>
              <p>Consider using a survey tool like ours <a href="https://www.pepperform.net">Pepperform</a> to create touch points to capture some of the information you need to tailor your onboarding.</p>
              <h3>4. Assist Your Customer in Advocating for Change</h3>
              <p>When customers adopt a new product, they are likely proposing a change in the way the organisation do things. It's important to understand that your customer will be the driving force behind this change. Your role is to support and guide them as they navigate this transformation within their organization. Encourage your customers to be the drivers of change, helping them understand that adopting a new product is about altering their work processes for the better. By embracing this change management approach, your customers can more effectively transition to your product while retaining their motivation and ownership of the transformation.</p>
            </article>
          <hr />
                <h2>In Conclusion</h2>
                <p>Customer onboarding is your opportunity to shine and leave an unforgettable first impression. Remember, effective onboarding isn't a one-size-fits-all endeavor. Instead, it's about customizing the experience to meet the unique needs of each customer. Automation and defined processes are vital, but every customer advances at their own pace, with specific priorities.</p>
                <p>For a practical guide on creating interaction points and designing a seamless onboarding process, we recommend <a href="https://www.customersuccesscollective.com/the-customer-success-onboarding-playbook/">this awesome playbook</a> by The Customer Success Collective.</p>
          <article>
            </article>
          </section>

        </main>
        <MainFooter />
      </div>
    </div>
  );
}

export default Article18;