
import React, { useState } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import blog from './css/Blog.module.scss'
import mainImage from './images/article-3.jpg'


const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Pre-Webinar Survey: Tell Us What You Want to Learn",category: "template",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "Pre-Webinar Survey: Tell Us What You Want to Learn", link: "https://pepperform.net/template/feedback/Pre-Webinar-Survey" }] },
]





function Article4() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
      <header className={blog.header}>
            <h1>5 Customer Insights for a Winning Marketing and Sales Strategy</h1>
            <p>
            Being data-driven is a game-changer. It means better leads, faster conversions, and laser-targeted marketing. But where do you begin? Start by getting to know your customers inside out. With the right insights, you can build a 360-degree view of your target audience and create a data-driven marketing plan that delivers results.
            </p>
            <img src={mainImage} alt="Plant" loading="lazy" />

          </header>
      <main className={blog.wrapper}>
        <section className={blog.blog_post}>

          <article>
            <h1>Questions</h1>
                <h2>Insights #1: Get up close and personal with your primary persona.</h2>
                <p>Dive into their demographic characteristics and create a consumer profile that captures their essence. By understanding who they are, you can improve how you connect with them and tailor your messaging to resonate deeply. 
                    <h3>Example:</h3>
                    <ol className={blog.exampleQuestions}>
                        <li>How do you describe yourself?</li>
                        <li>What do you do in your organisation?</li>
                    </ol>
                </p>
                <h2>Insight #2: Step into Their Shoes</h2>
                <p>Imagine a day in their life. Walk in their shoes and gain a holistic perspective on their daily experiences. This 360-degree worldview allows you to empathize with their needs and challenges. It also helps you identify how your product or service fits seamlessly into their routine.
                    <h3>Example:</h3>
                    <ol className={blog.exampleQuestions}>
                        <li>What is their typical daily routine like?</li>
                        <li>What are the biggest challenges they face in their daily lives?</li>
                        <li>How do they currently solve or cope with those challenges?</li>
                    </ol>
                </p>
                <h2>Insight #3: Identify Their Pain Points</h2>
                <p>Uncover the pain points your customers face. What keeps them up at night? By understanding their challenges, you can position your offerings as solutions that address their needs head-on. Solving their pain points will earn their loyalty and drive your marketing and sales success.
                    <h3>Example:</h3>
                    <ol className={blog.exampleQuestions}>
                        <li>What alternatives have you used?</li>
                        <li>What frustrates you most about the current solutions in the market?</li>
                        <li>What are the main obstacles preventing you from achieving their goals?</li>
                        <li>Are there any recurring issues or pain points you face in the task or specific area of interest?</li>
                    </ol>
                </p>

                <h2>Insight #4: Uncover Their Goals</h2>
                <p>Get to the heart of what your customers want to achieve. Identify their goals, aspirations, and ambitions. Understanding their desired outcomes will allow you to align your marketing and sales efforts to help them reach those objectives. By becoming their trusted partner, you can drive mutual success.
                    <h3>Example:</h3>
                    <ol className={blog.exampleQuestions}>
                        <li>What are the objectives you aim for in adopting this product?</li>
                        <li>How do they measure success in relation to their goals?</li>
                        <li>What are the top priorities they have set for themselves in the near future?</li>
                    </ol>
                </p>

                <h2>Insight #5: Identify Key Decision Makers</h2>
                <p>Pinpoint the key decision makers within your target audience. Identify the individuals who hold the power to influence purchasing decisions. By knowing who they are, you can tailor your messaging and engagement strategies to effectively reach and persuade them.
                    <h3>Example:</h3>
                    <ol className={blog.exampleQuestions}>
                        <li>Who within their organization has the final say in making purchasing decisions?</li>
                        <li>Are there any specific roles or job titles that hold significant influence in the decision-making process?</li>
                        <li>What criteria do they consider when selecting a vendor or partner for their needs?</li>
                    </ol>
                </p>
                
                <p>
                Building a data-driven marketing and sales strategy starts with collecting key customer insights. By focusing on demographic characteristics, stepping into your customers' shoes, and addressing their pain points, you can create impactful campaigns and tailored experiences. 
                </p>
          </article>
        </section>
        <section className={blog.reco} >
          <div className={blog.quote}>
          Building a data-driven marketing and sales strategy starts with collecting key customer insights. 
          </div>
              {/* {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })} */}

        </section>
      </main>
      {/* <TemplatesCarousel /> */}
      <MainFooter/>
      </div>

    </div>
  );
}

export default Article4;