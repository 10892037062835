import React, { useState } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import blog from './css/Blog.module.scss'
import mainImage from './images/inspired.jpg'


const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Pre-Webinar Survey: Tell Us What You Want to Learn",category: "template",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "Pre-Webinar Survey: Tell Us What You Want to Learn", link: "https://pepperform.net/template/feedback/Pre-Webinar-Survey" }] },
]





function Article4() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
      <header className={blog.header}>
            <h1>7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book "Inspired"</h1>
            <p>
            In the world of product management, gathering valuable customer insights is crucial for success. <a href="https://www.amazon.com/INSPIRED-Create-Tech-Products-Customers/dp/1119387507/ref=as_li_ss_tl?ie=UTF8&qid=1518204288&sr=8-1&keywords=inspired&linkCode=sl1&tag=productboar08-20&linkId=5c8a91891f11d606c5038805cc57bbc3">Inspired</a> by Marty Cagan's influential book "Inspired," we have identified seven essential customer insights that every product manager should collect. These insights will empower product managers to make informed decisions, create user-centric solutions, and drive meaningful impact. 
            </p>
            <img src={mainImage} alt="Plant" loading="lazy" />

          </header>
      <main className={blog.wrapper}>
        <section className={blog.blog_post}>

          <article>
            <h1>Questions</h1>
                <h2>Identifying the Core Target Users:</h2>
                <p>To gain a deep understanding of your core target users, consider asking survey questions such as:
                    <ol className={blog.exampleQuestions}>
                      <li>What are your main responsibilities and challenges in your role?</li>
                      <li>How do you currently address those challenges, and what frustrations do you encounter?</li>
                      <li>What are your goals and aspirations when it comes to using products like ours?</li>
                      <li>Can you share any specific instances where you struggled to find a solution that meets your needs?</li>
                    </ol>
                </p>
                <h2>2. Uncovering Unmet Needs:</h2>
                <p>When aiming to uncover unmet needs, consider asking survey questions like:
                    <ol className={blog.exampleQuestions}>
                      <li>What are the biggest obstacles you face in achieving your desired outcomes?</li>
                      <li>Are there any pain points or frustrations you experience with existing solutions?</li>
                      <li>Are there any specific features or functionalities you wish were available in products like ours?</li>
                      <li>Can you describe a situation where a product fell short of your expectations, and what could have been done differently?</li>
                    </ol>
                </p>
                <h2>3. Mapping the User Journey:</h2>
                <p>To understand the user journey, consider asking survey questions such as:
                    <ol className={blog.exampleQuestions}>
                      <li>How did you initially discover our product, and what prompted you to engage with it?</li>
                      <li>Can you walk us through the steps you take when using our product, from start to finish?</li>
                      <li>Are there any specific touchpoints where you find the experience particularly enjoyable or frustrating?</li>
                      <li>What improvements or enhancements would you suggest at each stage of the user journey?</li>
                    </ol>
                </p>
                <h2>4. Validating Product Ideas:</h2>
                <p>When validating product ideas, consider asking survey questions like:
                    <ol className={blog.exampleQuestions}>
                      <li>How likely are you to use a product that offers {`[`}describe the proposed idea{`}`}?</li>
                      <li>What benefits do you expect to gain from using a product with {'['}describe the proposed idea{']'}?</li>
                      <li>Are there any concerns or reservations you have regarding the proposed idea?</li>
                      <li>Is there anything specific you would like to see improved or added to the proposed idea?</li>
                    </ol>
                </p>
                <h2>5. Prioritizing Features:</h2>
                <p>For feature prioritization, consider asking survey questions like:
                    <ol className={blog.exampleQuestions}>
                      <li>Which features or functionalities do you consider most critical for achieving your goals?</li>
                      <li>How would you prioritize the following features in terms of their importance to you?</li>
                      <li>Are there any specific features or improvements that would significantly impact your decision to use or continue using our product?</li>
                      <li>What additional features or functionalities would you like to see added in the future?</li>
                    </ol>
                </p>
                <h2>6. Collaborating with Cross-Functional Teams:</h2>
                <p>
                  To foster collaboration with cross-functional teams, consider asking survey questions like:
                  <ol className={blog.exampleQuestions}>
                    <li>How would you rate the level of collaboration and communication between different teams involved in the development of our product?</li>
                    <li>Are there any specific areas where you feel there could be better alignment or collaboration between teams?</li>
                    <li>What suggestions do you have for improving cross-functional collaboration within our organization?</li>
                    <li>Are there any specific insights or perspectives from other teams that you believe would benefit the product development process?</li>
                  </ol>
                </p>
                <h2>7.Continuously Iterating and Learning:</h2>
                <p>
                  To promote continuous learning and iteration, consider asking survey questions like:
                  <ol className={blog.exampleQuestions}>
                    <li>How satisfied are you with the frequency and quality of updates and improvements to our product?</li>
                    <li>Is there any feedback or specific suggestions you would like to provide to help us enhance the product?</li>
                    <li>How likely are you to recommend our product to others based on your current experience?</li>
                    <li>Are there any specific insights or perspectives from other teams that you believe would benefit the product development process?</li>
                    <li>Is there anything else you would like to share that could help us improve and iterate our product?</li>
                  </ol>
                </p>
                <h2>To conclude: Create an MVP That Puts Your Customers First, Not Yourself</h2>
                <p>
                Customer insights are the compass that guides product managers on their journey toward building exceptional products. By embracing the principles outlined in Marty Cagan's "Inspired," product managers can cultivate a deep understanding of their customers, uncover unmet needs, and drive innovation. Armed with these seven key customer insights and the corresponding survey questions, you'll be well-equipped to create products that not only meet user expectations but exceed them, leaving a lasting impact in the world of product management.
                </p>
          </article>
        </section>
        <section className={blog.reco} >
          <div className={blog.quote}>
          Customer insights are the compass that guides product managers on their journey toward building exceptional products.
          </div>
              {/* {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })} */}

        </section>
      </main>
      {/* <TemplatesCarousel /> */}
      <MainFooter/>
      </div>

    </div>
  );
}

export default Article4