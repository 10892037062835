import React, { useState, useEffect } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import blog from './css/Blog.module.scss'
import imageMockup from './images/mockuuups-1.jpg'


const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Product",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)",category: "Survey",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Other",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
]





function Article1() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  useEffect(() => {
    document.title = '7 typical customer feedback questions to ask in an NPS survey';
  }, []);


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
      <header className={blog.header}>
            <h1>7 typical customer feedback questions to ask in an NPS survey</h1>
            <p>Want to understand how your customers truly feel about your brand? Seeking actionable insights to enhance customer loyalty and drive business growth? Look no further! In this article, we delve into the world of NPS surveys and unveil the top 8 questions that will empower you to gauge customer sentiment, identify areas for improvement, and cultivate a loyal customer base. Get ready to revolutionize your approach to customer satisfaction measurement with these indispensable NPS survey questions. Let's dive in!</p>
            <img src={imageMockup} alt="Plant" loading="lazy" />

          </header>
      <main className={blog.wrapper}>
        <section className={blog.blog_post}>

          <article className={blog.picture}>
            <h1>What is an NPS?</h1>
            <p>NPS, short for "Net Promoter Score," is a handy tool that measures how likely your customers are to recommend your business. It's a simple question we ask them: "On a scale of 0-10, how likely are you to recommend us to a friend or colleague?"
              Based on their responses, we divide customers into three groups:</p>
                      <CustomLink link={"/7-smart-ways"}>Check out our premade templates for customer feedback</CustomLink>
              <ol className={blog.lists}>
                <li><strong>Promoters (score 9-10):</strong> These are your biggest fans! They genuinely love your business and are enthusiastic about spreading the word. They're like your brand ambassadors, singing your praises to anyone who will listen.</li>
                <li><strong>Passives (score 7-8):</strong> These customers are somewhat neutral. They don't have strong feelings either way. They're like the easygoing folks at a gathering, going with the flow.</li>
                <li><strong>Detractors (score 0-6):</strong> These customers are less satisfied and may have some concerns or complaints. They're not likely to recommend your business and may even share their negative experiences with others.</li>
              </ol>
              <p>To calculate the Net Promoter Score, we subtract the percentage of detractors from the percentage of promoters. The resulting score can range from -100 to +100. A higher score indicates a higher level of customer advocacy.</p>
              <p>NPS is a valuable tool for understanding customer sentiment, identifying areas for improvement, and tracking changes in customer satisfaction over time. It helps us gauge how well we're doing in winning over our customers and earning their loyalty.</p>
              <p>So, with NPS, we can keep a finger on the pulse of customer satisfaction and continuously strive to make their experience even better.</p>
          </article>

          <article>
            <h1>Why is it important?</h1>
            <p>Elevate your customer experience with Pepperform's professional branded surveys. Create and customize surveys to capture feedback at every stage of the customer journey. Start today and turn customers into lifelong advocates.</p>
          </article>
          <article>
            <h1>7 must have questions to get customer feedback in your survey</h1>
            <ol className={blog.lists}>
              <li>Were you greeted in a friendly manner?</li>
              <li>Did our staff answer your questions?</li>
              <li>Did you find our staff helpful and courteous?</li>
              <li>Were you served promptly?</li>
              <li>How can we make your experience even better?</li>
              <li>How has our product/service made an impact on you/your business/your lifestyle?</li>
              <li>What is your favorite thing about our product/service?</li>
            </ol>
          </article>
        </section>
        <section className={blog.reco} >
          <div className={blog.quote}>
          NPS, short for "Net Promoter Score," is a handy tool that measures how likely your customers are to recommend your business. NPS, short for "Net Promoter Score," is a handy tool that measures how likely your customers are to recommend your business. 
          </div>
              {/* {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })} */}

        </section>
      </main>

      <MainFooter/>
      </div>

    </div>
  );
}

export default Article1;
