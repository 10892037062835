import React, { useState, useEffect } from 'react';

import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
import { Helmet } from 'react-helmet';

import mainNav from './css/MainNav.module.scss';
import layout from './css/Layout.module.scss';
import blog from './css/Blog.module.scss';


function Article18() {
  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });

  useEffect(() => {
    document.title = '4 Automation and AI Fails that prove We Still Need Human Touch in Customer Success';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
            <Helmet>
        <link rel="canonical" href="https://pepperform.net/4-automation-ai-fails" />
        <meta
          name="description"
          content="Learn how to strike the perfect balance, maintain transparency, and empower your human team for exceptional customer experiences"
        />
        <meta
          property="og:title"
          content="4 Automation and AI Fails that prove We Still Need Human Touch in Customer Success"
        />
        <meta
          property="og:description"
          content="Learn how to strike the perfect balance, maintain transparency, and empower your human team for exceptional customer experiences."
        />
      </Helmet>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
        <header className={blog.header}>
          <h1>4 Automation and AI Fails that prove We Still Need Human Touch in Customer Success</h1>
          <p>Humans aren't perfect, but it turns out neither are machines. In a world that's embracing automation, we are finding that although it improves some business operations, there are still many areas that require a human touch, especially when it comes to customer success. Here are five personal stories from Customer Success Managers, shedding light on the moments when AI and Automation fell short.
            </p>
          <img src={'https://cdn-images-1.medium.com/max/1600/1*kfr-TOzrM3Tv7_T_e42_qA.png'} alt="customer and csm" loading="lazy" />
        </header>
        <main className={blog.wrapper}>
          <section className={blog.blog_post}>

            <article className={blog.picture}>
              <h2>1. The Misguided Survey Automation generating 0 NPS Score</h2>
              <p>While working at an accounting SaaS company, we implemented automated customer satisfaction surveys for all inquiries. However, after a year, we noticed an unusual trend among customers who rated us 0 on their NPS. It turned out that 67% of those customers, who were already dissatisfied, received the survey via automation immediately after reporting an issue. This unfortunate timing left customers feeling bewildered, as it appeared their concerns were going unaddressed, ultimately leading them to churn.
              </p>
              <img width={'100%'} src="https://cdn-images-1.medium.com/max/1600/1*WLBwfQyLA3hYOXaJLlB6uQ.png" alt="Customer Success Manager Quote" />

              <h2>2. The Accidental Automated-Responder</h2>
              <p>In my role as a Customer Success Manager for an automated email platform, I once had to deal with an awkward mishap. A day after they just discussed their client's recent divorce, our trusty AI automatic email responder decided to spread some cheer with a heartfelt "Happy Anniversary" message. You can imagine the confusion! The lack of empathy in that perfectly timed automated greeting couldn't be ignored, leaving our client in an awkward spot, and prompting them to admit that message was automated and offer an apology to the divorced couple. Ah, the quirks of automation!
              </p>
              <img width={'100%'} src="https://cdn-images-1.medium.com/max/1600/1*n41hLwQ46VDEP_55nN3N4A.png" alt="Customer Success Manager Quote" />

              <h2>3. A meaty meal mix up</h2>
              <p>During my time at a meal box delivery service, we had a rather amusing mishap. A Buddhist institute, who had signed up for a 3-month contract, was given a choice during onboarding - a mystery drink or an extra mystery dish for the week. They opted for the latter, but when the delivery arrived, it was brimming with all sorts of meats. A truly unexpected carnivorous detour. 🥦🥩😱
              </p>
              <img width={'100%'} src="https://cdn-images-1.medium.com/max/1600/1*g3LyplE1AyWi-A9Vr5tD6A.png" alt="Customer Success Manager Quote" />

              <h2>4. The "House" vs. "Home" Dilemma</h2>
              <p>During my time at an eCommerce company, we implemented an AI chatbot to enhance our customer support across multiple languages. Everything was running smoothly until we received an inquiry from a Spanish-speaking customer. This customer wanted to know how to personalize their "home screen," but our chatbot had a little language mix-up. It interpreted "home" as "casa," which means "house" in Spanish. As a result, the bot provided a comprehensive guide on how to find items for decorating the exterior of a building. The customer, was left scratching their head and reached out for clarification. Afterwards, we dove into our data and discovered another231 instances of similar mishaps!
              </p>
              <img width={'100%'} src="https://cdn-images-1.medium.com/max/1600/1*sa4SLMcxjYEOypijB5yHZQ.png" alt="Customer Success Manager Quote" />

              
            </article>
          <hr />
                <h2>How to Successfully Incorporate AI and Automation in Your Customer Delivery</h2>
                <p>Sure, AI and automation can goof up sometimes, but they can also do wonders for your customers when used right. So how do you strike the right balance between automating with AI and human touch?</p>
                <ol>
  <li>Harmony: As a CSM, seek harmony by incorporating AI to augment your efforts where you know for sure it will not affect customer experience and delivery. Utilize AI to analyze customer data and predict their needs accurately.</li>
  <li>Adaptability: Embrace adaptability by continually training your customer support agents and ensuring that AI systems stay aligned with ever-evolving customer requirements. Be mindful of inclusivity in addressing customer needs and input the right information.</li>
  <li>Integration: Ensure a seamless integration of AI into your customer delivery process. Craft workflows that empower human agents with AI insights, and don't exclude them from the implementation process when you're uncertain.</li>
  <li>Transparency: Transparency is a priority. Be clear about when customers are interacting with AI and maintain their trust. Trust me, when issues arise, that honesty makes a difference. Being honest during any mishaps can significantly impact the resolution.</li>
  <li>Learning Loop: Create a learning loop where AI and human agents collaborate to provide a holistic customer experience. Recognize the importance of mutual feedback to enhance AI's performance.</li>
  <li>Balance: Find the right balance between AI automation and the human touch. Personalize AI interactions, but never overlook the unique and empathetic aspect of human assistance.</li>
  <li>Empowerment: Empower your human team by providing them with the tools and knowledge to work alongside AI efficiently.</li>
  <li>Respect: Always respect the value of human support. Customers should have the option to connect with a human agent when necessary.</li>
</ol>

          <article>
            </article>

          </section>
          <div className={blog.quoter}>
          <h3>TLDR; Don't Leave the Human Touch. </h3>
          <p style={{lineHeight:"1.7"}}>Find the right balance between AI automation and the human touch. Personalize AI interactions, but never overlook the unique and empathetic aspect of human assistance.</p>
          </div>

        </main>
        <hr style={{borderColor:'rgba(0,0,0,0.1)'}} />

        <MainFooter />
      </div>
    </div>
  );
}

export default Article18;