import React from 'react';

import styles from '../css/MainNav.module.scss';


function NavigationTile(props) {


  return (
    <a className={styles.navTile} href={props.link}>
    <header>
        <h2>{props.title}</h2>
        {/* <span className={styles.drawerNavItemArrow}><ChevronRightOutlinedIcon  /></span> */}
      </header>
      <p>{props.description}</p>
    </a>

  );
}

export default NavigationTile;
