import React, { useEffect } from 'react';
import { BrowserRouter as Router, useParams, Routes, Route, useNavigate} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';


import NoMatch from './NoMatch';
import Features from './Features';
import Pricing from './Pricing';
import Solutions from './Solutions';
import SolutionCustomer from './SolutionCustomer';
import SolutionProduct from './SolutionProduct';
import SolutionRSVP from './SolutionRSVP';
import SolutionContact from './SolutionContact';
import SolutionMarketing from './SolutionMarketing';
import FeatureBuilder from './FeatureBuilder';
import FeatureCustomization from './FeatureCustomization';
import QuestionBank from './QuestionBank';
import Privacy from './Privacy';
import TermsOfService from './TermsOfService';
import BlogPost from './BlogPost';
import Articles from './Blog';
import Article1 from './Article1';
import Article2 from './Article2';
import Article3 from './Article3';
import Article4 from './Article4';
import Article5 from './Article5';
import Article6 from './Article6';
import Article7 from './Article7';
import Article8 from './Article8';
import Article9 from './Article9';
import Article10 from './Article10';
import Article11 from './Article11';
import Article13 from './Article13';
import Article14 from './Article14';
import Article15 from './Article15';
import Article16 from './Article16';
import Article17 from './Article17';
import Article18 from './Article18';
import Article19 from './Article19';
import NewHomepage from './NewHomepage';
import TemplatesUseCase from './templates/TemplatesUseCase';
import Template from './templates/Template';
import Page404 from './Components/Page404';

function TemplateRedirect() {
  const { templateNumber } = useParams();
  const destinationURL = `https://app.pepperform.net/template/${templateNumber}`;

  useEffect(() => {
    window.location.href = destinationURL;
  }, [destinationURL]);

  return null;
}

function CategoryRedirect() {
  const { categoryName } = useParams();
  const destinationURL = `https://app.pepperform.net/category/${categoryName}`;

  useEffect(() => {
    window.location.href = destinationURL;
  }, [destinationURL]);

  return null;
}

function App() {
  return (
    <HelmetProvider>
    <React.Fragment>
    <Router>
        <Routes>
            <Route exact path="/" element={<NewHomepage />} /> 
            <Route path="/template/:templateNumber" element={<TemplateRedirect />} />
            <Route path="/category/:categoryName" element={<CategoryRedirect />} />
            <Route exact path="/onboarding-questions" element={<QuestionBank />} />

            {/* <Route exact path="/new-homepage" element={<NewHomepage />} /> */}
            {/* <Route exact path="/features" element={<FeatureCustomization />} /> */}
            {/* <Route exact path="/Features/Editor" element={<FeatureBuilder />} /> */}
            {/* <Route exact path="/Feature/Editor" element={<FeatureBuilder />} /> */}
            {/* <Route exact path="/Features/Customization" element={<FeatureCustomization />} /> */}
            {/* <Route exact path="/Feature/Customization" element={<FeatureCustomization />} /> */}

            {/* <Route exact path="/Solution" element={<Solutions />} /> */}
            <Route exact path="/Solution/Customer" element={<SolutionCustomer />} />
            <Route exact path="/Solution/Product" element={<SolutionProduct />} />
            <Route exact path="/Solution/Marketing" element={<SolutionMarketing />} />
            <Route exact path="/Solution/Contact-Forms" element={<SolutionContact />} />
            <Route exact path="/Solution/RSVP-Forms" element={<SolutionRSVP />} />
            
            <Route exact path="/Pricing" element={<Pricing />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/terms-of-service" element={<TermsOfService />} />

            {/* <Route exact path="/Templates" element={<Templates />} /> */}
            {/* <Route exact path="/Category/:usecase/:title?" element={<TemplatesUseCase />} /> */}
            {/* <Route exact path="/Template/:category?/:shorthand" element={<Template />} /> */}
            
            <Route exact path="/articles" element={<Articles />} />
            <Route exact path="/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" element={<Article1 />} />
            <Route exact path="/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" element={<Article2 />} />
            <Route exact path="/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" element={<Article3 />} />
            <Route exact path="/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" element={<Article4 />} />
            <Route exact path="/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" element={<Article5 />} />
            <Route exact path="/article/a-super-guide-to-conducting-team-health-checks-for-high-performing-agile-teams" element={<Article6 />} />
            <Route exact path="/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" element={<Article7 />} />
            <Route exact path="/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" element={<Article8 />} />
            <Route exact path="/article/customer-success-stories-how-forms-can-capture-and-share-success-stories" element={<Article9 />} />
            <Route exact path="/article/inclusivity-matters" element={<Article10 />} />
            <Route exact path="/article/5-employee-satisfaction-surveys-to-supercharge-employee-hapiness" element={<Article11 />} />
            <Route exact path="/article/Be-a-Better-Boss" element={<Article13 />} />
            <Route exact path="/article/Raging-customers" element={<Article14 />} />
            <Route exact path="/article/figma-shortcuts" element={<Article15 />} />
            <Route exact path="/article/15-funny-product-launch-quotes" element={<Article16 />} />
            <Route exact path="/article/remarkable-customer-stories" element={<Article17 />} />
            <Route exact path="/article/ultimate-customer-onboarding-guide-for-csm" element={<Article18 />} />
            <Route exact path="/article/4-automation-ai-fails" element={<Article19 />} />
            
            <Route path='*' element={<Page404 />} />
            
        </Routes>
    </Router>
    </React.Fragment>
    </HelmetProvider>
  );
}

export default App;
