import React from 'react';
import { Link } from "react-router-dom";

import styles from '../css/Button.module.scss'


function CustomLink(props) {


  return (
      <React.Fragment>
        <Link to={props.link} className={styles[props.buttonStyle]}>
            {props.children}
        </Link>
      </React.Fragment>

  );
}

export default CustomLink;
