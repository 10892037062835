import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import faq from '../css/FAQ.module.scss'



function CustomFAQ(props) {
    return (
        <React.Fragment>
            <section className={faq.container}>
                <header>
                    <h3>FAQ</h3>
                    <h2>{props.headline ? props.headline : 'All things Pepperform.'}</h2>
                </header>
                <Accordion preExpanded={["0"]}>
                    {props.source.map((item, index) => {
                        return <li key={index} className={faq.faq_item}>
                            <AccordionItem uuid={String(index)} >
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <h4>
                                            <span>{item.title}</span>
                                            <ExpandMoreIcon fontSize="large" />
                                        </h4>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        {item.description}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </li>
                    })}
                </Accordion>
            </section>

        </React.Fragment>

    );
}

export default CustomFAQ;
