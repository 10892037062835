import React, { useState } from 'react';
import { isDesktop } from 'react-device-detect';
import styles from './css/MainHeader.module.scss'
import GDPROverlay from './GDPROverlay';

import CustomLink from './DumbComponents/CustomLink'
import { HamburgerIcon, PepperIcon } from './SVGCollections'

import logo from './logo.svg'
import MainNavDrawer from './MainNavDrawer';



const FeaturesList = [
  { title: 'Editor', description: 'Build loyalty with Surveys that suits every part of the sales funnel', link: '/Feature/Editor' },
  { title: 'Customisation', description: 'Accompany your campaigns with creative engaging surveys', link: '/Feature/Customization' },
]

const SolutionList = [
  { title: 'Contact Forms', description: 'Build loyalty with Surveys that suits every part of the sales funnel', link: '/Solution/Contact-Forms' },
  { title: 'RSVP Forms', description: 'Level up your events with RSVP Forms! From lead to attendee, gather insights and make experiences unforgettable.', link: '/Solution/RSVP-Forms' },
  { title: 'Customer Teams', description: 'Empower your customer team with our online survey maker', link: '/Solution/Customer' },
  { title: 'Marketing', description: 'Accompany your campaigns with creative engaging surveys', link: '/Solution/Marketing' },
  { title: 'Product Teams', description: 'Get insights from your users with engaging surveys', link: '/Solution/Product' },
]


const PricingList = [
  { title: 'Pricing', description: 'Build loyalty with Surveys that suits every part of the sales funnel' },
  { title: 'One', description: 'Accompany your campaigns with creative engaging surveys' },
  { title: 'Product Teams', description: 'Get insights from your users with engaging surveys' },
]

const TemplateList = [
  { title: 'Template', description: 'Build loyalty with Surveys that suits every part of the sales funnel' },
  { title: 'One', description: 'Accompany your campaigns with creative engaging surveys' },
  { title: 'Product Teams', description: 'Get insights from your users with engaging surveys' },
]

const DefaultList = [
  { title: 'Default', description: 'Build loyalty with Surveys that suits every part of the sales funnel' },
  { title: 'One', description: 'Accompany your campaigns with creative engaging surveys' },
  { title: 'Product Teams', description: 'Get insights from your users with engaging surveys' },
]


const MobileList = [
  { title: 'Features', list: FeaturesList },
  { title: 'Solution', list: SolutionList },
  { title: 'Pricing', link: '/pricing' },
  { title: 'Templates', link: 'https://app.pepperform.net/templates' },
  { title: 'Blog', link: '/article' },
  { title: 'Login', link: 'https://app.pepperform.net/login' },
  { title: 'Signup', link: 'https://app.pepperform.net/register' },
]


function MainHeaderSimple(props) {


  const state = props.state
  const setNav = props.setNav
  const [isMobileNavOpen, setMobileNav] = useState({ isOpen: false, section: '' });

  return (
    <div className={styles.mainHeaderContainerSticky}>
      <header className={styles.mainHeader}>


        <a href="/" className={styles.mainLogo}>
          <PepperIcon /> <img src={logo} className={styles.mainLogo} alt="Pepperform.net" />
          {/* {!state.isDrawerOpen && <img src={logo} className={styles.mainLogo} alt="Pepperform.net" />} */}
        </a>

        <div className="hide-on-tablet-mobile">



        </div>
        <section className={styles.rightSideNav}>
          <CustomLink buttonStyle="loginBtn" link={'https://www.app.pepperform.net/login'}>Login </CustomLink>
          <CustomLink buttonStyle="registerBtn" link={'https://www.app.pepperform.net/register'}>Register FREE</CustomLink>
          <span onClick={() => setMobileNav({ ...state, isOpen: !isMobileNavOpen.isOpen, section: '' })} className={styles.hamburgerIcon} ><HamburgerIcon /></span>

        </section>
      </header>
      {state.isDrawerOpen && isDesktop ?
        <MainNavDrawer section={state.section} setNav={setNav} lists={state.section === "features" ? FeaturesList : state.section === 'solution' ? SolutionList : state.section === "pricing" ? PricingList : state.section === 'templates' ? TemplateList : DefaultList} /> : ''
      }
      {!isDesktop && isMobileNavOpen.isOpen ?

        <MainNavDrawer section={state.section} lists={MobileList} /> : ''}
            <GDPROverlay />

    </div>
  );
}

export default MainHeaderSimple;
