import React, { useState } from 'react';
import { isDesktop } from 'react-device-detect';
import styles from './css/MainHeader.module.scss'
import GDPROverlay from './GDPROverlay';

import CustomLink from './DumbComponents/CustomLink'
import { HamburgerIcon, PepperIcon } from './SVGCollections'

import logo from './images/logo-white.svg'
import logoDark from './images/logo-dark.svg'
import MainNavDrawer from './MainNavDrawer';



const FeaturesList = [
  { title: 'Editor', description: 'Build loyalty with Surveys that suits every part of the sales funnel', link: '/Feature/Editor' },
  { title: 'Customisation', description: 'Accompany your campaigns with creative engaging surveys', link: '/Feature/Customization' },
]

const SolutionList = [
  { title: 'Contact Forms', description: 'Build loyalty with Surveys that suits every part of the sales funnel', link: '/Solution/Contact-Forms' },
  { title: 'RSVP Forms', description: 'From lead to attendee, gather insights and make experiences unforgettable.', link: '/Solution/RSVP-Forms' },
  { title: 'Customer Teams', description: 'Empower your customer team with our online survey maker', link: '/Solution/Customer' },
  { title: 'Marketing', description: 'Accompany your campaigns with creative engaging surveys', link: '/Solution/Marketing' },
  { title: 'Product Teams', description: 'Get insights from your users with engaging surveys', link: '/Solution/Product' },
]


const PricingList = [
  { title: 'Pricing', description: 'Build loyalty with Surveys that suits every part of the sales funnel' },
  { title: 'One', description: 'Accompany your campaigns with creative engaging surveys' },
  { title: 'Product Teams', description: 'Get insights from your users with engaging surveys' },
]

const TemplateList = [
  { title: 'Template', description: 'Build loyalty with Surveys that suits every part of the sales funnel' },
  { title: 'One', description: 'Accompany your campaigns with creative engaging surveys' },
  { title: 'Product Teams', description: 'Get insights from your users with engaging surveys' },
]

const DefaultList = [
  { title: 'Default', description: 'Build loyalty with Surveys that suits every part of the sales funnel' },
  { title: 'One', description: 'Accompany your campaigns with creative engaging surveys' },
  { title: 'Product Teams', description: 'Get insights from your users with engaging surveys' },
]


const MobileList = [
  { title: 'Solution', list: SolutionList },
  { title: 'Pricing', link: '/pricing' },
  { title: 'Templates', link: 'https://app.pepperform.net/templates' },
  { title: 'Blog', link: '/articles' },
  { title: 'Login', link: 'https://app.pepperform.net/login' },
  { title: 'Signup', link: 'https://app.pepperform.net/register' },
]


function MainHeader(props) {


  const state = props.state
  const setNav = props.setNav
  const scrolled = props.scrolled
  const dark = props.dark
  const [isMobileNavOpen, setMobileNav] = useState({ isOpen: false, section: '' });

  return (
  <div className={`${dark && styles.dark} ${scrolled && styles.scrolled} ${styles.mainHeaderContainerSticky}`} >
      <header className={styles.mainHeader}>

      {console.log(isMobileNavOpen)}

        <div className="hide-on-tablet-mobile">

          <nav className={styles.mainNavigation}>
            <ul>
              {/* <li className={state.section === "features" ? styles.active : ''} onClick={() => setNav({ ...state, isDrawerOpen: true, section: 'features' })}>Features</li> */}
              <li className={state.section === "solution" ? styles.active : ''} onClick={() => setNav({ ...state, isDrawerOpen: true, section: 'solution' })} >Solution </li>
              <li className={state.section === "pricing" ? styles.active : ''} ><a href="/pricing">Pricing </a></li>
              <li className={state.section === "templates" ? styles.active : ''} ><a href="https://app.pepperform.net/templates">Templates </a></li>
              <li className={state.section === "articles" ? styles.active : ''} ><a href="/articles">Blog </a></li>
            </ul>
          </nav>

        </div>

        <a href="/" className={styles.mainLogo}>
          {!dark ? <img src={logo} alt="Pepperform.net" />: <img src={logoDark} alt="Pepperform.net" /> } 
          {/* {!state.isDrawerOpen && <img src={logo} className={styles.mainLogo} alt="Pepperform.net" />} */}
        </a>
        <section className={styles.rightSideNav}>
          <CustomLink buttonStyle="loginBtn" link={'https://www.app.pepperform.net/login'}>Login </CustomLink>
          <CustomLink buttonStyle="registerBtn" link={'https://www.app.pepperform.net/register'}>Sign up </CustomLink>
          <span onClick={() => setMobileNav({ ...state, isOpen: !isMobileNavOpen.isOpen, section: '' })} className={styles.hamburgerIcon} ><HamburgerIcon /></span>

        </section>
      </header>
      {state.isDrawerOpen && isDesktop ?
        <MainNavDrawer section={state.section} setNav={setNav} lists={state.section === "features" ? FeaturesList : state.section === 'solution' ? SolutionList : state.section === "pricing" ? PricingList : state.section === 'templates' ? TemplateList : DefaultList} /> : ''
      }
      {!isDesktop && isMobileNavOpen.isOpen ?

        <MainNavDrawer section={state.section} lists={MobileList} /> : ''}
            <GDPROverlay />

    </div>
  );
}

export default MainHeader;
