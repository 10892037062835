import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './App.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';


const root = ReactDOM.createRoot(document.getElementById('root'));
// const httpLink = (process.env.NODE_ENV === 'development') ? createHttpLink({ uri: `http://${process.env.REACT_APP_SERVER_URL}/graphql`}): createHttpLink({ uri: `https://${process.env.REACT_APP_SERVER_URL}/graphql`})

// const httpLink = createHttpLink({ uri: `https://${process.env.REACT_APP_SERVER_URL}/graphql` });

const graphqlurl = (process.env.NODE_ENV === 'development') ? `http://${process.env.REACT_APP_SERVER_URL}/graphql` : `https://${process.env.REACT_APP_SERVER_URL}/graphql`


const link = new HttpLink({
  uri: graphqlurl
  // Additional options
});

const client = new ApolloClient({
 link,
  cache: new InMemoryCache(),
});

root.render(
  <React.StrictMode>
     <ApolloProvider client={client}>   
       <App />

     </ApolloProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
