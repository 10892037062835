import React, { useState } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import blog from './css/Blog.module.scss'


const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Product",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)",category: "Survey",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Other",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
]




function BlogPost() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
      <main className={blog.wrapper}>
        <section className={blog.blog_post}>
          <header>
            <h2>Customer</h2>
            <h1>7 smart survey ideas to help you Collect Customer Feedback online</h1>
          </header>
          <article className={blog.picture}>
            <img src={'https://images.unsplash.com/photo-1521334884684-d80222895322?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'} alt="Plant" loading="lazy" />
            <p>Customer feedback is the best way to improve your product.
            Customers are willing to share feedback but only to businesses that make them feel important and using the right feedback survey technique.</p>
                      <CustomLink link={"/7-smart-ways"}>Check out our premade templates for customer feedback</CustomLink>
          </article>
          <article>

            <h1>Why is it important?</h1>
            <p>Elevate your customer experience with Pepperform's professional branded surveys. Create and customize surveys to capture feedback at every stage of the customer journey. Start today and turn customers into lifelong advocates.</p>
          </article>
          <article>
            <h1>7 must have questions to get customer feedback in your survey</h1>
            <ol className={blog.lists}>
              <li>Were you greeted in a friendly manner?</li>
              <li>Did our staff answer your questions?</li>
              <li>Did you find our staff helpful and courteous?</li>
              <li>Were you served promptly?</li>
              <li>How can we make your experience even better?</li>
              <li>How has our product/service made an impact on you/your business/your lifestyle?</li>
              <li>What is your favorite thing about our product/service?</li>
            </ol>
          </article>
        </section>
        <section className={blog.reco} >
              {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })}

        </section>
      </main>
      <TemplatesCarousel title={'feedback'} />
      <MainFooter/>
      </div>

    </div>
  );
}

export default BlogPost;
