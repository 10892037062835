import gql from "graphql-tag";

export const GET_TEMPLATES = gql`
query( $sortBy: String){
    templates(
      sortBy: $sortBy
    ){
      id
      name
      description
      shorthand
      updated_at
      myform
      previewImage
      featured
    }
  }
`;


export const GET_TEMPLATE = gql`
  query($shorthand: String!) {
    template(shorthand: $shorthand){
        id
        name
        description
        shorthand
        updated_at
        myform
        previewImage
        featured
    }
  }
`;


export const GET_ALL_CATEGORIES = gql`
  query {
    allcategories{
      title
    }
  }
`;


export const GET_FEATURED_TEMPLATES = gql`
query{
  featuredtemplates{
      name
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation createTemplate(
    $name: String
    $description: String
    $category: String
    $myform: String
    $previewImage: String
    $featured: Boolean
    $shorthand: String
  ) {
    createTemplate(
      name: $name
      description: $description
      category: $category
      myform: $myform
      previewImage: $previewImage
      featured: $featured
      shorthand: $shorthand
    ){
      id
    }
  }
`;


export const CREATE_CATEGORY = gql`
  mutation createTemplate(
    $category: String
  ) {
    createTemplate(
      category: $category
    ){
      id
    }
  }
`;


export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate(
    $id: String!
  ) {
    deleteTemplate(
      id: $id
    )
  }
`;


export const UPDATE_TEMPLATE = gql`
  mutation(
    $id: String
    $name: String
    $description: String
    $category: String
    $myform: String
    $previewImage: String
    $featured: Boolean
    $shorthand: String
  ) {
    updateTemplate(
      id: $id
      name: $name
      description: $description
      category: $category
      myform: $myform
      previewImage: $previewImage
      featured: $featured
      shorthand: $shorthand
    ) {
      id
    }
  }
`;

export const CREATE_CATEGORY_ROWS= gql`
  mutation(
    $mytemplate: String
    $title: String
    $featured: Boolean
  ) {
    createCategoryrows(
      mytemplate: $mytemplate
      title: $title
      featured: $featured
    )
  }
`;



export const UPDATE_TEMPLATE_IMG = gql`
  mutation(
    $id: String
    $previewImage: String
  ) {
    updateTemplate(
      id: $id
      previewImage: $previewImage
    ) {
      title
    }
  }
`;

export const GET_TEMPLATE_CATEGORIES = gql`
query($mytemplate: String){
  templatecategories(
    mytemplate: $mytemplate
  ){
    title
    id
    featured
  }
}
`;

export const DELETE_CATEGORY_ROWS= gql`
  mutation(
    $id: String
  ) {
    deleteCategoryrows(
      id: $id
    )
  }
`;


export const UPDATE_SINGLE_CATEGORY= gql`
  mutation(
    $id: String
    $mytemplate: String
    $featured: Boolean    
    $title: String   
  ) {
    updateSingleCategory(
      id: $id
      mytemplate: $mytemplate
      featured: $featured
      title: $title
    )
  }
`;


export const GET_CATEGORY_TEMPLATES = gql`
query($title: String!){
  categorytemplates(title: $title){
      title
      id
      mytemplate
      featured
      updated_at
      created_at
      template{
        name
        description
        id
        featured
        updated_at
        myform
        previewImage
        shorthand
      }
    }
  }
`;