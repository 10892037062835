// import React, { useState, useEffect } from 'react';

// import Fade from 'react-reveal/Fade';

// import MainHeader from './MainHeader'
// import { Helmet } from 'react-helmet';


// import ArticleBrowser from './Components/ArticleBrowsers'
// import FAQ from './Components/FAQ'
// import TemplatesCarousel from './Components/TemplatesCarousel'
// import MainFooter from './MainFooter'



// import mainNav from './css/MainNav.module.scss'
// import layout from './css/Layout.module.scss'
// import plaintext from './css/PlainTextBlock.module.scss'
// import twocolblock from './css/TwoColBlock.module.scss'
// import featurehighlight from './css/FeatureHighlight.module.scss'


// import drag from './images/drag.gif'
// import feature_highlight from './images/drag.png'
// import reportImage from './images/report2.png'
// import customerSupportImage from './images/support.jpg'
// import squiggle from './images/squiggle.svg'
// import highlightsquiggle from './images/highlight-squiggle1.svg'


// const articlefeatures = [
//   // {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
//   // {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
//   // {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
//   {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
//   {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
//   {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
//   {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
//   {title:"Customer Success Stories: How Forms Can Capture and Share Success Stories", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/customer-success-stories-how-forms-can-capture-and-share-success-stories" }] },  
// ]


// const faqlist = [
//   { title: 'Why use Pepperform?', description: "Pepperform is the go-to online form builder for creating engaging, and visually captivating forms. Whether you're creating interactive surveys, contact lists, shopping carts, or quizzes, Pepperform offers a versatile platform with powerful design tools and data management tools." },
//   { title: 'How do I create and send an online customer survey?', description: "Creating and sending an online customer survey with Pepperform is a simple and efficient process. Start by signing in to your account or creating a new one if you don't have an existing account. Once logged in, click on the Create Form button to begin building your survey. You have the option to start from scratch or use a pre-designed template. Customize your survey by adding various types of questions to gather the desired information. Personalize the survey's appearance by customizing the branding, including your logos and colors. Once your survey is ready, click on the Share button to start collecting responses. You can easily track and monitor the survey results in real-time through your reporting dashboard." },
//   { title: 'How much does Pepperform cost?', description: "We offer both free and premium plans, allowing you to choose the option that best fits your requirements." },
//   { title: 'What is the benefit of an online survey builder?', description: "Online surveys are a game-changer for those who want to do their own research. They're a time and money saver, delivering speedy results. You don't need developers or tech skills to get your survey up and ready to share. With just a few clicks, you can create engaging surveys that perfectly fit your needs." },
//   { title: 'How can Pepperform help improve our customer satisfaction levels?', description: "By collecting their insights, you gain a deeper understanding of their needs and preferences, allowing you to tailor your products, services, and customer experiences to meet their expectations. Pepperform offers a comprehensive set of features and tools designed to enhance customer satisfaction. With our intuitive survey builder, you can create engaging and personalized surveys to gather valuable feedback from your customers." },
//   { title: 'What support options are available if we encounter any issues with Pepperform?', description: "At Pepperform, we prioritize customer support and ensure that assistance is readily available when you need it. Our dedicated support team is ready to help you with any questions, technical issues, or concerns you may have." },
// ]


// function SolutionCustomer() {

//   const [state, setNav] = useState({ isDrawerOpen: false, section: '' });

//   useEffect(() => {
//     document.title = 'Deliver Exceptional Customer Experiences with Pepperform';
//   }, []);

//   return (
//     <div className={state.isDrawerOpen === true ? mainNav.frozePage : `App ${mainNav.page}`}>
//         <Helmet>
//             <link rel="canonical" href="https://pepperform.net/Solution/Customer" />
//             <meta name="keywords" content="customer insights, survey solutions, customer feedback, customer experience, customer satisfaction, customer engagement, customer retention" />
//             <meta name="description" content="Empower your customer team with Pepperform's free online survey maker. Easily design surveys to capture feedback, tailor customer feedback surveys, and create professional surveys for free. Start now!" />
//             <meta property="og:title" content="Customer Team Surveys Made Easy | Free Online Survey Maker - Pepperform" />
//             <meta property="og:description" content="Empower your customer team with Pepperform's free online survey maker. Easily design surveys to capture feedback, tailor customer feedback surveys, and create professional surveys for free. Start now!" />
//         </Helmet>
//       <MainHeader page="features" setNav={setNav} state={state} />
//       <main className={layout.body_wrapper}>
//         <section className={plaintext.containerHero + " " + plaintext.hero}>
//           <h2>Customer teams</h2>
//           <Fade up distance={"12px"}>
//             <h1>Unlock Customer Success with Insights
//             {/* <img src={highlightsquiggle} /> */}
//             </h1>
//           </Fade>
//           <Fade delay={500}>
//             <h3>Surveys to Facilitate Customer Onboarding and Activation</h3>
//             <p>Create touchpoints during onboarding or collect feedback during activation. Get better visibility on the most important information throughout the different stage of your customer lifecycle and convert more customers.</p>
//           </Fade>
//         </section>
//         <div style={{background:'url('+squiggle+')', height:"48px"}}>
//         </div>
//         <section className={featurehighlight.wrapper} style={{ backgroundColor: "#222b3d" }}>
//         <div className={featurehighlight.container} style={{paddingBottom:"0"}}>
//             <Fade up distance={"32px"}>
//                 <h2>The most useful Microtool To Onboard New Customers</h2>
//               </Fade>
//               <Fade up distance={"32px"} delay="250">
//               <p>Onboarding is top of mind for the customer-led among us, for a good reason 🫶🏾. Even after customers sign up, they might not be fully convinced to adopt yet. Minor issues can lead to customer churn, making it crucial to set your customers up for success during onboarding. The key to this? Gathering the right information.
//               <br /> <a href="https://medium.com/p/8cb305708f50/" style={{paddingTop:"20px", display:"block", color:"white"}}>4 Questions You Should be Asking During Customer Onboarding</a>
//               </p>
//               </Fade>
//           </div>
//           <div className={`${featurehighlight.container}`} style={{paddingBottom:"0"}}>
//             <header className={featurehighlight.header}>
//               <Fade up distance={"32px"}>
//                 <h3>Easily Create and Distribute Forms</h3>
//               </Fade>
//               <Fade up distance={"32px"} delay="250">
//               <p>Onboarding is top of mind for the customer-led among us, for a good reason 🫶🏾. Even after customers sign up, they might not be fully convinced to adopt yet. Minor issues can lead to customer churn, making it crucial to set your customers up for success during onboarding. The key to this? Gathering the right information.
//               </p>
//               </Fade>
//             </header>
//             <div className={featurehighlight.images}>
//               <img src={reportImage} alt="Feature highlight" loading="lazy" />
//             </div>
//           </div>
//           <div className={`${featurehighlight.container} ${featurehighlight.containerLeft}`} style={{paddingBottom:"80px"}}>
//             <header className={featurehighlight.header}>
//               <Fade up distance={"32px"}>
//                 <h3>Easily Create and Distribute Forms</h3>
//               </Fade>
//               <Fade up distance={"32px"} delay="250">
//               <p>Onboarding is top of mind for the customer-led among us, for a good reason 🫶🏾. Even after customers sign up, they might not be fully convinced to adopt yet. Minor issues can lead to customer churn, making it crucial to set your customers up for success during onboarding. The key to this? Gathering the right information.
//               </p>
//               </Fade>
//             </header>
//             <div className={featurehighlight.images}>
//               <img src={feature_highlight} alt="Feature highlight" loading="lazy" />
//             </div>
//           </div>

//         </section>
//         <section className={twocolblock.container + " " + twocolblock.left_image}>

//           <div className={twocolblock.image_area} style={{ backgroundColor: "#a4bba8" }}>
//             <div className={twocolblock.image}>
//               <img src={drag} alt="Customise to your heart's content" />
//             </div>
//             <div className={twocolblock.image_description_container}>
//               <div className={twocolblock.image_description}>
//                 <h2>24</h2>
//                 <p>Question types</p>
//               </div>
//               <div className={twocolblock.image_description}>
//                 <h2>Unlimited</h2>
//                 <p>Responses</p>
//               </div>
//             </div>
//           </div>
//           <div className={twocolblock.description_area}>
//               <Fade left distance="32px">
//                 <h2>Versatile Surveys for Diverse Needs</h2>
//               </Fade>
//               <Fade left distance="32px" delay="250">
//                 <h3>Create Surveys Tailored to Your Goals</h3>
//                 <p>
//                 Customize surveys to capture feedback by quickly adapting strategies, product development, and consumer interaction to market dynamics and needs by analyzing reactions. Start today and grow your business while knowing your customer's preferences and needs.</p>
//               </Fade>
//               <Fade left distance="32px" delay="500">
//               <p> 
//               Our builder's flexibility ensures you can stay responsive to shifting needs and create surveys that genuinely resonate with your audience.</p>
//               </Fade>
//           </div>

//         </section>
//         <ArticleBrowser articles={articlefeatures} />
//         <TemplatesCarousel title="feedback" />

//         <section className={twocolblock.container + " " + twocolblock.right_image} >
          
//           <div className={twocolblock.description_area}>
//             <h2>Full Support, From Design to Success</h2>
//             <h3>Your Support Journey</h3>
//             <p>Our devoted team guarantees outstanding design help, from design components to creating aesthetically engaging content. Our team will provide you with unmatched assistance and is available to answer any inquiries or address any problems.</p>
//           </div>
//           <div className={twocolblock.image_area} style={{ backgroundColor: '#a4c3c4' }}>
//             <div className={twocolblock.image +" "+twocolblock.image_full }>
//               <img src={customerSupportImage} alt="Customer support" loading='lazy' />
//             </div>

//           </div>

//         </section>
//         <FAQ source={faqlist} />
//         <MainFooter />

//       </main>

//     </div>
//   );
// }

// export default SolutionCustomer;

import React, { useState, useEffect } from 'react';

import Fade from 'react-reveal/Fade';

import MainHeader from './MainHeader'
import { Helmet } from 'react-helmet';

import reportImage from './images/feature_highlight.png'

import ArticleBrowser from './Components/ArticleBrowsers'
import FAQ from './Components/FAQ'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'
import styles from "./css/NewHomepage.module.scss";
import unlimited from "./images/unlimited.svg";
import notification from "./images/notification.svg";
import lifebuoy from "./images/lifebuoy.svg";
import formIcon from "./images/contact-form.svg";

import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import plaintext from './css/PlainTextBlock.module.scss'
import twocolblock from './css/TwoColBlock.module.scss'
import featurehighlight from './css/FeatureHighlight.module.scss'


import drag from './images/fields.gif'
import feature_highlight from './images/mockuuups-1.jpg'
import mockupImage from './images/stefan.jpg'
import customerSupportImage from './images/hero_desktop_1.jpg'


const articlefeatures = [
  // {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
  // {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
  // {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
  {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
  {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
  {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
  {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
  {title:"Customer Success Stories: How Forms Can Capture and Share Success Stories", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/customer-success-stories-how-forms-can-capture-and-share-success-stories" }] },  
]


const faqlist = [
  {
    "title": "How can Pepperform enhance my customer success efforts?",
    "description": "Pepperform simplifies gathering feedback from your clients, helping you understand their needs and preferences. This valuable data enables you to enhance your customer success strategies."
  },
  {
    "title": "Can I use Pepperform for real-time client engagement?",
    "description": "Absolutely! Pepperform allows you to engage your clients in real-time with surveys and feedback forms. Stay connected and gather instant insights to improve your customer interactions."
  },
  {
    "title": "What happens after I collect feedback from clients?",
    "description": "Pepperform's post-feedback surveys are essential. They help you measure customer satisfaction, collect insights, and identify areas for improvement. This data ensures you can continually enhance your customer success efforts."
  },
  {
    "title": "Can I customize the appearance of my client feedback forms?",
    "description": "Yes, Pepperform offers extensive customization options. You can brand your feedback forms with logos and colors to align with your customer success branding."
  },
  {
    "title": "How can Pepperform help me optimize my customer success strategies?",
    "description": "Pepperform empowers you to gather client insights at every stage of their journey. With data from various feedback forms, you can fine-tune your customer success strategies for better results."
  },
  {
    "title": "Is Pepperform suitable for businesses of all sizes?",
    "description": "Absolutely! Pepperform is adaptable and versatile, making it suitable for businesses of all sizes, from startups to large enterprises."
  }
];



function SolutionRSVP() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  const [scrollY, setScrollY] = useState(0);
  const [thumbnails, setThumbnails] = useState([]); // Define the thumbnails state variable
  const [browserHeight, setBrowserHeight] = useState(false); // Initialize with false for each word

  useEffect(() => {

    // Add a scroll event listener to update the scrollY state
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const abc = window.innerHeight;
      setBrowserHeight(abc);
    };

    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    document.title = 'Transform Contact Forms into a Brand Experience';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <div className={featurehighlight.content_solution}>
         <Helmet>
             <link rel="canonical" href="https://pepperform.net/Solution/Customer" />
             <meta name="keywords" content="customer insights, survey solutions, customer feedback, customer experience, customer satisfaction, customer engagement, customer retention" />
             <meta name="description" content="Empower your customer team with Pepperform's free online survey maker. Easily design surveys to capture feedback, tailor customer feedback surveys, and create professional surveys for free. Start now!" />
             <meta property="og:title" content="Free Online Survey Maker for Customer Teams" />
             <meta property="og:description" content="Empower your customer team with Pepperform's free online survey maker. Easily design surveys to capture feedback, tailor customer feedback surveys, and create professional surveys for free. Start now!" />
         </Helmet>
        <MainHeader  scrolled={scrollY > 100 && true} page="solution" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        <section 
        style={{backgroundSize:'cover', backgroundPosition:'top',background:`url('https://images.unsplash.com/photo-1622737133809-d95047b9e673?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2832&q=80')`}}
        className={plaintext.containerHero + " " + plaintext.hero}>
          <div className={plaintext.containerHero__content}>
            <div>
              <h2>Customer teams</h2>
              <h1>Be the data driven customer team</h1>
            </div>
            <div>
              {/* <h3>Effortless Feedback Capture with Customizable Contact Form Templates</h3> */}
              <p>Customer Success Managers, your quest is clear – ensuring your customers' delight. Pepperform empowers you to craft online surveys that uncover the keys to customer satisfaction. Dive deep into insights, refine customer journeys, and effortlessly cultivate loyalty.</p>
            </div>
          </div>
        </section>

        <section className={featurehighlight.wrapper}>
        {/* <div className={featurehighlight.container}>
            <div className={featurehighlight.images}>
              <img src={feature_highlight} alt="Feature highlight" loading="lazy" />
            </div>
            <header className={featurehighlight.header}>
              <Fade up distance={"32px"}>
                <h2>Give your customer a voice</h2>
              </Fade>
              <Fade up distance={"32px"} delay="250">
                <h3 style={{lineHeight:1.65}}>Unveiling the Power of Contact Forms: Direct Dialogue, Deeper Connection</h3>
              <p>Imagine your online product as a bustling marketplace of ideas, where visitors' thoughts can be heard through the medium of contact forms. This provides an avenue for individuals to engage with your brand on their own terms, without the pressure of immediate response.</p>
              <p>Our contact forms permit visitors to communicate with your brand based on their conditions and act as a platform for genuine connections. By bridging the gap between you and your customers, this open discussion uncovers preferences, issues, and objectives that can guide your items and strengthen your brand.
                
              </p>
              <p><a style={{color:"white"}} href="https://app.pepperform.net/templates">View this template</a> to see what you can create with our Survey Builder tool.</p>

              </Fade>
            </header>

          </div> */}

          <div className={featurehighlight.container} style={{paddingBottom:'0'}}>
          <Fade down><h2>The most useful Microtool To Onboard, Engage and Convert New Customers</h2>
          <img className={featurehighlight.image_report} src={reportImage} alt="Responsive Pepperform Survey on a mobile device" loading="lazy" />
          </Fade>

          </div>

          <header className={featurehighlight.feature_content}>
            <div>
            <Fade left distance="32px">
                <h3>Gather Onboarding Insights</h3>
                <p>Use Pepperform to collect feedback during the onboarding journey. Understand where customers encounter challenges and opportunities for improvement.</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>Measure and Optimize Customer Satisfaction</h3>
                <p>Pepperform's robust analytics tools provide a 360-degree view of customer satisfaction. Track trends, measure NPS, and identify areas for improvement. Empower your team to be data-driven.</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>Boost Engagement and Retention</h3>
                <p>Pepperform's dynamic surveys help you engage customers at every touchpoint. Collect valuable feedback to uncover their needs and concerns. Use these insights to enhance your customer success strategies, resulting in improved retention rates.</p>
              </Fade>
            </div>
            </header>

            <div className={featurehighlight.fullImage}>
              <img src={mockupImage} alt="A person viewing their Classic Pepperform Survey on an iPad" loading="lazy" />
            </div> 
            <div className={`${styles.screenWidePanel}`}>
        <div>
          <div
            className={styles.screenWidePanel_content__small}

          >
            <h2>Our Freemium Doesn't Suck</h2>
            <p>
              A lot of free online survey form services these days are too
              limited. You're pushed to upgrade to paid plans to access even
              basic features. At Pepperform, we provide you with access to a
              free, unlimited version that includes all the meaningful features.
            </p>
          </div>
          <ul className={styles.freemiumDetails}>
            <li>
              <img src={formIcon} />
              <p>
                <strong>5 Free Forms</strong>
                Create 5 forms for free, no questions asked.
              </p>
            </li>
            <li>
              <img src={unlimited} />
              <p>
                <strong>Unlimited Responses</strong>
                Collect as many responses as you need
              </p>
            </li>
            <li>
              <img src={notification} />
              <p>
                <strong>Be notified</strong>
                Access the full reporting features
              </p>
            </li>
            <li>
              <img src={lifebuoy} />
              <p>
                <strong>Be supported</strong>
                Our support team is here to help
              </p>
            </li>
          </ul>
          <a href="https://app.pepperform.net/register" className={styles.primaryCta}>Sign up FREE</a>
          <span className={styles.primaryCta_helper}>
            No credit card required
          </span>
        </div>
      </div>
        </section>
        <section className={twocolblock.container + " " + twocolblock.left_image}>

          <div className={twocolblock.image_area} style={{ backgroundColor: "#686197" }}>
            <div className={twocolblock.image}>
              <img style={{margin:"20px"}} src={'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/customer-feedback.jpg'} alt="A Customer Feedback Pepperform Survey Template" />
            </div>
            <div className={twocolblock.image_description_container}>
              <div className={twocolblock.image_description}>
                <h2>24</h2>
                <p>Question types</p>
              </div>
              <div className={twocolblock.image_description}>
                <h2>Unlimited</h2>
                <p>Responses</p>
              </div>
            </div>
          </div>
          <div className={twocolblock.description_area}>
              <Fade left distance="32px">
                <h2>Start with a Template or Craft it all from scratch</h2>
              </Fade>
              <Fade left distance="32px" delay="250">
                <p style={{paddingTop:"80px"}}>
                With our Customer Feedback Templates, you can streamline the process of gathering valuable insights from your clients. Design surveys tailored to your specific customer success needs and collect actionable feedback throughout the entire customer journey.
                <a href="https://app.pepperform.net/templates/">Browse our Survey Form Templates</a>
                </p>
              </Fade>
          </div>

        </section>
        {/* <ArticleBrowser articles={articlefeatures} /> */}
        {/* <TemplatesCarousel title="feedback" /> */}

        <section className={twocolblock.container + " " + twocolblock.right_image} >
          
          <div className={twocolblock.description_area}>
            <h2>Engage your audience, tell a story</h2>
            <p>Pepperform empowers you to go beyond the ordinary and transform your feedback forms into compelling narratives. With our platform, it's not just about gathering data; it's about telling your customer success story. Seamlessly integrate text, images, and videos into your forms to captivate your clients and effectively deliver your message.

            <a href="https://app.pepperform.net/templates">Browse our Survey Form Templates</a>

              .</p>
          </div>
          <div className={twocolblock.image_area} style={{ backgroundColor: '#a4c3c4' }}>
            <div className={twocolblock.image +" "+twocolblock.image_full }>
              <img src={customerSupportImage} alt="Pepperform Survey displayed on a Desktop Device" loading='lazy' />
            </div>

          </div>

        </section>
        <FAQ source={faqlist} />
        <MainFooter />

      </main>
      </div>
    </div>
  );
}

export default SolutionRSVP;
