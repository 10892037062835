import React, { useState, useEffect } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import blog from './css/Blog.module.scss'
import mainImage from './images/article-2.jpg'


const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Pre-Webinar Survey: Tell Us What You Want to Learn",category: "template",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "Pre-Webinar Survey: Tell Us What You Want to Learn", link: "https://pepperform.net/template/feedback/Pre-Webinar-Survey" }] },
]





function Article2() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  useEffect(() => {
    document.title = '8 powerful questions to ask in your next customer satisfaction survey';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
      <header className={blog.header}>
            <h1>8 powerful questions to ask in your next customer satisfaction survey</h1>
            <p>Running a customer satisfaction survey is crucial for gaining valuable insights and understanding the needs and preferences of your customers. By collecting feedback directly from your customers, you can make informed decisions, improve your products or services, and build stronger customer relationships. In this article, we'll share eight powerful questions that you can include in your next customer satisfaction survey to gather actionable insights.</p>
            <img src={mainImage} alt="Plant" loading="lazy" />

          </header>
      <main className={blog.wrapper}>
        <section className={blog.blog_post}>

          <article>
            <h1>Questions</h1>
                <h2>1. How do you feel about the product?</h2>
                <p>This question helps us build a comprehensive picture of customer satisfaction and identify areas for improvement.</p>
                
                <h2>2. How likely are you to recommend us on a scale from 0 to 10?</h2>
                <p>This question follows the classic Net Promoter Score (NPS) format, which allows customers to rate their likelihood of recommending our product or service to others. By using a numerical scale, we can easily quantify and track customer sentiment over time. The NPS question provides a standardized measure of customer loyalty and advocacy, helping us identify promoters, passives, and detractors. It allows us to assess the overall satisfaction of our customers and make data-driven improvements based on their feedback.</p>
                
                <h2>3. Which of the following words would you use to describe our product?</h2>
                <p>This question is designed to gather feedback and categorize customer perceptions about our product. By providing a list of descriptive words, we can prompt customers to choose the ones that best represent their experience. However, it's important to be mindful of any bias that may arise from the selection of words. The aim is to quickly identify positive and negative traits associated with our product, enabling us to gain valuable insights into its strengths and areas for improvement. This question helps us understand how customers perceive our product and guides us in refining our messaging and positioning based on their feedback.</p>
                
                <h2>4. What is your favorite feature of the product or service?</h2>
                <p>This question allows us to understand which specific features of our product or service customers find most valuable. By identifying their favorite feature, we gain insights into the aspects that resonate with them the most. This knowledge can guide us in prioritizing and enhancing these key features to further delight our customers.</p>
                
                <h2>5. What 3 features are we missing?</h2>
                <p>By asking customers to identify three features they feel are missing from our product or service, we can uncover valuable opportunities for improvement. This question not only highlights areas where we can enhance our offering but also provides insight into the customers' desires and expectations. Understanding their desired features helps us align our development efforts with their needs, ultimately delivering a more tailored and satisfying experience.</p>
                
                <h2>6. What is your goal in adopting the product?</h2>
                <p>Understanding the goals that customers have in mind when adopting our product allows us to align our efforts with their desired outcomes. By asking this question, we gain valuable insights into their motivations and expectations. This knowledge enables us to refine our marketing messaging, enhance product positioning, and provide the necessary resources to help customers achieve their goals effectively.</p>
                
                <h2>7. Does the product/service help you achieve this goal?</h2>
                <p>This question helps us gauge the effectiveness of our product in meeting the customers' goals. By seeking their feedback on whether our product has helped them achieve their intended objectives, we can assess the impact and value it brings to their lives or businesses. This information allows us to make data-driven decisions, refine our product strategy, and ensure that our offering continues to align with customer needs.</p>
                
                <h2>8. Based on your experience with the product, would you buy it again?</h2>
                <p>Asking customers if they would repurchase our product based on their experience provides valuable insights into their satisfaction and brand loyalty. This question measures their level of satisfaction and commitment to our product. Positive responses indicate a strong likelihood of repeat business and customer loyalty, while negative responses signal areas that need improvement. Understanding their willingness to repurchase helps us gauge customer satisfaction and identify opportunities to enhance the overall customer experience.</p>
                
          </article>
        </section>
        <section className={blog.reco} >
          <div className={blog.quote}>
          By collecting feedback directly from your customers, you can make informed decisions, improve your products or services, and build stronger customer relationships.
          </div>
              {/* {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })} */}

        </section>
      </main>
      {/* <TemplatesCarousel /> */}
      <MainFooter/>
      </div>

    </div>
  );
}

export default Article2;
