import React, { useState, useEffect } from 'react';
import styles from '../css/404.module.scss'

const Page404 = ({ props, optionrefetch }) => {

    return (
      <div className={styles.page}>
      <div className={styles.logo}>
        <a href="/"><img src="/logo.svg" alt="Logo" /></a>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.image}>
          <svg width="74" height="120" viewBox="0 0 74 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M72.8847 117.508C72.8847 117.508 75.7566 118.773 67.9199 119.163C60.0832 119.552 18.612 119.747 14.3286 119.163C10.0452 118.579 1.77047 118.92 12.771 117.508C23.7716 116.096 35.1129 116.924 46.2108 116.096C57.3087 115.269 71.2298 116.048 72.8847 117.508Z" fill="#F0EBE7"/>
            <path d="M15.4008 93.1214C15.4008 93.1214 11.02 91.1257 10.2899 80.904C9.55978 70.6822 14.0379 62.4561 14.476 49.5086C14.914 36.561 7.61277 29.2597 1.96647 33.4458C-3.67984 37.6318 4.78962 45.8093 3.23202 62.6995C1.67442 79.5411 1.23635 96.5773 14.622 100.958C14.5733 100.958 16.861 97.7942 15.4008 93.1214Z" fill="#413B39"/>
            <path d="M50.739 117.557C50.739 117.557 67.6292 117.557 68.6514 117.557C69.6736 117.557 70.9391 109.866 61.9829 109.72C53.0267 109.574 47.6238 109.72 47.6238 109.72L50.739 117.557Z" fill="#AFA7A0"/>
            <path d="M66.2671 117.556C66.2671 117.556 66.7538 115.707 65.3909 114.393" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M63.0527 117.558C63.0527 117.558 63.0527 116.049 62.3713 115.367" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M56.7262 7.98917C56.7262 7.98917 62.6645 -0.772347 66.5099 0.0551289C70.3552 0.882605 70.8906 18.941 67.532 20.0606C64.2221 21.2288 56.7262 7.98917 56.7262 7.98917Z" fill="#413B39"/>
            <path d="M39.3027 8.62118C39.3027 8.62118 31.466 2.97488 26.6959 3.31561C21.8771 3.60766 26.7932 17.8208 30.3465 21.4714C33.8998 25.122 39.3027 8.62118 39.3027 8.62118Z" fill="#413B39"/>
            <path d="M61.2036 38.6543C61.2036 38.6543 67.4827 34.809 68.5048 26.7776C69.527 18.7462 65.8764 6.04199 48.3534 6.04199C30.8303 6.042 27.5204 20.6932 28.786 28.2378C29.9542 35.3931 33.5561 37.6321 33.5561 37.6321C33.5561 37.6321 29.6621 46.4423 27.7151 51.6019C26.2062 55.5932 25.4761 59.6819 26.0602 64.4521C26.0602 64.4521 20.2192 73.1162 16.5686 80.3201C13.064 87.232 10.1434 104.56 13.3073 116.291C13.502 117.07 14.2322 117.605 15.011 117.605L61.1062 117.605C65.1949 117.605 69.9651 110.888 66.2171 84.2628C66.2171 84.2628 68.1641 79.6874 66.4605 70.1957C64.7569 60.7041 61.2036 38.6543 61.2036 38.6543Z" fill="#CDC8C4"/>
            <path d="M20.6589 117.557C20.6589 117.557 23.6281 117.557 24.5042 117.557L45.97 117.557C48.4524 111.083 40.9078 108.649 37.0624 108.649C34.0932 108.649 27.2787 108.649 27.2787 108.649C27.2787 108.649 23.6281 108.795 22.0705 111.327C20.2208 114.296 20.5616 117.411 20.6589 117.557Z" fill="#AFA7A0"/>
            <path d="M55.9958 85.8684C57.7968 87.1339 59.4517 87.8641 60.6199 88.3021C61.8855 88.7402 63.2971 88.4482 64.2219 87.426C65.4874 86.0631 66.2662 84.2134 66.2662 84.2134C66.2662 84.2134 67.5318 81.2442 67.1424 75.1599C67.1424 75.1599 66.9964 71.1685 61.4474 69.9516C57.31 69.0268 55.071 71.0225 55.071 71.0225C55.1683 71.8013 55.3143 72.5801 55.4604 73.4562C55.9471 76.3767 55.363 81.439 55.071 83.7754C54.9736 84.5542 55.3143 85.3816 55.9958 85.8684Z" fill="#AFA7A0"/>
            <path d="M66.2676 84.2632C66.2676 84.2632 65.4888 86.1128 64.2232 87.4757C63.2984 88.4492 61.8868 88.7899 60.6213 88.3032C59.4531 87.8651 57.7981 87.135 55.9971 85.8694C55.3157 85.3827 54.975 84.6039 55.121 83.7764C55.413 81.44 55.9485 76.3778 55.5104 73.4573C54.8776 69.5633 54.4882 66.3994 54.4882 66.3994" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M60.376 88.1576C60.376 88.1576 62.323 86.6487 62.5664 83.9229" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M57.3078 86.6972C57.3078 86.6972 58.768 85.3343 58.9141 82.9492" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M33.1175 73.7495C36.5248 75.8425 38.2771 77.0594 38.0824 79.3958C37.839 82.1216 39.932 82.365 40.8569 82.6083C42.2198 82.9491 44.7996 83.1924 46.7952 82.9491C47.6714 82.8517 49.375 81.2454 49.375 77.6435C49.375 74.3336 46.6979 68.6873 41.5383 65.8155C41.5383 65.8155 37.3036 64.988 34.4804 68.2492C32.6308 70.4396 33.1175 73.7495 33.1175 73.7495Z" fill="#AFA7A0"/>
            <path d="M26.0598 64.4531C26.0598 64.4531 26.6926 69.71 31.3167 72.6305C35.9408 75.551 38.3746 76.7192 38.1312 79.445C37.8878 82.1708 39.9809 82.4142 40.9057 82.6576C42.2686 82.9983 44.8484 83.2417 46.8441 82.9983C47.7202 82.901 49.4238 81.2947 49.4238 77.6927C49.4238 74.3828 46.7467 68.7365 41.5871 65.8647" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M43.8268 82.9986C43.8268 82.9986 46.2118 82.4631 46.0658 78.8125" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M40.8585 82.6082C40.8585 82.6082 42.4161 81.5373 42.2214 78.8115" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M21.1938 81.4879C21.1938 81.4879 25.5745 76.085 32.4377 79.979C38.7655 83.581 40.3231 100.179 34.9202 108.6" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M45.9192 117.558C48.4016 111.084 40.857 108.65 37.0116 108.65C34.0425 108.65 27.2279 108.65 27.2279 108.65" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M42.465 117.556C42.465 117.556 43.6819 115.658 42.465 114.393" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M39.6883 117.557C39.6883 117.557 39.9803 115.416 39.1528 114.686" stroke="#413B39" stroke-width="1.5" stroke-miterlimit="10" strokeLinecap="round"/>
            <path d="M42.9133 19.0437C43.3956 17.5149 42.8822 15.9902 41.7667 15.6383C40.6511 15.2863 39.3557 16.2403 38.8734 17.769C38.391 19.2978 38.9044 20.8224 40.02 21.1744C41.1355 21.5264 42.4309 20.5724 42.9133 19.0437Z" fill="#413B39"/>
            <path d="M58.0356 17.6709C57.4082 16.1958 56.0269 15.3711 54.9504 15.8289C53.874 16.2868 53.5099 17.8538 54.1374 19.329C54.7648 20.8041 56.1461 21.6288 57.2226 21.171C58.299 20.7131 58.6631 19.1461 58.0356 17.6709Z" fill="#413B39"/>
            <path d="M48.7986 21.4941C48.7986 21.4941 48.8802 23.2485 48.8394 23.3709C48.7986 23.4933 46.8811 24.6356 44.3516 24.3908" stroke="#413B39" stroke-width="1.3" stroke-miterlimit="10"/>
            <path d="M48.8379 23.4121C48.8379 23.4121 50.3066 24.6361 52.9993 24.7585" stroke="#413B39" stroke-width="1.3" stroke-miterlimit="10"/>
            <path d="M46.9621 18.9242C46.2685 18.965 45.8605 19.7402 46.3093 20.3114C46.7581 20.8825 47.4517 21.4129 48.5532 21.4945C49.7364 21.5761 50.5523 21.0049 51.0419 20.3521C51.4499 19.8218 51.0827 19.0466 50.4299 19.0058C49.4508 18.965 48.1452 18.8834 46.9621 18.9242Z" fill="#413B39"/>
            <path d="M33 38C34.1667 38.8333 37.9 40.6 43.5 41" stroke="#413B39" stroke-width="1.5" strokeLinecap="round"/>
          </svg>
        </div>
        <div className={styles.content}>
          <h1>🐾 Page not found</h1>
          <p>Oh no, the page you're seeking is nowhere to be found. Head back home and continue your Pepperform journey.</p>
          <a className={styles.button} href="/">Return Home</a>
          <h2>Error(404)</h2>
        </div>
      </div>
      </div>
    );
};
export default Page404;

