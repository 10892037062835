import React, { useState } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import blog from './css/Blog.module.scss'
import mainImage from './images/iPad.jpg'


const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Pre-Webinar Survey: Tell Us What You Want to Learn",category: "template",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "Pre-Webinar Survey: Tell Us What You Want to Learn", link: "https://pepperform.net/template/feedback/Pre-Webinar-Survey" }] },
]





function Article7() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
      <header className={blog.header}>
            <h1>
                6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers
            </h1>
            <p>
                When it comes to acquiring new customers, the onboarding process plays a crucial role in setting the foundation for a successful and long-lasting relationship. It's the perfect opportunity to engage customers, address their needs, and guide them towards taking desired actions. 
            </p>

            <img src={mainImage} alt="Plant" loading="lazy" />

          </header>
      <main className={blog.wrapper}>
        <section className={blog.blog_post}>

          <article>
            <h1>
                What is the onboarding process for CSM?
            </h1>
                
             <p>
                The onboarding process for Customer Success Managers (CSMs) is a critical phase that sets the stage for building strong relationships with customers and ensuring their success. As a CSM, your primary goal during onboarding is to guide customers through a seamless transition and help them fully understand and leverage your product or service.
            </p>
            <h1>
                What does successful onboarding look like?
            </h1>
                
             <p>
                A successful onboarding process sets the stage for a positive and fruitful client relationship.
                <ul>
                    <li><h2>Establishing the Client:</h2>The first step is to establish a solid foundation with the client. This includes finalizing the pricing and ensuring that a written contract is in place. It's important to create the necessary organizational structures and set up accounts to facilitate seamless collaboration.</li>
                    <li><h2>Plan B Preparation:</h2>While striving for a successful onboarding, it's essential to have a backup plan in case unexpected challenges arise. By providing clients with direct contact information in case of any issues, you demonstrate your commitment to resolving problems quickly and efficiently, ensuring their satisfaction.</li>
                    <li><h2>Understanding Goals:</h2> Although you may have an initial understanding of your client's objectives, it's crucial to directly discuss and clarify their goals. By using a questionnaire or similar tool, you can convert their answers into actionable team goals. This helps align your team and enables them to meet the client's needs effectively.</li>
                    <li><h2>Assigning Responsibilities:</h2> During the onboarding process, it's important to designate a lead contact within your team who can best address the client's requirements. Clearly communicate each step, deadline, and responsibility to ensure smooth project management and delivery.</li>
                    <li><h2>Check-In and Progress Evaluation:</h2> Around 30 days into the onboarding process, it's beneficial to conduct a check-in with the client. This allows you to assess the progress made and gather valuable feedback. By evaluating your team's performance and addressing any concerns, you can further enhance the client's experience.</li>
                </ul>
            </p>
            <h1>6 onboarding questions</h1>
            <ol>
                <li>
                    <h2>What are your specific goals and objectives?</h2>
                    Understanding your customers' unique goals and objectives is the first step towards tailoring your services to meet their needs. By delving deeper into their aspirations, you can align your offerings and demonstrate how your solutions can help them achieve their desired outcomes.
                </li>
                <li>
                    <h2>What challenges or pain points are you currently facing?</h2>
                    Identifying your customers' pain points allows you to address their specific concerns and provide targeted solutions. By empathizing with their challenges, you can showcase how your products or services can alleviate their pain and offer a more efficient and effective way forward.
                </li>
                <li>
                    <h2>How do you envision success with our product/service?</h2>
                    By envisioning success together, you can create a shared vision of how your product or service will benefit your customers. Understanding their expectations and desired outcomes enables you to customize your approach and highlight the specific value your solution brings to the table.
                </li>
                <li>
                    <h2>What factors influenced your decision to choose our product/service?</h2>
                    Exploring the decision-making process provides valuable insights into why customers chose your offering over competitors. This knowledge helps you identify your unique selling points and refine your messaging to resonate with potential customers in the future.
                </li>
                <li>
                    <h2>How can we best support you during the onboarding process?</h2>
                    Asking customers how you can support them during the onboarding phase demonstrates your commitment to their success. By offering personalized guidance and assistance, you can ensure a smooth transition and help them fully leverage the benefits of your product or service.
                </li>
                <li>
                    <h2>How do you prefer to communicate and receive updates?</h2>
                    Understanding your customers' communication preferences allows you to establish effective channels for ongoing collaboration and support. Whether it's email, phone calls, or a project management tool, aligning your communication methods ensures seamless interaction and fosters strong customer relationships.
                </li>
            </ol>
            <p>
            By incorporating these six key questions into your onboarding process, you can uncover valuable insights, tailor your approach, and ultimately increase customer conversion rates. Remember, successful onboarding is not just about acquiring customers but also about nurturing lasting relationships built on trust, understanding, and shared goals.
            </p>
          </article>
        </section>
        <section className={blog.reco} >
          <div className={blog.quote}>
            Successful onboarding is not just about acquiring customers but also about nurturing lasting relationships built on trust, understanding, and shared goals.
          </div>
              {/* {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })} */}

        </section>
      </main>
      {/* <TemplatesCarousel /> */}
      <MainFooter/>
      </div>

    </div>
  );
}

export default Article7