import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Carousel from "react-multi-carousel";
import Fade from 'react-reveal/Fade';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import 'react-multi-carousel/lib/styles.css';

import TemplateThumbnailTile from '../DumbComponents/TemplateThumbnailTile'

import templateshowcase from '../css/TemplateShowCase.module.scss'

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const GET_CATEGORY_TEMPLATES = gql`
query($title: String!, $page: Int!) {
  categorytemplates(title: $title, page: $page) {
    total
    totalPages
    currentPage
    templates {
      id
      name
      description
      category
      shorthand
      featured
      updated_at
      myform
      previewImage
      pageview
      rating
    }
  }
}`;


const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    // const { carouselState: { currentSlide } } = rest;
    return (
        <div className="carousel-button-group">
            <span className='left-arrow' onClick={() =>
                previous()}> <ArrowBackIcon /></span>
            <span className='right-arrow' onClick={() => next()}><ArrowForwardIcon /></span>
        </div>

    );
};

function TemplatesCarousel(props) {
    const { loading, error, data } = useQuery(GET_CATEGORY_TEMPLATES, {
        variables: { title: props.title ?? "popular", page: 1 },
      });
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;
    return (
        <React.Fragment>

            <section className={templateshowcase.container}>
                <header className={templateshowcase.header_info}>
                    <Fade up distance={'32px'}>
                        <h2>
                        <h3>Survey templates to</h3>
                        🔎 Uncover Any Insights</h2>
                    </Fade>
                    <Fade delay={550}>
                        <div style={{paddingTop:"56px"}}>
                            <p>Explore a wide range of online survey templates, from contact forms to team health checks and user research templates. They're here to help you uncover insights and enhance collaboration.</p>
                        </div>
                    </Fade>

                </header>
                <div className={templateshowcase.thumbnails_container}>
                    <div className={templateshowcase.thumbnails}>
                        <Carousel responsive={responsive} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}  >
                        {data?.categorytemplates?.templates?.map((template, index) => {
                        return (
                            <Fade distance={'32px'} up delay={index * 200} key={index}>
                            <TemplateThumbnailTile template={template} index={index} />
                            </Fade>
                        );
                        })}
                        </Carousel>
                    </div>

                </div>

            </section>
        </React.Fragment>

    );
}

export default TemplatesCarousel;
