import React, { useState, useEffect, useRef } from "react";
import styles from "./css/NewHomepage.module.scss";
import { Helmet } from 'react-helmet';
import { PepperIcon } from "./SVGCollections";
import axios from "axios";
import logo from "./logo.svg";
import hero_desktop_1 from "./images/hero_desktop_1.jpg";
import unlimited from "./images/unlimited.svg";
import notification from "./images/notification.svg";
import lifebuoy from "./images/lifebuoy.svg";
import formIcon from "./images/contact-form.svg";
import mockup from "./images/device1.jpg";
import mockup2 from "./images/device2.jpg";
import mockup3 from "./images/device3.jpg";
import mockup4 from "./images/device4.jpg";
import classic from "./images/classic.jpg";
import CustomLink from "./DumbComponents/CustomLink";
import footer from "./css/MainFooter.module.scss";
import MainHeader from './MainHeader';
import MainFooter from './MainFooter'

import footer_image from "./images/ipadMock.png";

function NewHomepage() {
  const [scrollY, setScrollY] = useState(0);
  const [showPanel, setShowPanel] = useState(false);
  const [hideWords, setHideWords] = useState([false, false, false, false]); // Initialize with false for each word
  const [browserHeight, setBrowserHeight] = useState(false); // Initialize with false for each word
  const [thumbnails, setThumbnails] = useState([]); // Define the thumbnails state variable
  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  const [activeContentShowcase, setActiveContentShowcase] = useState(0); // Track the active content
  const contentArray = [
    "Pepperform offers users the flexibility of two distinct form presentation modes. First, the slideshow mode, which allows for a one-question-at-a-time approach. This mode guides respondents through the survey, presenting each question individually, ensuring a focused and streamlined experience.",
    "Pepperform's classic mode takes a different approach. It presents all the questions of your survey at once, allowing respondents to view the entire survey and provide their answers in one comprehensive interaction. This mode is suitable for scenarios where users prefer to see the complete set of questions upfront and navigate freely.",
    // "And here's the third content.",
    // "And here's the third content.",
    // Add more content as needed
  ];
  const contentContainerRef = useRef(null);
  const [isPanelDocked, setIsPanelDocked] = useState(false);
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Check if it's the last paragraph
        setShowPanel(true);
        const lastIndex = contentArray.length - 1;
      } else {
        setShowPanel(false); // The last paragraph is not in view
      }
      // console.log(entry)
    });
  };
  const observer = useRef(
    new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0, // Trigger when the entire element is in view
    })
  );
  useEffect(() => {
    document.title =
      "Create Professional Premium Online Surveys with Pepperform";

    // Fetch JSON data (replace with your JSON API URL)
    const jsonDataUrl =
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40pepperform-official"; // Replace with your JSON API URL

    axios
      .get(jsonDataUrl)
      .then((response) => {
        // Extract relevant data and update state with the first 4 items
        const extractedThumbnails = response.data.items.slice(0, 4);
        setThumbnails(extractedThumbnails);
      })
      .catch((error) => {
        console.error("Error fetching JSON data:", error);
      });
  }, []); // Empty dependency array to fetch JSON data once on component mount

  // Use IntersectionObserver to determine which content is in view
  useEffect(() => {
    if (contentContainerRef.current) {
      const contentElements = contentContainerRef.current.querySelectorAll(
        `.${styles.content}`
      );

      contentElements.forEach((element, index) => {
        element.setAttribute("data-index", index);
        observer.current.observe(element); // Use observer.current
      });
    } else {
    }
    return () => {
      observer.current.disconnect(); // Use observer.current
    };
  }, [contentArray]);

  useEffect(() => {

    // Add a scroll event listener to update the scrollY state
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const abc = window.innerHeight;
      setBrowserHeight(abc);
      // console.log(window.scrollY)
    };

    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const panelOffset = browserHeight;
    const contentHeight = 300;

    const scrollPosition = scrollY - panelOffset;

    if (scrollPosition >= 0) {
      const newActiveContent = Math.floor(scrollPosition / contentHeight);

      if (newActiveContent < contentArray.length) {
        setActiveContentShowcase(newActiveContent);
      }
    } else {
      setActiveContentShowcase(0); // Back to the first content when scrolling back up
      if (scrollY <= 0) {
        // Handle the case where the user is at the top of the page
        setActiveContentShowcase(0);
      }
    }
    if (showPanel) {
      console.log(browserHeight + " " + scrollY + " " + isPanelDocked);
      if (scrollY >= browserHeight * 5 && scrollY < browserHeight * 6) {
        setIsPanelDocked(true);
      } else if (scrollY >= browserHeight * 7) {
        setIsPanelDocked(false);
      } else if (scrollY < browserHeight * 5) {
        setIsPanelDocked(false);
      }
    }
  }, [scrollY, showPanel, contentArray]);

  useEffect(() => {
    const animateLetters = (wordElement, wordIndex) => {
      const letters = wordElement.querySelectorAll(`.${styles.animateLetter}`);
      const totalLetters = letters.length;

      letters.forEach((letter, index) => {
        setTimeout(() => {
          letter.classList.add(styles.animate);
          if (index === totalLetters - 1) {
            // console.log("last letter");

            setTimeout(() => {
              if (wordIndex === 4) {
                // This is the last word
                console.log("Last word animated!");
              } else {
                wordElement.classList.add(styles.hide);
              }
            }, 800); // Adjust the delay as needed
          }
        }, index * 88);
      });
    };

    // Call the animation function for each word when the component mounts
    const wordElements = document.querySelectorAll(`.${styles.animateWord}`);
    wordElements.forEach((wordElement, index) => {
      setTimeout(() => {
        animateLetters(wordElement, index);
      }, index * 1400);
    });
  }, []);

  return (
    <div className={styles.app}>
      <Helmet>
        <link rel="canonical" href="https://pepperform.net" />
        <meta
          name="keywords"
          content="online survey builder, free survey maker, design surveys, customize surveys, create surveys, personalize surveys, professional surveys, survey platform, survey software, survey tool"
        />
        <meta
          name="description"
          content="Pepperform, an affordable survey platform, empowers teams to gather insights and craft engaging customer touchpoints. Join us at Pepperform.net!"
        />
        <meta
          property="og:title"
          content="Create Professional Surveys | Online Survey Builder | Pepperform"
        />
        <meta
          property="og:description"
          content="Design and customize surveys effortlessly with our free online survey maker. Build, create, and personalize professional surveys with ease. Start now!"
        />
      </Helmet>
      {/* <nav
        className={`${styles.mainNavigation} ${
          scrollY > 100 && styles.scrolled
        }`}
      >
        <ul>
          <li>Features</li>
          <li>Solution</li>
          <li>
            <a href="/pricing">Pricing</a>
          </li>
          <li>
            <a href="https://app.pepperform.net/templates">Templates</a>
          </li>
        </ul>
        <a href="/" className={styles.mainLogo}>
          <PepperIcon />
        </a>
        <ul className={styles.mainNavigation_actions}>
          <li>Login</li>
          <li className={styles.cta}>Register</li>
        </ul>
      </nav> */}
              <MainHeader scrolled={scrollY > 100 && true} page="/" setNav={setNav} state={state} />

      <div className={styles.parallaxPanel}>
        <div>
          <h1
            className={`${styles.heroText} ${hideWords[0] ? styles.hide : ""}`}
            style={{
              opacity: 1 - scrollY * 0.002,
              transform: `translateY(${scrollY * 0.3}px)`,
            }}
          >
            <span className={`${styles.word} ${styles.animateWord}`}>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                e
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                n
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                g
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                a
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                g
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                e
              </span>
            </span>
          </h1>

          <h1
            className={`${styles.heroText} ${hideWords[1] ? styles.hide : ""}`}
            style={{
              opacity: 1 - scrollY * 0.002,
              transform: `translateY(${scrollY * 0.3}px)`,
            }}
          >
            <span className={`${styles.word} ${styles.animateWord}`}>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                c
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                o
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                n
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                n
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                e
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                c
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                t
              </span>
            </span>
          </h1>

          <h1
            className={`${styles.heroText} ${hideWords[2] ? styles.hide : ""}`}
            style={{
              opacity: 1 - scrollY * 0.002,
              transform: `translateY(${scrollY * 0.3}px)`,
            }}
          >
            <span className={`${styles.word} ${styles.animateWord}`}>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                c
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                o
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                n
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                v
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                e
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                r
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                t
              </span>
            </span>
          </h1>
          <h1
            className={`${styles.heroText} ${hideWords[3] ? styles.hide : ""}`}
            style={{
              opacity: 1 - scrollY * 0.002,
              transform: `translateY(${scrollY * 0.3}px)`,
            }}
          >
            <span className={`${styles.word} ${styles.animateWord}`}>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                w
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                i
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                t
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                h
              </span>
            </span>
          </h1>

          <h1
            className={`${styles.heroText} ${hideWords[3] ? styles.hide : ""}`}
            style={{
              opacity: 1 - scrollY * 0.002,
              transform: `translateY(${scrollY * 0.3}px)`,
            }}
          >
            <span
              className={`${styles.word} ${styles.animateWord} ${styles.twolines}`}
            >
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                p
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                r
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                e
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                m
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                i
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                u
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                m
              </span>

              <br />
              {/* 
          </span> </h1>

          <h1
            className={`${styles.heroText}`}
            style={{ opacity: 1 - scrollY * 0.002, transform: `translateY(${scrollY * 0.3}px)` }}
          > 
            <span className={`${styles.word} ${styles.animateWord}`}>

          */}
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                s
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                u
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                r
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                v
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                e
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                y
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                s
              </span>
              <span className={`${styles.letter} ${styles.animateLetter}`}>
                .
              </span>
            </span>
          </h1>
        </div>

        {/* <div
          className={styles.heroImage}
          style={{
            transform: `translateY(${scrollY * 0.5}px)`,
          }}
        >
          <iframe src="https://app.pepperform.net/form/c50094f0-484f-11ee-874f-b32ee7d4b25b" width="1000" height="600" frameBorder="0"></iframe>
        </div> */}

        <div
          className={styles.textInfo}
          style={{
            opacity: 1 - scrollY * 0.005,
            transform: `translateY(${scrollY * 0.1}px)`,
          }}
        >
          <h3>Create Engaging Online Surveys in Minutes</h3>
          <p>
          We're on a mission to make surveys more than just forms – we want them to be memorable, interactive experiences that connect with your audience. Build impressive online surveys in minutes and unlock insights – no coding required.
          </p>
          <div className={styles.buttons}>
            <a href="https://www.app.pepperform.net/register"><button className={styles.primary}>Sign up</button></a>
          </div>
        </div>
      </div>

      <div className={`${styles.screenWidePanel}`}>
        <div className={styles.screenWidePanel_content__small}>
          <h2>
            Use Pepperform to Create Online Touchpoints That Truly Connects with
            Your Customers
          </h2>
          <p
            style={{
              opacity: Math.min(1, Math.max(0, (scrollY - browserHeight) * 0.12)), // Adjust the offset (200) as needed
              transform: `translateY(${Math.min(0, (scrollY - browserHeight) * 0.2)}px)`, // Adjust the offset (200) as needed
            }}
          >
            Everyone is after your audience's attention these days. That's why
            it's crucial to create touchpoints that truly captivate. 
            That's where we come in – Pepperform surveys help teams create
            memorable touchpoints in their customer journey. Add logo, craft
            captivating stories with text, video and images before capturing data. 
            Give your audience something valuable and make a genuine connections
            that count.
          </p>
          <div
            className={styles.scrollLine}
            style={{
              height: `${
                scrollY < 1600 && Math.max(Math.min(1550, scrollY - 1200), 600)
              }px`,
              width: "2px",
            }}
          >
            <h2
              className={styles.heading}
              style={{
                opacity: Math.min(1, Math.max(0, (scrollY - 820) * 0.008)),
                transform: `translateY(${
                  scrollY > (browserHeight*3) &&
                  scrollY < (browserHeight*4) &&
                  Math.min((scrollY + 0) * 0.2, 890)
                }px)`,
              }}
            >
              Why Use Pepperform?
            </h2>
          </div>
        </div>
      </div>
      <div className={styles.screenWidePanel}>
        <div className={styles.screenWidePanel_content__fourcol}>
          <div
            style={{
              marginTop: scrollY * 0.12,
              transform: `translateY(${Math.max(
                Math.min(-500 + (scrollY - 900) * 0.55, 0), // Ensure transform doesn't go below 0
                -800
              )}px)`,
            }}
            className={styles.panel}
          >
            <img alt="A person viewing their Pepperform Survey on a Desktop" className={styles.panel_image} src={mockup} />
            <h3>1</h3>
            <p>
              Show Your Brand.
              <span>
                Customize logo, color, fonts and styles to make your survey a
                true extension of your brand.
              </span>
            </p>
          </div>
          <div
            style={{
              marginTop: scrollY * 0.12,
              transform: `translateY(${Math.max(
                Math.min(-500 + (scrollY - 980) * 0.5, 0), // Ensure transform doesn't go below 0
                -800
              )}px)`,
            }}
            className={styles.panel}
          >
            <img 
            alt="A person viewing their Pepperform Survey on a Desktop"
            className={styles.panel_image} src={mockup2} />

            <h3>2</h3>
            <p>
              Works in any device.
              <span>
                Your Pepperform is responsive, optimised for Desktop, Tablet or
                Mobile Devices.
              </span>
            </p>
          </div>
          <div
            style={{
              marginTop: scrollY * 0.12,
              transform: `translateY(${Math.max(
                Math.min(-500 + (scrollY - 880) * 0.45, 0), // Ensure transform doesn't go below 0
                -800
              )}px)`,
            }}
            className={styles.panel}
          >
            <img 
            alt="A person viewing their Pepperform Survey on a Desktop"
            className={styles.panel_image} src={mockup3} />
            <h3>3</h3>
            <p>
              Flexible and versatile.
              <span>
                Capture answers using NPS scales, multiple choice and selects.
                And distribute vouchers, whitepapers and more.
              </span>
            </p>{" "}
          </div>
          <div
            style={{
              marginTop: scrollY * 0.12,
              transform: `translateY(${Math.max(
                Math.min(-500 + (scrollY - 950) * 0.46, 0), // Ensure transform doesn't go below 0
                -800
              )}px)`,
            }}
            className={styles.panel}
          >
            <img className={styles.panel_image} src={mockup4} />
            <h3>4</h3>
            <p>
              Own Your Data.
              <span>
                Be notified when responses come in, then edit delete or export
                your data as CSV.
              </span>
            </p>
          </div>
        </div>
      </div>

      <div
        className={`${styles.screenWidePanel} ${styles.top} ${styles.fullWidth}`}
      >
        <div className={styles.screenPanel__types_content}>
          <div className={styles.title}>
            <h2>How teams use Pepperform</h2>
          </div>

          <div className={styles.table}>
            <div className={styles.tableCol}>
              <h3>Lead Generation</h3>
              <img
                className={styles.types_image}
                alt="An image of a Lead Generation Survey Template"
                src="https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/templates/empowerment.jpg"
              />
            </div>
            <div className={styles.tableCol}>
              <h3>Customer Feedback</h3>
              <img
                className={styles.types_image}
                alt="An image of a Pepperform Customer Feedback Survey Template"
                src="https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/customer-feedback.jpg"
              />
            </div>
            <div className={styles.tableCol}>
              <h3>Product/Service Guide</h3>
              <img
                className={styles.types_image}
                alt="A Pepperform Product Service Guide Template with a few Customer Experience Survey"
                src="https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/templates/product_feedback.jpg"
              />
            </div>
            <div className={styles.tableCol}>
              <h3>Internal</h3>
              <img
                className={styles.types_image}
                alt="A Pepperform PERMA Employee Happiness Survey Template"
                src="https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/templates/perma.jpg"
              />
            </div>
          </div>
          {/* <div className={`${styles.table__header} ${styles.hideOnMobile}`}>
            How?</div>

            <div className={`${styles.table} ${styles.hideOnMobile}`}>
            <div className={styles.tableCol}>
              <a href="/Solution/Marketing">Find out more about Marketing</a>
            </div>
            <div className={styles.tableCol}>NPS Scale, Rating</div>
            <div className={styles.tableCol}>Gallery block, Steps</div>
            <div className={styles.tableCol}>
              Capture Text input, Multiple Select
            </div>
          </div> */}
          <div className={`${styles.table__header} ${styles.hideOnMobile}`}>
            Feature Highlight</div>

          <div className={`${styles.table} ${styles.hideOnMobile}`}>
            <div className={styles.tableCol}>Capture Emails, Voucher <br />
            <a href="/Solution/Marketing">Use it for Marketing</a>
</div>
            <div className={styles.tableCol}>NPS Scale, Rating <br />
            <a href="/Solution/Marketing">Surveys for Customer Teams</a>

            </div>
            <div className={styles.tableCol}>Gallery block, Steps
            <br />
            <a href="/Solution/Marketing">Surveys for Product Teams</a>
            </div>
            <div className={styles.tableCol}>
              Capture Text input, Multiple Select
              <br />
            <a href="/Solution/Marketing">Run events with Surveys</a>
            </div>
          </div>
          <div className={`${styles.table__header}`}>
            Use cases</div>
          <div className={`${styles.table}`}>
            <div className={styles.tableCol}>
              Run competitions, request quotes, Register Webinar Attendees
              Surveys
            </div>
            <div className={styles.tableCol}>
              Post webinar feedback survey, service feedback form, post purchase
              Surveys
            </div>
            <div className={styles.tableCol}>
              How to get started guide, Service Manual, Troubleshooting Surveys
            </div>
            <div className={styles.tableCol}>
              PERMA Survey, Weekly feedback, Anonymous Survey, Sprint Retro and
              1–1 Surveys
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.screenWidePanel}`}>
        <div>
          <div
            className={styles.screenWidePanel_content__small}
            style={{
              opacity: (scrollY - browserHeight * 4) * 0.005,
              transform: `translateY(-${scrollY * 0.02}px)`,
            }}
          >
            <h2>Our Freemium Doesn't Suck</h2>
            <p>
              A lot of free online survey form services these days are too
              limited. You're pushed to upgrade to paid plans to access even
              basic features. At Pepperform, we provide you with access to a
              free, unlimited version that includes all the meaningful features.
            </p>
          </div>
          <ul className={styles.freemiumDetails}>
            <li>
              <img src={formIcon} />
              <p>
                <strong>5 Free Forms</strong>
                Create 5 forms for free, no questions asked.
              </p>
            </li>
            <li>
              <img src={unlimited} />
              <p>
                <strong>Unlimited Responses</strong>
                Collect as many responses as you need
              </p>
            </li>
            <li>
              <img src={notification} />
              <p>
                <strong>Be notified</strong>
                Access the full reporting features
              </p>
            </li>
            <li>
              <img src={lifebuoy} />
              <p>
                <strong>Be supported</strong>
                Our support team is here to help
              </p>
            </li>
          </ul>
          <a href="https://app.pepperform.net/register" className={styles.primaryCta}>Sign up FREE</a>
          <span className={styles.primaryCta_helper}>
            No credit card required
          </span>
        </div>
      </div>
      <div
        ref={contentContainerRef}
        style={
          isPanelDocked
            ? {
                bottom: `${browserHeight * 3}px`,
                transform: `translateY(${browserHeight * 3}px)`,
              }
            : {}
        }
        className={`${styles.screenWidePanel} ${styles.white} ${
          isPanelDocked && styles.dockedPanel
        }`}
      >
        <div className={styles.screenWidePanel_content__twocol}>
        <div className={styles.screenWidePanel_content__twocol_image}>
        {scrollY < browserHeight * 6 && (
          <img
            src={hero_desktop_1}
            alt="A person viewing their Pepperform Survey on a Desktop"
            style={{
              opacity: scrollY >= browserHeight * 5.9 ? 1 - (scrollY - browserHeight * 5.9) / (browserHeight * 0.1) : 1,
              transform: `scale(${scrollY >= browserHeight * 5.9 ? 1 - (scrollY - browserHeight * 5.9) / (browserHeight * 0.1) : 1.0})`,

            }}
          />
        )} 
{scrollY > browserHeight * 5.9 && (
  <img
    src={classic}
    style={{
      opacity: scrollY >= browserHeight * 5.9 ? 0 + (scrollY - browserHeight * 5.9) / (browserHeight * 0.1) : 1,
      transform: `scale(${Math.min(1, scrollY >= browserHeight * 5.9 ? 0 + (scrollY - browserHeight * 5.9) / (browserHeight * 0.1) : 1)})`,
    }}
  />
)}
    
        {scrollY >= browserHeight * 5.9 && scrollY < browserHeight * 7 && (
          <div
            style={{
              width: "100%",
              height: `${
                scrollY >= browserHeight * 5.9
                  ? Math.min(100, Math.max(0, scrollY - browserHeight * 5.9))
                  : 0
              }vh`,
            }}
            className={styles.content2}
          >
            {/* Your content here */}
          </div>
        )}
      </div>

          <div className={styles.screenWidePanel_content__twocol_text}>
            <h2>
            {scrollY >= browserHeight * 5.9 && scrollY < browserHeight * 7 ? 'Or All Questions At Once' : 'Show One Question At a Time' }
              {/* {scrollY} and browser: {scrollY/browserHeight} */}
            </h2>
            {/* <div> */}
            <div>
              {contentArray.map((content, index) => (
                <p
                  key={index}
                  style={{
                    // opacity: Math.max(
                    //   0,
                    //   1 - (scrollY - (browserHeight * (index+5))) * 0.00015
                    // ),
                    // transform: `translateY(${Math.min(
                    //   0,
                    //   (scrollY - browserHeight * (index+5)) * 0.1
                    // )}px)`,
                    display:
                      scrollY < (browserHeight * 6) && index === 1
                        ? "none"
                        : scrollY >= (browserHeight * 6) && index === 1
                        ? "block"
                        : scrollY > (browserHeight * 6) && index === 0  
                        ? "none"
                        : scrollY < (browserHeight * 6) && index === 0  
                        ? "block" 
                        : "block"
                  }}
                  className={`${styles.content} ${
                    activeContentShowcase === index ? styles.active : ""
                  }`}
                >
                  {content}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <MainFooter />

    </div>
  );
}

export default NewHomepage;
