import { React, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import CustomLink from './CustomLink';
import tile from '../css/TemplateTile.module.scss';


function TemplateThumbnailTile(props) {

  const [isOpen, clickThumbnail] = useState(false);
  const template = props.template;

  return (

    <div className={`${tile.thumbnail + " "}  ${props.context === "nav" ? tile.tiny_thumbnail : ''} ${isOpen === true ? tile.active_thumbnail : ''}`} >
      <OutsideClickHandler
        onOutsideClick={() => {
          clickThumbnail(false)
        }}>
        <CustomLink link={'https://app.pepperform.net/template/' + template.shorthand}>
          <div className={tile.image_thumbnail} onMouseEnter={() => { clickThumbnail(!isOpen) }} onMouseLeave={() => { clickThumbnail(!isOpen) }}>
            <img src={template.previewImage} alt="Template title" />
          </div>
          <div className={tile.tile_description}>
            <h2>{template.name}</h2>

            <div className={tile.link_description}>
              <p>View Survey template</p>
              {/* <span className={tile.link_button}><ChevronRightOutlinedIcon /></span> */}
            </div>

          </div>
        </CustomLink>
      </OutsideClickHandler>
    </div>

  );
}

export default TemplateThumbnailTile;
