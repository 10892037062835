// import React, { useState, useEffect } from 'react';

// import Fade from 'react-reveal/Fade';

// import MainHeader from './MainHeader'
// import { Helmet } from 'react-helmet';

// import ArticleBrowser from './Components/ArticleBrowsers'
// import FAQ from './Components/FAQ'
// import TemplatesCarousel from './Components/TemplatesCarousel'
// import MainFooter from './MainFooter'
// import TwoBlockSection from './DumbComponents/TwoBlockSection'



// import mainNav from './css/MainNav.module.scss'
// import layout from './css/Layout.module.scss'
// import plaintext from './css/PlainTextBlock.module.scss'
// import twocolblock from './css/TwoColBlock.module.scss'
// import featurehighlight from './css/FeatureHighlight.module.scss'


// import feature_highlight from './images/marketing.jpg'
// import dragDropImage from './images/drag.gif'
// import productmanager from './images/product.jpg'



// const articlefeatures = [
//     {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
//     // {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
//     {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
//     {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
//     {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
//     {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
//     // {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
//     // {title:"A Super Guide to Conducting Team Health Checks for High-Performing Agile Teams", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/a-super-guide-to-conducting-team-health-checks-for-high-performing-agile-teams" }] },  
// ]

// const faqlist = [
//   { title: 'What is the benefit of an online survey builder?', description: "Online surveys are a game-changer for those who want to do their own research. They're a time and money saver, delivering speedy results. You don't need developers or tech skills to get your survey up and ready to share. With just a few clicks, you can create engaging surveys that perfectly fit your needs." },
//   { title: 'Why use Pepperform?', description: "Pepperform is the go-to online form builder for creating engaging, and visually captivating forms. Whether you're creating interactive surveys, contact lists, shopping carts, or quizzes, Pepperform offers a versatile platform with powerful design tools and data management tools." },
//   { title: 'How do I create and send an online customer survey?', description: "Creating and sending an online customer survey with Pepperform is a simple and efficient process. Start by signing in to your account or creating a new one if you don't have an existing account. Once logged in, click on the Create Form button to begin building your survey. You have the option to start from scratch or use a pre-designed template. Customize your survey by adding various types of questions to gather the desired information. Personalize the survey's appearance by customizing the branding, including your logos and colors. Once your survey is ready, click on the Share button to start collecting responses. You can easily track and monitor the survey results in real-time through your reporting dashboard." },
//   { title: 'How much does Pepperform cost?', description: "Our survey maker has got everything you need to gather customer feedback, conduct market research, or run a quiz. Don't wait any longer to get the insights you need to take your business to the next level." },
//   { title: 'Is there a limit to the number of responses I can collect with my survey form?', description: "Our survey maker has got everything you need to gather customer feedback, conduct market research, or run a quiz. Don't wait any longer to get the insights you need to take your business to the next level." },
// ]
// const benefits = [
//     { title: 'No additional resoure', description: "Online surveys are a game-changer for those who want to do their own research. They're a time and money saver, delivering speedy results. You don't need developers or tech skills to get your survey up and ready to share. With just a few clicks, you can create engaging surveys that perfectly fit your needs." },
//     { title: 'Dozens of question types', description: "Pepperform is the go-to online form builder for creating engaging, and visually captivating forms. Whether you're creating interactive surveys, contact lists, shopping carts, or quizzes, Pepperform offers a versatile platform with powerful design tools and data management tools." },
//     { title: 'Own your data', description: "Creating and sending an online customer survey with Pepperform is a simple and efficient process. Start by signing in to your account or creating a new one if you don't have an existing account. Once logged in, click on the Create Form button to begin building your survey. You have the option to start from scratch or use a pre-designed template. Customize your survey by adding various types of questions to gather the desired information. Personalize the survey's appearance by customizing the branding, including your logos and colors. Once your survey is ready, click on the Share button to start collecting responses. You can easily track and monitor the survey results in real-time through your reporting dashboard." },
//   ]

// function SolutionMarketing() {

//   const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
//   useEffect(() => {
//     document.title = 'Enhance Marketing Strategies with Pepperform Survey';
//   }, []);

//   return (
//     <>
//     <Helmet>
//     <link rel="canonical" href="https://pepperform.net/solution/marketing" />
//     <meta name="keywords" content="marketing insights, survey solutions, market research, customer feedback, marketing strategies, data-driven decisions" />
//     <meta name="description" content="Enhance your marketing strategies with Pepperform's survey solutions. Gather valuable market insights, understand customer preferences, and make data-driven decisions to optimize your marketing efforts." />
//     <meta property="og:title" content="Enhance Marketing Strategies with Survey Solutions | Pepperform" />
//     <meta property="og:description" content="Enhance your marketing strategies with Pepperform's survey solutions. Gather valuable market insights, understand customer preferences, and make data-driven decisions to optimize your marketing efforts." />
//   </Helmet>
//     <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
//       <MainHeader page="features" setNav={setNav} state={state} />
//       <main className={layout.body_wrapper}>
//         <section className={plaintext.containerHero + " " + plaintext.hero}>
//           <h2>Marketing teams</h2>
//           <Fade up distance={"12px"}>
//             <h1>Create Survey Campaigns to Unlock Target Growth</h1>
//           </Fade>
//           <Fade delay={500}>
//             <p>As a marketer, you know that insights are the key to unlocking growth. With Pepperform, you can easily gather valuable feedback from your target audience through surveys and questionnaires. Discover customer pain points, preferences, and behaviors to optimize your marketing efforts and create campaigns that truly resonate. Whether you're looking to measure brand awareness, customer satisfaction, or get feedback on a new product or service, we got you covered.</p>
//           </Fade>
//         </section>

//         <section className={featurehighlight.wrapper} style={{ backgroundColor: "#789b7e" }}>
//         <div className={featurehighlight.container}>
//             <div className={featurehighlight.images}>
//               <img src={feature_highlight} alt="List of Features" loading="lazy" />
//             </div>
//             <header className={featurehighlight.header}>
//               <Fade up distance={"32px"}>
//                 <h1 style={{color:"#ffffff", marginBottom:"16px"}}>Unlocking Insights to target growth</h1>
//               </Fade>
//               <Fade up distance={"32px"} delay="250">
//               <p>
//               In today's marketing landscape, surveys have become an invaluable tool for marketers in their pursuit of achieving Objectives and Key Results (OKRs). Surveys provide a unique opportunity to gather valuable insights and make data-driven decisions, enabling marketers to refine their strategies and drive success.
//               </p>
//               {/* <a className='text-link' href="/he">10 powerful questions to ask users in discovery interviews</a> */}

//               </Fade>
//             </header>

//           </div>

//           <div className={featurehighlight.containerRight +" "+ featurehighlight.container}>

//             <header className={featurehighlight.header}>
//               <Fade down><h1 style={{color:"#ffffff", marginBottom:"16px"}}>We help marketing teams to define strategy and planning</h1></Fade>
//               <Fade left distance="32px">
//                 <p>Surveys play a crucial role in understanding our customers' needs, preferences, and pain points. By gathering valuable feedback through surveys, we gain deep insights into what makes our product good and identify areas for improvement. This valuable information enables us to make data-driven decisions, prioritize features, and enhance the user experience. Surveys empower us to listen to our customers, iterate rapidly, and deliver a solution that exceeds their expectations. Together, with the power of surveys, we can elevate our product to new heights of greatness!</p>
//               </Fade>
//             </header>

//           </div>

//         </section>

//         <TwoBlockSection source={benefits} alignment="left" staticimage={dragDropImage} backgroundColor="#1D3334" type={'image'} displaysingleimage={true} removepaddingbottom={true}>
//           <h1>Create Professional Branded Forms in minutes</h1>
//         </TwoBlockSection>

//         <section className={twocolblock.container + " " + twocolblock.right_image + " " + twocolblock.removepaddingbottom} >
//           <Fade>
//           <div className={twocolblock.description_area}>
//             <h1 style={{marginBottom:"16px"}}>Foster Transparent Communication in Your Agile Team </h1>
//             <p>In the fast-paced world of Agile teams, operating at full capacity sprint after sprint, transparent communication is essential for success. That's where our surveys come in. By utilizing our survey tool, you can gather valuable feedback and insights from team members, promoting a culture of open communication and employee engagement.</p>
//             <p>Surveys play a crucial role in assessing team dynamics, identifying areas for improvement, and addressing any challenges or concerns that may arise within the team. Through the feedback collected, team leaders gain valuable insights into employee satisfaction, collaboration, and overall team health. This information empower you to understand your team better, address their needs, and foster continuous improvement.</p>
//           </div>
          
//           <div className={twocolblock.image_area} style={{ backgroundColor: '#a4c3c4' }}>
//             <div className={twocolblock.image +" "+twocolblock.image_full }>
//               <img src={productmanager} alt="Customer support " />
//             </div>

//           </div>
//           </Fade>
//         </section>
//         <ArticleBrowser articles={articlefeatures} />
//         <TemplatesCarousel title="marketing" />

//         <FAQ source={faqlist} />
//         <MainFooter />

//       </main>

//     </div>
//     </>
//   );
// }

// export default SolutionMarketing;


import React, { useState, useEffect } from 'react';

import Fade from 'react-reveal/Fade';

import MainHeader from './MainHeader'
import { Helmet } from 'react-helmet';

import reportImage from './images/ipadClay.png'

import ArticleBrowser from './Components/ArticleBrowsers'
import FAQ from './Components/FAQ'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'
import styles from "./css/NewHomepage.module.scss";
import unlimited from "./images/unlimited.svg";
import notification from "./images/notification.svg";
import lifebuoy from "./images/lifebuoy.svg";
import formIcon from "./images/contact-form.svg";

import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import plaintext from './css/PlainTextBlock.module.scss'
import twocolblock from './css/TwoColBlock.module.scss'
import featurehighlight from './css/FeatureHighlight.module.scss'


import drag from './images/fields.gif'
import feature_highlight from './images/mockuuups-1.jpg'
import mockupImage from './images/stefan.jpg'
import customerSupportImage from './images/hero_desktop_1.jpg'


const articlefeatures = [
  // {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
  // {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
  // {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
  {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
  {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
  {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
  {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
  {title:"Customer Success Stories: How Forms Can Capture and Share Success Stories", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/customer-success-stories-how-forms-can-capture-and-share-success-stories" }] },  
]


const faqlist = [
  {
    "title": "Can I create surveys for different stages of the customer journey, like pre-purchase and post-purchase?",
    "description": "Yes, Pepperform empowers marketing teams to craft surveys tailored to specific customer journey stages. You can create engaging pre-purchase surveys to understand customer needs and post-purchase surveys to gather feedback and measure satisfaction. This customization ensures your marketing strategies are data-driven and effective."
  },
  {
    "title": "How can I customize the appearance of my marketing surveys?",
    "description": "Pepperform offers extensive customization options, including branding with logos and colors. You can customize the look and feel of your marketing surveys to match your brand's theme and aesthetics. This level of customization ensures a seamless brand experience for your audience."
  },
  {
    "title": "Can I incorporate storytelling into my marketing surveys?",
    "description": "Absolutely! Pepperform allows marketing teams to go beyond simple questions and incorporate storytelling elements into surveys. Enhance your surveys with text, images, and videos to convey your brand's story effectively. This storytelling approach captivates your audience and reinforces your brand's message."
  },
  {
    "title": "How can Pepperform help me measure the success of my marketing campaigns?",
    "description": "Pepperform provides robust reporting and analytics tools. You can use these tools to measure the effectiveness of your marketing campaigns. Collect feedback and insights from your audience to understand what works and what needs improvement. Pepperform's data-driven approach helps optimize marketing strategies for better results."
  },
  {
    "title": "Is Pepperform suitable for marketing teams of all sizes?",
    "description": "Absolutely! Pepperform's flexibility makes it suitable for marketing teams of all sizes, from startups to established enterprises. Whether you're a solo marketer or part of a large marketing department, Pepperform can be tailored to meet your specific needs."
  }
];





function SolutionMarketing() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  const [scrollY, setScrollY] = useState(0);
  const [thumbnails, setThumbnails] = useState([]); // Define the thumbnails state variable
  const [browserHeight, setBrowserHeight] = useState(false); // Initialize with false for each word

  useEffect(() => {

    // Add a scroll event listener to update the scrollY state
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const abc = window.innerHeight;
      setBrowserHeight(abc);
    };

    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    document.title = 'Power up Your Marketing With Pepperform Survey';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <div className={featurehighlight.content_solution}>
           <Helmet>
     <link rel="canonical" href="https://pepperform.net/Solution/Marketing" />
     <meta name="keywords" content="marketing insights, survey solutions, market research, customer feedback, marketing strategies, data-driven decisions" />
     <meta name="description" content="Enhance your marketing strategies with Pepperform's survey solutions. Gather valuable market insights, understand customer preferences, and make data-driven decisions to optimize your marketing efforts." />
     <meta property="og:title" content="Enhance Marketing Strategies with Pepperform Survey" />
     <meta property="og:description" content="Enhance your marketing strategies with Pepperform's survey solutions. Gather valuable market insights, understand customer preferences, and make data-driven decisions to optimize your marketing efforts." />
   </Helmet>
        <MainHeader  scrolled={scrollY > 100 && true} page="solution" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        <section 
        style={{backgroundSize:'cover', backgroundPosition:'center',background:`url('https://images.unsplash.com/photo-1635002962487-2c1d4d2f63c2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80')`}}
        className={plaintext.containerHero + " " + plaintext.hero}>
          <div className={plaintext.containerHero__content}>
            <div>
              <h2>Marketing teams</h2>
              <h1>The Tool To Power up Engagement in Your Marketing</h1>
            </div>
            <div>
              {/* <h3>Effortless Feedback Capture with Customizable Contact Form Templates</h3> */}
              <p>Marketing isn't just about reaching your audience; it's about truly understanding and engaging with them. At Pepperform, we believe that every interaction is an opportunity to connect, learn, and grow. Our platform empowers marketers to craft meaningful surveys and forms that capture the essence of your brand.</p>
            </div>
          </div>
        </section>

        <section className={featurehighlight.wrapper}>
        {/* <div className={featurehighlight.container}>
            <div className={featurehighlight.images}>
              <img src={feature_highlight} alt="Feature highlight" loading="lazy" />
            </div>
            <header className={featurehighlight.header}>
              <Fade up distance={"32px"}>
                <h2>Give your customer a voice</h2>
              </Fade>
              <Fade up distance={"32px"} delay="250">
                <h3 style={{lineHeight:1.65}}>Unveiling the Power of Contact Forms: Direct Dialogue, Deeper Connection</h3>
              <p>Imagine your online product as a bustling marketplace of ideas, where visitors' thoughts can be heard through the medium of contact forms. This provides an avenue for individuals to engage with your brand on their own terms, without the pressure of immediate response.</p>
              <p>Our contact forms permit visitors to communicate with your brand based on their conditions and act as a platform for genuine connections. By bridging the gap between you and your customers, this open discussion uncovers preferences, issues, and objectives that can guide your items and strengthen your brand.
                
              </p>
              <p><a style={{color:"white"}} href="https://app.pepperform.net/templates">View this template</a> to see what you can create with our Survey Builder tool.</p>

              </Fade>
            </header>

          </div> */}

          <div className={featurehighlight.container} style={{paddingBottom:'0'}}>
          <Fade down><h2>Empower Your Marketing Team to Create Memorable Touchpoints with Pepperform</h2>
          <img className={featurehighlight.image_report} src={reportImage} alt="A Responsive Pepperfrom Survey displayed on a tablet mobile device" loading="lazy" />
          </Fade>

          </div>

          <header className={featurehighlight.feature_content}>
            <div>
            <Fade left distance="32px">
                <h3>Creative Content Marketing</h3>
                <p>People engage with content not brands. Pepperform enables you to create captivating content surveys. Seamlessly integrate text, images, and videos to convey your brand's story. Engage your audience on a deeper level, nurturing relationship.</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>Optimize Pre/Post-Purchase Funnels</h3>
                <p>Understand customer needs, preferences, and pain points at every stage of the buyer's journey. Tailor marketing efforts to resonate with potential customers, optimizing your pre-purchase funnels for success.

</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>Elevate with Your Brand</h3>
                <p>Pepperform's dynamic surveys help you engage customers at every touchpoint. Collect valuable feedback to uncover their needs and concerns. Use these insights to enhance your customer success strategies, resulting in improved retention rates.</p>
              </Fade>
            </div>
            </header>

            <div className={featurehighlight.fullImage}>
              <img src={mockupImage} alt="A Pepperform Survey being viewed from a Tablet Device" loading="lazy" />
            </div> 
            <div className={`${styles.screenWidePanel}`}>
        <div>
          <div
            className={styles.screenWidePanel_content__small}

          >
            <h2>Our Freemium Doesn't Suck</h2>
            <p>
              A lot of free online survey form services these days are too
              limited. You're pushed to upgrade to paid plans to access even
              basic features. At Pepperform, we provide you with access to a
              free, unlimited version that includes all the meaningful features.
            </p>
          </div>
          <ul className={styles.freemiumDetails}>
            <li>
              <img src={formIcon} />
              <p>
                <strong>5 Free Forms</strong>
                Create 5 forms for free, no questions asked.
              </p>
            </li>
            <li>
              <img src={unlimited} />
              <p>
                <strong>Unlimited Responses</strong>
                Collect as many responses as you need
              </p>
            </li>
            <li>
              <img src={notification} />
              <p>
                <strong>Be notified</strong>
                Access the full reporting features
              </p>
            </li>
            <li>
              <img src={lifebuoy} />
              <p>
                <strong>Be supported</strong>
                Our support team is here to help
              </p>
            </li>
          </ul>
          <a href="https://app.pepperform.net/register" className={styles.primaryCta}>Sign up FREE</a>
          <span className={styles.primaryCta_helper}>
            No credit card required
          </span>
        </div>
      </div>
        </section>
        <section className={twocolblock.container + " " + twocolblock.left_image}>

          <div className={twocolblock.image_area} style={{ backgroundColor: "#643a32" }}>
            <div className={twocolblock.image}>
              <img style={{margin:"20px"}} src={'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/race.jpg'} alt="A Pepperform survey template for a pledge against racism" />
            </div>
            <div className={twocolblock.image_description_container}>
              <div className={twocolblock.image_description}>
                <h2>24</h2>
                <p>Question types</p>
              </div>
              <div className={twocolblock.image_description}>
                <h2>Unlimited</h2>
                <p>Responses</p>
              </div>
            </div>
          </div>
          <div className={twocolblock.description_area}>
              <Fade left distance="32px">
                <h2>Start with Templates or Craft Unique Narratives</h2>
              </Fade>
              <Fade left distance="32px" delay="250">
                <p style={{paddingTop:"80px"}}>
                Kickstart your campaigns with our professionally designed templates, saving you time and effort. Alternatively, craft unique narratives that captivate your audience. Incorporate text, images, and videos seamlessly into your surveys to convey your brand's story effectively.
                <a href="https://app.pepperform.net/templates/">Browse our Survey Form Templates</a>
                </p>
              </Fade>
          </div>

        </section>
        {/* <ArticleBrowser articles={articlefeatures} /> */}
        {/* <TemplatesCarousel title="feedback" /> */}

        <section className={twocolblock.container + " " + twocolblock.right_image} >
          
          <div className={twocolblock.description_area}>
            <h2>Engage your audience, tell a story</h2>
            <p>Pepperform empowers you to go beyond the ordinary and transform your feedback forms into compelling narratives. With our platform, it's not just about gathering data; it's about telling your customer success story. Seamlessly integrate text, images, and videos into your forms to captivate your clients and effectively deliver your message.

            <a href="https://app.pepperform.net/templates">Browse our Survey Form Templates</a>

              .</p>
          </div>
          <div className={twocolblock.image_area} style={{ backgroundColor: '#643a32' }}>
            <div className={twocolblock.image +" "+twocolblock.image_full }>
              <img src={customerSupportImage} alt="A Pepperform displayed on a Desktop Device" loading='lazy' />
            </div>

          </div>

        </section>
        <FAQ source={faqlist} />
        <MainFooter />

      </main>
      </div>
    </div>
  );
}

export default SolutionMarketing;
