import React, { useState } from 'react';
import styles from './css/_GDPR.module.scss';

const GDPROverlay = () => {
  const [showOverlay, setShowOverlay] = useState(!localStorage.getItem('gdprConsent'));

  const handleAccept = () => {
    localStorage.setItem('gdprConsent', 'true');
    setShowOverlay(false);
  };

  const handleDismiss = () => {
    setShowOverlay(false);
  };

  if (!showOverlay) {
    return null; // If user has already given consent, do not show the overlay
  }

  return (
    <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', padding: '12px 24px', boxSizing:'border-box', zIndex: 9999 }}>
    <div className={styles.content}>
      <p>
        Pepperform uses cookies for analytics purposes. <a href="/privacy">Learn more</a>
      </p>
      <div>
        <button onClick={handleAccept} className={`${styles.accept}`} >Accept</button>
        <button onClick={handleDismiss} className={`${styles.dismiss}`} >Dismiss</button>
      </div>
    </div>
    </div>
  );
};

export default GDPROverlay;
