import React, { useState, useEffect } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'

import ArticleBrowser from './Components/ArticleBrowsers'
import FAQ from './Components/FAQ'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'

import CustomAccordion from './DumbComponents/CustomAccordion'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import plaintext from './css/PlainTextBlock.module.scss'
import twocolblock from './css/TwoColBlock.module.scss'
import featurehighlight from './css/FeatureHighlight.module.scss'


import homepage1 from './images/homepage-1.png'
import feature_highlight from './images/feature_highlight.png'


const customiseform = [
  { icon: 'leaf', title: 'Add your logo', description: 'Add a logo and make your survey look and feel consistent to the rest of your brand communication.' },
  { icon: 'easel', title: 'Customise brand colours', description: 'Customise brand Colour!!' },
  { icon: 'chromeReader', title: 'Classic Survey or a Wizard', description: 'Different kind of data captures' },
]

const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Product",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)",category: "Survey",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Other",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
]

const faqlist = [
  { title: 'What types of surveys can I create with Pepperform?', description: " Pepperform supports a wide range of surveys, including customer feedback, employee engagement, market research, event registration, and more. You can tailor each survey to your specific needs using various question types and customization options." },
  { title: 'How does Pepperform assist in survey creation?', description: "Pepperform provides an intuitive drag-and-drop interface that allows you to easily create surveys. Choose from various question types, add branching logic, and customize the design to match your brand. No technical skills required." },
  { title: 'Is Pepperform suitable for non-technical users?', description: "Absolutely. Pepperform is user-friendly and requires no coding or technical expertise. Its intuitive interface allows users of all skill levels to create and customize surveys effortlessly." },
]




function Features() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });

  useEffect(() => {
    document.title = 'Features for All Your Survey Needs – Customer Feedback Made Easy and Free with Pepperform';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader page="features" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        <section className={plaintext.container}>
          <h1>Turn your customers into brand champions</h1>
          <h2>Surveys that converts</h2>
          <p>Elevate your customer experience with Pepperform's professional branded surveys. Create and customize surveys to capture feedback at every stage of the customer journey. Start today and turn customers into lifelong advocates.</p>
          <div className={plaintext.cta}><CustomLink link={'https://app.pepperform.net/register'} buttonStyle={"cta"}>Get Started FREE</CustomLink></div>
        </section>
        <section className={featurehighlight.wrapper} style={{ backgroundColor: "#C1CBAC" }}>
          <div className={featurehighlight.container}>
            <header className={featurehighlight.header}>
              <h1>Transform touch points into conversion opportunities</h1>
              <p>With Pepperform, you can easily create customized quizzes and surveys that enhance the customer experience and turn interactions into meaningful opportunities for conversion. Start creating your surveys and quizzes today to unlock the power of customer engagement and drive results.</p>
            </header>
            <div className={featurehighlight.images}>
              <img src={feature_highlight} alt="Feature highlight" loading="lazy" />
            </div>
          </div>

        </section>
        <section className={twocolblock.container + " " + twocolblock.left_image}>
          <div className={twocolblock.image_area} style={{ backgroundColor: "#D2BFCD" }}>
            <div className={twocolblock.image}>
              <img src={homepage1} alt="Customise to your heart's content" />
            </div>
            <div className={twocolblock.image_description_container}>
              <div className={twocolblock.image_description}>
                <h2>24</h2>
                <p>Question types</p>
              </div>
              <div className={twocolblock.image_description}>
                <h2>Unlimited</h2>
                <p>Responses</p>
              </div>
            </div>
          </div>
          <div className={twocolblock.description_area}>
            <h1>Transform touch points into conversion opportunities</h1>
            <p>With Pepperform, you can easily create customized quizzes and surveys that enhance the customer experience and turn interactions into meaningful opportunities for conversion. Start creating your surveys and quizzes today to unlock the power of customer engagement and drive results.</p>
          </div>
        </section>
        <ArticleBrowser articles={articlefeatures} />
        <TemplatesCarousel title={'feedback'} />
        <section className={plaintext.container}>
          <h1>Design Support for Your Surveys</h1>
          <p>Whether you need help with creating custom logos, graphics, or other design elements for your surveys, our designers are ready to help you create professional-looking surveys that align with your brand. Get the design support you need to make your surveys visually appealing and engaging with Pepperform.</p>
        </section>
        <section className={twocolblock.container + " " + twocolblock.right_image} >
          <div className={twocolblock.description_area}>
            <h1>Engage and Delight your Customers</h1>
            <p>Our survey maker has got everything you need to gather customer feedback, conduct market research, or run a quiz. Don't wait any longer to get the insights you need to take your business to the next level.</p>
            <CustomAccordion source={customiseform} />
          </div>
          <div className={twocolblock.image_area} style={{ backgroundColor: '#E2D3C3' }}>
            <div className={twocolblock.image}>
              <img src={homepage1} alt="Customise to your heart's content" />
            </div>

          </div>

        </section>
        <FAQ source={faqlist} />
        <MainFooter />

      </main>

    </div>
  );
}

export default Features;
