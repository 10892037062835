// import React, { useState, useEffect } from 'react';

// import Fade from 'react-reveal/Fade';

// import MainHeader from './MainHeader'
// import { Helmet } from 'react-helmet';


// import ArticleBrowser from './Components/ArticleBrowsers'
// import FAQ from './Components/FAQ'
// import TemplatesCarousel from './Components/TemplatesCarousel'
// import MainFooter from './MainFooter'



// import mainNav from './css/MainNav.module.scss'
// import layout from './css/Layout.module.scss'
// import plaintext from './css/PlainTextBlock.module.scss'
// import twocolblock from './css/TwoColBlock.module.scss'
// import featurehighlight from './css/FeatureHighlight.module.scss'


// import homepage1 from './images/homepage-1.png'
// import feature_highlight from './images/prodteam.jpg'
// import mockupImage from './images/mockup.png'
// import productQuiz from './images/ProductQuiz.png'
// import productQuiz2 from './images/ProductQuiz2.jpg'
// import agileImage from './images/agile.jpg'
// import productmanager from './images/product.jpg'
// import TwoBlockSection from './DumbComponents/TwoBlockSection';


// const articlefeatures = [
//   // {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
//   {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
//   {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
//   // {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
//   {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
//   // {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
//   // {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
//   {title:"A Super Guide to Conducting Team Health Checks for High-Performing Agile Teams", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/a-super-guide-to-conducting-team-health-checks-for-high-performing-agile-teams" }] },  
// ]


// const faqlist = [
//   { title: 'What is the benefit of an online survey builder?', description: "Online surveys are a game-changer for those who want to do their own research. They're a time and money saver, delivering speedy results. You don't need developers or tech skills to get your survey up and ready to share. With just a few clicks, you can create engaging surveys that perfectly fit your needs." },
//   { title: 'Why use Pepperform?', description: "Pepperform is the go-to online form builder for creating engaging, and visually captivating forms. Whether you're creating interactive surveys, contact lists, shopping carts, or quizzes, Pepperform offers a versatile platform with powerful design tools and data management tools." },
//   { title: 'How do I create and send an online customer survey?', description: "Creating and sending an online customer survey with Pepperform is a simple and efficient process. Start by signing in to your account or creating a new one if you don't have an existing account. Once logged in, click on the Create Form button to begin building your survey. You have the option to start from scratch or use a pre-designed template. Customize your survey by adding various types of questions to gather the desired information. Personalize the survey's appearance by customizing the branding, including your logos and colors. Once your survey is ready, click on the Share button to start collecting responses. You can easily track and monitor the survey results in real-time through your reporting dashboard." },
//   { title: 'How much does Pepperform cost?', description: "Our survey maker has got everything you need to gather customer feedback, conduct market research, or run a quiz. Don't wait any longer to get the insights you need to take your business to the next level." },
//   { title: 'Is there a limit to the number of responses I can collect with my survey form?', description: "Our survey maker has got everything you need to gather customer feedback, conduct market research, or run a quiz. Don't wait any longer to get the insights you need to take your business to the next level." },
// ]


// function SolutionProduct() {

//   const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
//   useEffect(() => {
//     document.title = 'Unlock Customer Insights with Survey Solutions for Product Teams';
//   }, []);


//   return (
//     <><Helmet>
//     <link rel="canonical" href="https://pepperform.net/solution/product" />
//     <meta name="keywords" content="product insights, survey solutions, product development, market insights, customer feedback, product managers" />
//     <meta name="description" content="Unlock valuable product insights with Pepperform's survey solutions for product teams. Leverage our easy-to-use questionnaire builder to inform product development, ensure product-market fit, and answer critical business questions." />
//     <meta property="og:title" content="Unlock Product Insights with Survey Solutions for Product Teams | Pepperform" />
//     <meta property="og:description" content="Unlock valuable product insights with Pepperform's survey solutions for product teams. Leverage our easy-to-use questionnaire builder to inform product development, ensure product-market fit, and answer critical business questions." />
//   </Helmet>
  
//     <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
//       <MainHeader page="features" setNav={setNav} state={state} />
//       <main className={layout.body_wrapper}>
//         <section className={plaintext.containerHero + " " + plaintext.hero}>
//           <h2>Product teams</h2>
//           <Fade up distance={"12px"}>
//             <h1>Build better product with insights</h1>
//           </Fade>
//           <Fade delay={500}>
//             <p>Product managers use Pepperform's easy-to-use questionnaire builder to create surveys and gather valuable market and customer insights that inform product development, ensure product-market fit, and answer critical business questions.</p>
//           </Fade>
//         </section>

//         <section className={featurehighlight.wrapper} style={{ backgroundColor: "#9d5f5f" }}>
//         <div className={featurehighlight.container}>
//             <div className={featurehighlight.images}>
//               <img src={feature_highlight} alt="Feature highlight" loading="lazy" />
//             </div>
//             <header className={featurehighlight.header}>
//               <Fade up distance={"32px"}>
//                 <h2>Transform your users into co-creators</h2>
//               </Fade>
//               <Fade up distance={"32px"} delay="250">
//               <p>
//               Pepperform empowers businesses to turn their users into co-creators. By collecting feedback and insights from customers through surveys and questionnaires, businesses can understand what their users want and need, and develop products and services that meet those needs. 
//               </p>
//               </Fade>
//             </header>

//           </div>

//           <div className={featurehighlight.containerRight +" "+ featurehighlight.container}>

//             <header className={featurehighlight.header}>
//               <Fade down><h2>Surveys Helps Elevate Good Products to Greatness</h2></Fade>
//               <Fade left distance="32px">
//                 <h3>User testing</h3>
//                 <p>Surveys play a crucial role in understanding our customers' needs, preferences, and pain points. By gathering valuable feedback through surveys, we gain deep insights into what makes our product good and identify areas for improvement. This valuable information enables us to make data-driven decisions, prioritize features, and enhance the user experience. Surveys empower us to listen to our customers, iterate rapidly, and deliver a solution that exceeds their expectations. Together, with the power of surveys, we can elevate our product to new heights of greatness!</p>
//               </Fade>
//               <Fade left distance="32px" delay="250">
//                 <h3>Unlock innovation</h3>
//                 <p> Surveys are like the secret spice that fuels innovation in the product world. They give us direct access to the minds of our users, helping us understand their needs and uncover hidden gems of insights. With surveys, we can gather feedback on our products, features, and user experiences, allowing us to fine-tune and iterate on our offerings. It's like having a direct line to our users, giving them a chance to shape the future of our tech innovations. So, if you want to unlock innovation and create amazing tech solutions, surveys are the key to success!</p>
//                 {/* <a href="/" title='10 powerful questions'>10 powerful questions to ask users in discovery interviews</a> */}
//               </Fade>
//             </header>

//           </div>
//             <div className={featurehighlight.fullImage}>
//               <img src={productQuiz} alt="Feature highlight" loading="lazy" />
//             </div> 

//         </section>
//         <TwoBlockSection alignment="left" backgroundColor="#3E1B1B" staticimage={productQuiz2} type={"image"} displaysingleimage={true}>
//         <Fade left distance="32px">
//                 <h2>Get deep insights with user feedback surveys</h2>
//               </Fade>
//               <Fade left distance="32px" delay="250">
//                 <p>
//                   With our versatile survey builder, you have the freedom to create engaging and impactful surveys for a wide range of content. Whether you're looking to gather customer feedback, conduct market research, or measure employee satisfaction, our builder empowers you to design surveys that deliver valuable insights. 
//                 </p>
//               </Fade>
//               <Fade left distance="32px" delay="500">
//               <p> 
//                 The flexibility of our survey builder ensures that you can adapt to changing needs and create surveys that resonate with your audience. 
//               </p>
//             </Fade>
//         </TwoBlockSection>

//         <TwoBlockSection alignment="right" backgroundColor="#3E1B1B" staticimage={agileImage} type={"image"} displaysingleimage={true}>
//         <Fade left distance="32px">
//                 <h2>Surveys to Foster Continous Improvement in Your Agile Team</h2>
//               </Fade>
//               <Fade left distance="32px" delay="250">
//                 <p>
//                 In the fast-paced world of Agile teams, operating at full capacity sprint after sprint, transparent communication is essential for success. That's where our surveys come in. By utilizing our survey tool, you can gather valuable feedback and insights from team members, promoting a culture of open communication and employee engagement.
//                 </p>
//               </Fade>
//               <Fade left distance="32px" delay="500">
//               <p> 
//               <p>Through the feedback collected, team leaders gain valuable insights into employee satisfaction, collaboration, and overall team health. This information empower you to understand your team better, address their needs, and foster continuous improvement.</p>
//               </p>
//             </Fade>
//         </TwoBlockSection>

//         <ArticleBrowser articles={articlefeatures} />
//         <TemplatesCarousel title="product" />

//         <FAQ source={faqlist} />
//         <MainFooter />

//       </main>

//     </div>
//     </>
//   );
// }

// export default SolutionProduct;


import React, { useState, useEffect } from 'react';

import Fade from 'react-reveal/Fade';

import MainHeader from './MainHeader'
import { Helmet } from 'react-helmet';

import reportImage from './images/ipadClay.png'

import ArticleBrowser from './Components/ArticleBrowsers'
import FAQ from './Components/FAQ'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'
import styles from "./css/NewHomepage.module.scss";
import unlimited from "./images/unlimited.svg";
import notification from "./images/notification.svg";
import lifebuoy from "./images/lifebuoy.svg";
import formIcon from "./images/contact-form.svg";

import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import plaintext from './css/PlainTextBlock.module.scss'
import twocolblock from './css/TwoColBlock.module.scss'
import featurehighlight from './css/FeatureHighlight.module.scss'


import drag from './images/fields.gif'
import bgprod from './images/bgprod.jpg'
import mockupImage from './images/stefan.jpg'
import customerSupportImage from './images/hero_desktop_1.jpg'


const articlefeatures = [
  // {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
  // {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
  // {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
  {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
  {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
  {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
  {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
  {title:"Customer Success Stories: How Forms Can Capture and Share Success Stories", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/customer-success-stories-how-forms-can-capture-and-share-success-stories" }] },  
]


const faqlist = [
  {
    "title": "How can Pepperform enhance our product development process?",
    "description": "Pepperform simplifies gathering feedback from your users, helping you understand their needs and preferences. This valuable data enables you to tailor your product development for maximum impact."
  },
  {
    "title": "Can I use Pepperform for real-time user engagement?",
    "description": "Absolutely! Pepperform allows you to engage your users in real-time with surveys and feedback forms. Stay connected and gather instant insights to optimize your product development efforts."
  },
  {
    "title": "What happens after I collect feedback from our users?",
    "description": "Pepperform's post-feedback surveys are essential. They help you measure user satisfaction, collect insights, and identify areas for improvement. This data ensures you can continually enhance your product development strategies."
  },
  {
    "title": "Can I customize the appearance of our feedback forms?",
    "description": "Yes, Pepperform offers extensive customization options. You can brand your feedback forms with logos and colors to align with your product branding."
  },
  {
    "title": "How can Pepperform help us optimize our product development?",
    "description": "Pepperform empowers you to gather user insights at every stage of their journey. With data from various feedback forms, you can fine-tune your product development for better results."
  },
  {
    "title": "Is Pepperform suitable for product teams of all sizes?",
    "description": "Absolutely! Pepperform is adaptable and versatile, making it suitable for product teams of all sizes, from startups to large enterprises."
  }
];



function SolutionProduct() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  const [scrollY, setScrollY] = useState(0);
  const [thumbnails, setThumbnails] = useState([]); // Define the thumbnails state variable
  const [browserHeight, setBrowserHeight] = useState(false); // Initialize with false for each word

  useEffect(() => {

    // Add a scroll event listener to update the scrollY state
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const abc = window.innerHeight;
      setBrowserHeight(abc);
    };

    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    document.title = 'Unlock Product Insights with Pepperform Surveys';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <div className={featurehighlight.content_solution}>
      <Helmet>
       <link rel="canonical" href="https://pepperform.net/Solution/Product" />
       <meta name="keywords" content="product insights, survey solutions, product development, market insights, customer feedback, product managers" />
       <meta name="description" content="Unlock valuable product insights with Pepperform's survey solutions for product teams. Leverage our easy-to-use questionnaire builder to inform product development, ensure product-market fit, and answer critical business questions." />
       <meta property="og:title" content="Unlock Product Insights with Pepperform Surveys" />
       <meta property="og:description" content="Unlock valuable product insights with Pepperform's survey solutions for product teams. Leverage our easy-to-use questionnaire builder to inform product development, ensure product-market fit, and answer critical business questions." />
     </Helmet>
        <MainHeader  scrolled={scrollY > 100 && true} page="solution" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        <section 
        style={{backgroundSize:'cover', backgroundPosition:'center',background:`url('${bgprod}')`}}
        className={plaintext.containerHero + " " + plaintext.hero}>
          <div className={plaintext.containerHero__content}>
            <div>
              <h2>Product teams</h2>
              <h1>Turn Your Customers into Co-creators</h1>
            </div>
            <div>
              {/* <h3>Effortless Feedback Capture with Customizable Contact Form Templates</h3> */}
              <p>Surveys is the crucial tool in scaling your design thinking. Use Pepperform to understand your customers' needs, preferences, and pain points. The feedback you collect will uncover insights on what makes our product good and identify areas for improvement.</p>
            </div>
          </div>
        </section>

        <section className={featurehighlight.wrapper}>
        {/* <div className={featurehighlight.container}>
            <div className={featurehighlight.images}>
              <img src={feature_highlight} alt="Feature highlight" loading="lazy" />
            </div>
            <header className={featurehighlight.header}>
              <Fade up distance={"32px"}>
                <h2>Give your customer a voice</h2>
              </Fade>
              <Fade up distance={"32px"} delay="250">
                <h3 style={{lineHeight:1.65}}>Unveiling the Power of Contact Forms: Direct Dialogue, Deeper Connection</h3>
              <p>Imagine your online product as a bustling marketplace of ideas, where visitors' thoughts can be heard through the medium of contact forms. This provides an avenue for individuals to engage with your brand on their own terms, without the pressure of immediate response.</p>
              <p>Our contact forms permit visitors to communicate with your brand based on their conditions and act as a platform for genuine connections. By bridging the gap between you and your customers, this open discussion uncovers preferences, issues, and objectives that can guide your items and strengthen your brand.
                
              </p>
              <p><a style={{color:"white"}} href="https://app.pepperform.net/templates">View this template</a> to see what you can create with our Survey Builder tool.</p>

              </Fade>
            </header>

          </div> */}

          <div className={featurehighlight.container} style={{paddingBottom:'0'}}>
          <Fade down><h2>Create Online Surveys to Scale Your Customer Research</h2>
          <img className={featurehighlight.image_report} src={reportImage} alt="Pepperform Responsive Survey on a Tablet Device" loading="lazy" />
          </Fade>

          </div>

          <header className={featurehighlight.feature_content}>
            <div>
            <Fade left distance="32px">
                <h3>Understand Your User's Worldview</h3>
                <p> Through surveys and feedback forms, you can uncover pain points, desires, and motivations of your users. Apply design thinking principles and create solutions that solve real problems.</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>Surveys to level up onboarding and adoption</h3>
                <p>Walk users through product features, benefits, and best practices using visual elements. Whether it's a step-by-step guide or an interactive tutorial, Pepperform allows you to visually convey complex information with ease.</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>Receive Instant Alerts for New Responses</h3>
                <p>Pepperform ensures that your product team stays updated on customer feedback in real-time. With our notification system, you'll receive instant alerts every day new responses come in.</p>
              </Fade>
            </div>
            </header>

            <div className={featurehighlight.fullImage}>
              <img src={mockupImage} alt="Someone viewing their Pepperform Survey on a Tablet Device" loading="lazy" />
            </div> 
            <div className={`${styles.screenWidePanel}`}>
        <div>
          <div
            className={styles.screenWidePanel_content__small}

          >
            <h2>Our Freemium Doesn't Suck</h2>
            <p>
              A lot of free online survey form services these days are too
              limited. You're pushed to upgrade to paid plans to access even
              basic features. At Pepperform, we provide you with access to a
              free, unlimited version that includes all the meaningful features.
            </p>
          </div>
          <ul className={styles.freemiumDetails}>
            <li>
              <img src={formIcon} />
              <p>
                <strong>5 Free Forms</strong>
                Create 5 forms for free, no questions asked.
              </p>
            </li>
            <li>
              <img src={unlimited} />
              <p>
                <strong>Unlimited Responses</strong>
                Collect as many responses as you need
              </p>
            </li>
            <li>
              <img src={notification} />
              <p>
                <strong>Be notified</strong>
                Access the full reporting features
              </p>
            </li>
            <li>
              <img src={lifebuoy} />
              <p>
                <strong>Be supported</strong>
                Our support team is here to help
              </p>
            </li>
          </ul>
          <a href="https://app.pepperform.net/register" className={styles.primaryCta}>Sign up FREE</a>
          <span className={styles.primaryCta_helper}>
            No credit card required
          </span>
        </div>
      </div>
        </section>
        <section className={twocolblock.container + " " + twocolblock.left_image}>

          <div className={twocolblock.image_area} style={{ backgroundColor: "#2a120d" }}>
            <div className={twocolblock.image}>
              <img style={{margin:"20px"}} src={'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/templates/product_feedback.jpg'} alt="A Survey template for Product Managers to get feedbacks on a newly released feature" />
            </div>
            <div className={twocolblock.image_description_container}>
              <div className={twocolblock.image_description}>
                <h2>24</h2>
                <p>Question types</p>
              </div>
              <div className={twocolblock.image_description}>
                <h2>Unlimited</h2>
                <p>Responses</p>
              </div>
            </div>
          </div>
          <div className={twocolblock.description_area}>
              <Fade left distance="32px">
                <h2>Start with Templates or Craft Unique Narratives</h2>
              </Fade>
              <Fade left distance="32px" delay="250">
                <p style={{paddingTop:"80px"}}>
                Kickstart your campaigns with our professionally designed templates, saving you time and effort. Alternatively, craft unique narratives that captivate your audience. Incorporate text, images, and videos seamlessly into your surveys to convey your brand's story effectively.
                <a href="https://app.pepperform.net/templates/">Browse our Survey Form Templates</a>
                </p>
              </Fade>
          </div>

        </section>
        {/* <ArticleBrowser articles={articlefeatures} /> */}
        {/* <TemplatesCarousel title="feedback" /> */}

        <section className={twocolblock.container + " " + twocolblock.right_image} >
          
          <div className={twocolblock.description_area}>
            <h2>Engage your audience, tell a story</h2>
            <p>Pepperform empowers you to go beyond the ordinary and transform your feedback forms into compelling narratives. With our platform, it's not just about gathering data; it's about telling your customer success story. Seamlessly integrate text, images, and videos into your forms to captivate your clients and effectively deliver your message.

            <a href="https://app.pepperform.net/templates">Browse our Survey Form Templates</a>

              .</p>
          </div>
          <div className={twocolblock.image_area} style={{ backgroundColor: '#643a32' }}>
            <div className={twocolblock.image +" "+twocolblock.image_full }>
              <img src={customerSupportImage} alt="Pepperform Survey on a desktop device" loading='lazy' />
            </div>

          </div>

        </section>
        <FAQ source={faqlist} />
        <MainFooter />

      </main>
      </div>
    </div>
  );
}

export default SolutionProduct;
