import React, {useState} from 'react';


import styles from '../css/MainNav.module.scss';


function NavigationTileMobile(props) {

  const [isOpen, clickSubList] = useState(false );
  return (
    <a className={styles.navTile_mobile} href={props.link}>
    <header onMouseMove={()=>clickSubList(!isOpen)}>
        <h2>{props.title}</h2>
      </header>
      <p>{props.description}</p>
    </a>

  );
}

export default NavigationTileMobile;
