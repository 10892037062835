import React, {useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { Link } from "react-router-dom";
import articlebrowser from '../css/ArticleBrowser.module.scss'


import CustomLink from './CustomLink'


function ArticleBrowserItem(props) {
  const [isOpen, clickArticle] = useState(false);
  const article = props.article;
  const context = props.context;

  return (
      <React.Fragment>
        <OutsideClickHandler
        onOutsideClick={() => {
          clickArticle(false)
        }}>
        {/* <Link link={articlebrowser.item_links[0].link}> */}
        <div className={context === "blog-post" ? articlebrowser.item_no_height : articlebrowser.item } onClick={()=> clickArticle(!isOpen)}>
          <header className={articlebrowser.item_header}>

            <h2>{article.title}</h2>
            
            {isOpen && <p>{article.description}</p>}
            
          </header>
          {article.description && 
                    <ul className={articlebrowser.item_links + " " + articlebrowser.item_inactive}>
                    <li className={articlebrowser.item_link}>Read more</li>
                    {/* <span className={articlebrowser.arrow}><ChevronRightOutlinedIcon /></span> */}
                </ul>
          }
          <ul className={articlebrowser.item_links}>
            {article.links.map((link,index)=>{
              return <li key={index}><CustomLink link={link.link}>{link.title}</CustomLink></li>
            })}

          </ul>
          {article.image && 
            <div className={articlebrowser.tileimage} style={{ background: `url(${article.image})`, backgroundSize: 'cover' }}>
           </div>
          }
          </div>
        {/* </Link> */}
        </OutsideClickHandler>
      </React.Fragment>

  );
}

export default ArticleBrowserItem;
