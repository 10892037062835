import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import twocolblock from '../css/Accordion.module.scss'
import AccordionIcons from './AccordionIcons'


function CustomAccordion(props) {
    const customiseFormID = props.customiseFormID

    return (
        <React.Fragment>
            {/* {console.log({count})} */}
            <ul className={twocolblock.accordion_custom}>
                <Accordion preExpanded={[customiseFormID]} onChange={(item)=> props.setCustomiseForm(parseInt(item))}>
                    {props.source.map((item, index) => {
                        return <li key={index} >
                            <AccordionItem uuid={index} >
                                <AccordionItemHeading aria-level={3}>
                                    <AccordionItemButton>
                                        <h4>
                                            { item.icon ? <span className={twocolblock.accordion_icon}><AccordionIcons icon={item.icon}/></span> :''}
                                            <span>{item.title}</span>
                                        </h4>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        {item.description}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </li>
                    })}


                </Accordion>

            </ul>
        </React.Fragment>

    );
}

export default CustomAccordion;
