import React, { useState } from 'react';


import MainHeader from './MainHeader'
import CustomLink from './DumbComponents/CustomLink'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'


import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import blog from './css/Blog.module.scss'
import mainImage from './images/product.jpg'


const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Pre-Webinar Survey: Tell Us What You Want to Learn",category: "template",  description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "Pre-Webinar Survey: Tell Us What You Want to Learn", link: "https://pepperform.net/template/feedback/Pre-Webinar-Survey" }] },
]





function Article10() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
      <header className={blog.header}>
            <h1>
            Inclusivity Matters: 7 Survey Questions to Unlock Actionable Insights for Inclusive Design
            </h1>
            <p>
            <h3>What is Inclusive Design?</h3>
            Inclusive design is not just a buzzword; it's a mindset that strives to create experiences that cater to the needs of every individual, regardless of their abilities or backgrounds. When it comes to surveys, adopting an inclusive design approach is paramount to ensure that everyone can participate and provide valuable feedback.
            <h3>The Power of Customer Insights in Inclusive Design</h3>
            By asking and collecting insights from a diverse range of individuals, we can gain a deeper understanding of their unique perspectives and needs. This helps us identify potential barriers, uncover hidden opportunities, and make informed decisions that address the diverse needs of our audience. Inclusive surveys empower individuals to share their insights and experiences, allowing us to design products and services that are more inclusive, relevant, and meaningful to everyone.
            </p>

            <img src={mainImage} alt="Plant" loading="lazy" />

          </header>
      <main className={blog.wrapper}>
        <section className={blog.blog_post}>
        
          <article>
            <h2>1. How would you describe your level of familiarity with our product? {'('}e.g., beginner, intermediate, advanced{')'}</h2>
            <p>
            This question allows us to understand the diverse range of users and their varying levels of expertise
            </p>
            <h2>2. Are there any specific challenges you face when using our product?</h2>
            <p>
            By asking this question, we open the door for users to share their unique perspectives and shed light on potential barriers to inclusivity.
            </p>
            <h2>
            3. How would you rate the accessibility of our product?
                </h2>
            <p>
            Providing a rating scale allows users to express their experiences and perceptions regarding the accessibility of the product.
            </p>
            <h2>
            4. Have you encountered any difficulties in navigating our product's interface?
            </h2>
            <p>
            This question helps identify areas where the user interface may need improvement to enhance usability for all users.
            </p>
            <h2>5. What additional features or functionalities would you like to see implemented to make our product more inclusive?</h2>
            <p>By asking for specific suggestions, we gain insights into the specific needs and desires of our user base.</p>
            <h2>6. Do you feel represented and included in our product's visuals and content?</h2>
            <p>This question addresses the importance of diversity and representation in design, ensuring that all users feel seen and acknowledged.</p>
            <h2>7. How can we improve our product's documentation and support resources to better serve your needs? </h2>
            <p>Gathering feedback on documentation and support resources helps us identify areas for improvement and ensure that users can easily access the information they need.</p>

            <h2>Conclusion</h2>
            <p>By asking these seven survey questions, you'll unlock valuable insights that will guide your inclusive design efforts. Remember, inclusivity isn't just a buzzword—it's a mindset that drives innovation, empathy, and a commitment to creating products that truly meet the needs of all users. Together, let's design a more inclusive future.




</p>
          </article>
          
        </section>
        <section className={blog.reco} >
          <div className={blog.quote}>
            Successful onboarding is not just about acquiring customers but also about nurturing lasting relationships built on trust, understanding, and shared goals.
          </div>
              {/* {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })} */}

        </section>
      </main>
      {/* <TemplatesCarousel /> */}
      <MainFooter/>
      </div>

    </div>
  );
}

export default Article10