import React, { useState, useEffect } from 'react';

import MainHeader from './MainHeader';
import CustomLink from './DumbComponents/CustomLink';
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem';
import TemplatesCarousel from './Components/TemplatesCarousel';
import MainFooter from './MainFooter';
import { Helmet } from 'react-helmet';

import mainNav from './css/MainNav.module.scss';
import layout from './css/Layout.module.scss';
import blog from './css/Blog.module.scss';
import imageMockup from './images/mockuuups-1.jpg';

const articlefeatures = [
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Contact us", category: "Contact us", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Other survey", category: "Marketing", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Product", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Survey", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
  { title: "Net Promoter Score (NPS)", category: "Other", description: "NPS surveys are crucial for understanding customer satisfaction and loyalty.", links: [{ title: "NPS Survey Template", link: "nps-survey-template" }, { title: "20 Powerful NPS Questions to ask your customers", link: "20-powerfull-template" }] },
];

function Article16() {
  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });

  useEffect(() => {
    document.title = '15 of the Funniest Overheard Quotes During Our Product Launch D-Days';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
            <Helmet>
        <link rel="canonical" href="https://pepperform.net/15-funny-product-launch-quotes" />
        <meta
          name="description"
          content="Product launches for us are always an intense and high-stakes affair. But, amid the hustle and bustle, we've overheard and captured some memorable and downright hilarious comments overheard from our team"
        />
        <meta
          property="og:title"
          content="15 of the Funniest Overheard Quotes During Our Product Launch D-Days"
        />
        <meta
          property="og:description"
          content="Product launches for us are always an intense and high-stakes affair. But, amid the hustle and bustle, we've overheard and captured some memorable and downright hilarious comments overheard from our team"
        />
      </Helmet>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper }>
        <header className={blog.header}>
          <h1>15 of the Funniest Overheard Quotes During Our Product Launch D-Days</h1>
          <p>Product launches for us are always an intense and high-stakes affair. But, amid the hustle and bustle, we've overheard and captured some memorable and downright hilarious comments overheard from our team. From accidental slip-ups to witty observations, these quotes capture the essence of the rollercoaster ride that is a product launch. Enjoy!</p>
          <img src={'https://cdn-images-1.medium.com/max/1600/0*9aMrizF1MHne_0vR'} alt="Teams hanging out on Zoom" loading="lazy" />
        </header>
        <main className={blog.wrapper}>
          <section className={blog.blog_post}>

            <article className={blog.picture}>
              <h1>From the Engineering Corner</h1>
              <img style={{width:"100%"}} src={'https://cdn-images-1.medium.com/max/1600/1*-fVPg0Gt4Aqosp5HMfCQPg.png'} alt="Bird with a question mark next to a funny error message" loading="lazy" />

              <p>1. "I've seen error messages with more compassion."</p>
              <p>Sometimes there are just some less-than-friendly error messages that appear at the worst times.</p>
              <p>2. "If this deploys smoothly, I'll believe in magic."</p>
              <p>3. "Just imagine the post-launch celebration… if we survive."</p>
              <p>4. "Wait, I need to check if the office plant is still alive."</p>
              <p>5. "I think our server is as stressed as you are right now"</p>
            </article>

            <article>
              <h1>From the Product and Design Corner</h1>
              <img style={{width:"100%"}} src={'https://cdn-images-1.medium.com/max/1600/1*jqZ3HepJkMDJAQvgl7vHGw.png'} alt="Hamster holding a donut" loading="lazy" />

              <p>6. "Is this icon always like this or am I seeing things?"</p>
              <p>The launch-induced hallucinations of a designer.</p>
              <p>7. "If I am a magician, my wand is a malfunctioning mouse."</p>
              <p>8. "Why did we schedule this launch during Mercury's retrograde?"</p>
              <p>9. "I think, I'm on my fourth donut today"</p>
              <p>10. "Did anyone order pizza for the next three days?"</p>
              <p>11. "I think this is the wrong shade of blue. BUT, it can be the right shade at least for launch day."</p>
            </article>

            <article>
              <h1>From the Marketing Corner</h1>
              <img style={{width:"100%"}} src={'https://cdn-images-1.medium.com/max/1600/1*1BMN5G5DbMoniQ3cO_Ekgw.png'} alt="Rabbit staring nervously at a big red product launch button" loading="lazy" />

              <p>12. "We're about to start, and I can't find my shoes anywhere."</p>
              <p>13. "If this works, I'm booking a holiday; if not, I'm taking a nap"</p>
              <p>14. "Do you think people are going to notice that we're only half human up from head to waist?"</p>
              <p>As long as you're launching on Zoom 🙉</p>
              <p>15. "Is it just me, or does the 'send' button feel heavy today?"</p>
            </article>

          </section>

        </main>
        <MainFooter />
      </div>
    </div>
  );
}

export default Article16;