import React, { useState, useEffect } from 'react';
import MainHeader from './MainHeader';
import CustomLink from './DumbComponents/CustomLink';
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem';
import TemplatesCarousel from './Components/TemplatesCarousel';
import MainFooter from './MainFooter';
import mainNav from './css/MainNav.module.scss';
import layout from './css/Layout.module.scss';
import blog from './css/Blog.module.scss';
import { Helmet } from 'react-helmet';

const FigmaShortcutsArticle = () => {
  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });

  useEffect(() => {
    document.title = '7 Figma Workflow Shortcuts That Will Speed You Up | Pepperform Blog';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <Helmet>
        <link rel="canonical" href="https://pepperform.net/figma-shortcuts-article" />
        <meta
          name="keywords"
          content="Figma, design, productivity, workflow, design tools, shortcuts"
        />
        <meta
          name="description"
          content="Discover 7 Figma workflow shortcuts that will accelerate your design process. Spend more time on creativity and less on clicking buttons."
        />
        <meta
          property="og:title"
          content="7 Figma Workflow Shortcuts That Will Speed You Up | Pepperform Blog"
        />
        <meta
          property="og:description"
          content="Discover 7 Figma workflow shortcuts that will accelerate your design process. Spend more time on creativity and less on clicking buttons."
        />
      </Helmet>

      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <div className={layout.body_wrapper}>
        <header className={blog.header}>
          <h1>7 Figma Workflow Shortcuts That Will Speed You Up</h1>

          <p>
            Designers, we get it; you should be spending more time bringing your UX vision to life and less time on clicking on buttons and selecting objects. Designing a product shouldn’t feel like a slow crawl through menus. That’s where these Figma shortcuts have been a lifesaver for us! So let’s dive in.
          </p>
          <img src={'https://cdn-images-1.medium.com/max/1600/1*htGvO8RCxeWzXm9ULGqGqg.png'} alt="Figma Shortcuts" loading="lazy" />
        </header>
        <main className={blog.wrapper}>
          <section className={blog.blog_post}>
            <article>
              <h2>1. Layer Arrangement</h2>
              <p>
                These object arrangement shortcuts enhance your speed when working with multiple layers. Select an object on the canvas with your mouse and use these shortcuts to adjust the layer order.
              </p>
              <p>
                <strong>Shortcuts:</strong>
              </p>
              <ul>
                <li>Bring Forward: Ctrl + ]</li>
                <li>Send Backward: Ctrl + [</li>
                <li>Bring to Front: Ctrl + Shift + ]</li>
                <li>Send to Back: Ctrl + Shift + [</li>
              </ul>
              <img width={'100%'} src={'https://cdn-images-1.medium.com/max/1600/1*vsVSYvAqcagnZqAjzsE-Hg.gif'} alt="Figma Layer Arrangement Shortcuts in Play" loading="lazy" />
            </article>

            <article>
              <h2>2. Copy Anything as a PNG</h2>
              <p>
                Need to quickly share an image on Slack or email? This shortcut helps you copy anything from Figma as a PNG in seconds. Select the object and press Cmd+Shift+C, and paste (Cmd + V) wherever you want to use your PNG. It’s a convenient method for sharing visuals without the hassle of manual file exports. Given Figma’s focus on enhancing collaboration, it’s no wonder such features exist.
              </p>
              <p>
                <strong>Shortcut:</strong> Cmd + Shift + C
              </p>
              <img width={'100%'} src={'https://cdn-images-1.medium.com/max/1600/1*blbTabN9igFaT28AVcg8pA.gif'} alt="Copying a frame as a static PNG" loading="lazy" />
            </article>

            <article>
              <h2>3. Navigating Components</h2>
              <p>
                As your design evolves, you’re likely to work with more components, making these shortcuts invaluable for navigating Figma’s component system.
              </p>
              <p>
                <strong>Shortcuts:</strong>
              </p>
              <ul>
                <li>Create Component: Alt + Ctrl + K</li>
                <li>Detach Instance: Alt + Ctrl + B</li>
                <li>Launch Team Library: Alt + Ctrl + O</li>
                <li>Show Your Assets: Alt + 2</li>
              </ul>
            </article>

            <article>
              <h2>4. Applying Auto-Layout to Object Groups</h2>
              <p>
              Select an object, then use the Shift + A shortcut to apply auto-layout to your object.
              </p>
              <p>
                <strong>Shortcut:</strong>
                Shift + A to add auto layout, Shift + Cmd + G
              </p>
              <img width={'100%'} src={'https://cdn-images-1.medium.com/max/1600/1*9kqoAwEBQAltmbGLyLIhXg.gif'} alt="Applying Autolayout" loading="lazy" />

            </article>

            <article>
              <h2>5. Configure and Set Opacity</h2>
              <p>
              Control an object's opacity with ease. Select the object whose opacity you want to modify, and type a number (e.g., 1) to set it at 10% opacity. You can combine two numbers for precise opacity (e.g., 35 for 35%).
              </p>
              <p>
                <strong>Shortcuts:</strong>
              </p>
              <ul>
                <li>1: 10% opacity</li>
                <li>3: 30% opacity</li>
                <li>35: 35% opacity</li>
                <li>4: 40% opacity</li>
                <li>0: 100% opacity</li>
              </ul>
              <img width={'100%'} src={'https://cdn-images-1.medium.com/max/1600/1*OgyMVxDdzWKpe6CR-rHKAA.gif'} alt="Altering opacity" loading="lazy" />

            </article>

            <article>
              <h2>6. Creating Objects and Shapes</h2>
              <p>
              When creating objects and shapes, these shortcuts are your time-saving allies. Instead of navigating through menus, take advantage of these handy shortcuts, especially when your design is teeming with objects.
              </p>
              <p>
                <strong>Shortcuts:</strong>
              </p>
              <ul>
                <li>R: Rectangle</li>
                <li>L: Line</li>
                <li>O: Oval or ellipse</li>
                <li>T: Text</li>
                <li>F: Frame</li>
              </ul>
              <img width={'100%'} src={'https://cdn-images-1.medium.com/max/1600/1*83xdok1-V0m1ylG6U2lFSg.gif'} alt="Altering opacity" loading="lazy" />

            </article>

            <article>
              <h2>7. Hide and Show Layout Grids</h2>
              <p>
              
              Grids are helpful in optimizing designs for reusability and responsiveness. However, having them constantly on your screen can be distracting when you’re focused on giving life to your design. These shortcuts come to the rescue, allowing you to effortlessly toggle grids on and off at your convenience.

</p>
              <p>
                <strong>Shortcut:</strong>
                 Ctrl + Shift + 4
              </p>
            </article>

            {/* Add more articles for the remaining shortcuts */}
          </section>
        </main>
        <hr style={{ borderColor: 'rgba(0,0,0,0.1)' }} />
        <MainFooter />
      </div>
    </div>
  );
}

export default FigmaShortcutsArticle;
