
export const HamburgerIcon = (props) => {
    return (
        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 1.17157 0.671573 0.5 1.5 0.5H22.5C23.3284 0.5 24 1.17157 24 2C24 2.82843 23.3284 3.5 22.5 3.5H1.5C0.671573 3.5 0 2.82843 0 2Z" fill="#353333" />
            <path d="M0 12C0 11.1716 0.671573 10.5 1.5 10.5H22.5C23.3284 10.5 24 11.1716 24 12C24 12.8284 23.3284 13.5 22.5 13.5H1.5C0.671573 13.5 0 12.8284 0 12Z" fill="#353333" />
        </svg>


    )
}

export const PepperIcon = (props) => {
    return (
        <svg width="19" height="26" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9546 6.01952C11.5343 5.84455 12.1499 5.74933 12.7888 5.74933C16.193 5.74933 18.9575 7.59692 19 10.8951C18.9453 11.4272 18.8161 12.0245 18.5927 12.6389C17.8479 14.6884 16.1828 16.645 13.2197 17.1043C9.178 17.7307 6.388 18.6845 4.46261 19.957C3.81847 20.3827 3.28166 20.8371 2.83298 21.3125C1.92611 18.9003 0.986172 15.4092 0.490076 13.5668C0.376064 13.1434 0.285502 12.807 0.224151 12.5908C0.0780499 12.0757 0 11.5331 0 10.9724C0 7.62575 2.78101 5.74929 6.21175 5.74929C6.85054 5.74929 7.4662 5.84455 8.04595 6.01946C8.13347 5.98568 8.2222 5.95407 8.3127 5.92631V4.20466C8.3127 2.28826 9.91077 0.729156 11.8753 0.729156H12.667C13.3228 0.729156 13.8545 1.24785 13.8545 1.88763C13.8545 2.52747 13.3228 3.04614 12.667 3.04614H11.8753C11.2205 3.04614 10.6878 3.56585 10.6878 4.20466V5.92636C10.7783 5.95412 10.867 5.98568 10.9546 6.01952Z" fill="#211E22" />
            <path d="M4.74964 24.6393C5.35455 25.0478 6.09283 25.2708 6.8981 25.2708C7.3682 25.2708 7.819 25.1892 8.23727 25.0403C8.60218 25.1885 9.00135 25.2708 9.42004 25.2708C9.83873 25.2708 10.2379 25.1885 10.6028 25.0403C11.0211 25.1892 11.4719 25.2708 11.942 25.2708C13.1779 25.2708 14.2559 24.7457 14.9362 23.8306C15.4464 23.1439 16.052 21.5285 16.6246 19.7292C15.7428 20.1693 14.7436 20.4986 13.6181 20.676C9.86515 21.2673 7.66653 22.1152 6.35022 22.9997C5.58217 23.5158 5.08715 24.0621 4.74964 24.6393Z" fill="#211E22" />
        </svg>


    )
}



export const LeafIcon = (props) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.68751 5.06604C-0.724991 8.47854 -0.724991 14.0035 2.66251 17.416C4.50001 13.166 7.77501 9.61604 11.8625 7.50354C8.40001 10.4285 5.97501 14.516 5.12501 19.1535C8.37501 20.691 12.375 20.1285 15.0625 17.441C19.4125 13.091 20.125 0.00354004 20.125 0.00354004C20.125 0.00354004 7.03751 0.71604 2.68751 5.06604Z" fill="rgb(33, 30, 34)" />
        </svg>
    )
}

export const EaselIcon = (props) => {
    return (
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_23_168)">
                <path d="M15.125 3.75C8.9125 3.75 3.875 8.7875 3.875 15C3.875 21.2125 8.9125 26.25 15.125 26.25C16.1625 26.25 17 25.4125 17 24.375C17 23.8875 16.8125 23.45 16.5125 23.1125C16.225 22.7875 16.0375 22.35 16.0375 21.875C16.0375 20.8375 16.875 20 17.9125 20H20.125C23.575 20 26.375 17.2 26.375 13.75C26.375 8.225 21.3375 3.75 15.125 3.75ZM8.25 15C7.2125 15 6.375 14.1625 6.375 13.125C6.375 12.0875 7.2125 11.25 8.25 11.25C9.2875 11.25 10.125 12.0875 10.125 13.125C10.125 14.1625 9.2875 15 8.25 15ZM12 10C10.9625 10 10.125 9.1625 10.125 8.125C10.125 7.0875 10.9625 6.25 12 6.25C13.0375 6.25 13.875 7.0875 13.875 8.125C13.875 9.1625 13.0375 10 12 10ZM18.25 10C17.2125 10 16.375 9.1625 16.375 8.125C16.375 7.0875 17.2125 6.25 18.25 6.25C19.2875 6.25 20.125 7.0875 20.125 8.125C20.125 9.1625 19.2875 10 18.25 10ZM22 15C20.9625 15 20.125 14.1625 20.125 13.125C20.125 12.0875 20.9625 11.25 22 11.25C23.0375 11.25 23.875 12.0875 23.875 13.125C23.875 14.1625 23.0375 15 22 15Z" fill="#211E22" />
            </g>
            <defs>
                <rect width="30" height="30" fill="white" transform="translate(0.125)" />
            </defs>
            </svg >

    )
}


export const ChromeReaderIcon = (props) => {
    return (
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_23_174)">
<path d="M12.0417 11H18.4584V12.375H12.0417V11ZM12.0417 8.70829H18.4584V10.0833H12.0417V8.70829ZM12.0417 13.2916H18.4584V14.6666H12.0417V13.2916ZM19.375 3.66663H2.87502C1.86669 3.66663 1.04169 4.49163 1.04169 5.49996V17.4166C1.04169 18.425 1.86669 19.25 2.87502 19.25H19.375C20.3834 19.25 21.2084 18.425 21.2084 17.4166V5.49996C21.2084 4.49163 20.3834 3.66663 19.375 3.66663ZM19.375 17.4166H11.125V5.49996H19.375V17.4166Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_23_174">
<rect width="22" height="22" fill="white" transform="translate(0.125)"/>
</clipPath>
</defs>
</svg>


    )
}



