import React, { useState } from 'react';


import MainHeader from './MainHeader'

import FAQ from './Components/FAQ'

import MainFooter from './MainFooter'



import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'

import privacy from './css/Privacy.module.scss'



const faqlist = [
  { title: 'Can I switch from the free plan to the premium plan at any time?', description: "Absolutely! We understand that your needs may change over time, and we want to give you the freedom to switch between our premium and free plans as per your requirements. You can easily manage your plan by logging into your account and accessing your account setting." },
  { title: 'Are there any limitations on the number of surveys or responses in the premium plan?', description: "No, there are no limitations on the number of surveys or responses you can create or collect with our premium plan. We believe in empowering our users to gather as much feedback and insights as they need, without any restrictions." },
  { title: 'Can I cancel my premium subscription at any time?', description: "Absolutely! We understand that circumstances may change, and you may need to adjust your subscription. With Pepperform, you have full control over your premium subscription, and you can cancel it at any time. Simply visit your account settings and follow the straightforward cancellation process. Please note that you will continue to have access to the premium features until the end of your current billing period, and after that, your subscription will be switched to our free plan." },
]




function TermsOfService() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        <section className={privacy.intro}>
            <h1>Terms Of Service</h1>
            <p><i>This Privacy Policy was last updated on February 15, 2023.</i></p>
            <p>These Terms of Service ("Terms") govern the use of the services provided by Pepperform ("Pepperform," "we," or "us"). By accessing or utilizing the services offered on the Pepperform website (www.pepperform.com) and any related services (referred to collectively as the "Services"), you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using the Services.</p>
        </section>


        <section  className={privacy.content}>
          
        <div>
          <h2>1. Use of Services</h2>
          <h3>1.1 Eligibility</h3>
          <p>
            To use the Services, you must meet the eligibility requirements, including being at least 18 years old and having the legal capacity to enter into a contract. By using the Services, you represent and warrant that you meet these eligibility requirements. If you are using the Services on behalf of an organization, you further represent and warrant that you have the authority to bind that organization to these Terms.
          </p>
          <h3>1.2 Account Creation</h3>
          <p>Certain features and functionalities of the Services may require you to create an account. When creating an account, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Please notify us immediately of any unauthorized use of your account.</p>
          <h3>1.3 Acceptable Use</h3>
          <p>
          You agree to use the Services in compliance with applicable laws and these Terms. You shall not:
          <ol>
            <li>Use the Services for any illegal, unauthorized, or improper purposes;</li>
            <li>Post, transmit, or distribute any content that is unlawful, harmful, defamatory, obscene, infringing, or otherwise objectionable;</li>
            <li>Engage in any activity that could interfere with or disrupt the Services or the servers and networks connected to the Services;</li>
            <li>Attempt to gain unauthorized access to any portion or feature of the Services or any other systems or networks connected to the Services;</li>
            <li>Use any automated means, such as bots or scrapers, to access or collect data from the Services without our prior written consent.</li>
          </ol>
          </p>
          <h2>2. Intellectual Property Rights</h2>
          <h3>2.1 Ownership</h3>
          <p>
          All the content, including works, trademarks, software, and other creations, displayed or provided by us, either belong to the Company or have been licensed to us by their respective owners. Unless explicitly granted by the rightful owners or permitted by applicable laws, you do not acquire any rights or ownership over the mentioned content and creations, except for the limited use of the Site and/or Services as described in these Terms of Use. Consequently, unless specifically authorized in accordance with this section or these Terms of Use, you are strictly prohibited from distributing, reproducing, copying, publicly communicating, transforming, modifying, adapting, translating, or engaging in any other form of use or exploitation of the aforementioned works.
          </p>
          <h3>2.2 Limited License</h3>
          <p>
          Subject to your compliance with these Terms, Pepperform grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for your own personal or internal business purposes.
          </p>
          <h2>3. Privacy</h2>
          <p>Our privacy practices are described in our <a href="/privacy">Privacy Policy</a>. By using the Services, you consent to our collection, use, and disclosure of personal information as set forth in the Privacy Policy.</p>
          <h2>4. Modifications to the Services</h2>
          <p>Pepperform reserves the right to modify, suspend, or discontinue the Services or any part thereof at any time without prior notice or liability. We may also impose limits on certain features or restrict your access to parts or all of the Services without notice or liability.</p>

          <h2>5. Compliance with Australia NSW Standards</h2>
          <p>Pepperform operates in compliance with the applicable standards and regulations of New South Wales, Australia. While we strive to ensure the accuracy and reliability of the Services, we do not guarantee that they fully comply with all standards and regulations outside of New South Wales.</p>

          <h2>6. Termination</h2>
          <p>These Terms will remain in effect until terminated by you or Pepperform. You may terminate these Terms by ceasing to use the Services and closing your account. Pepperform reserves the right to terminate these Terms or suspend your access to the Services at any time, for any reason, without prior notice or liability.</p>

          <h2>7. Disclaimer of Warranties</h2>
          <p>THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED.</p>
        </div>
        </section>

        {/* <FAQ headlline={"yes"} source={faqlist} /> */}
        <MainFooter />


      </main>

    </div>
  );
}

export default TermsOfService;
