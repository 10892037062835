import { gql } from '@apollo/client';
export const GET_STYLE = gql`
query($myform: String!) {
  formStyle(myform: $myform) {
      id
      font
      primaryColor
      selectionColor
      labelColor
      backgroundColor
      pagelayout
      headerTextAlign
      fieldbgcolor
      formStyle
      logo
      logosize
      logourl
      logoposition
      backgroundheadlineurl
      bgimgposition
      bgimgtintblur
      bgimgurl
      backgroundformcolor
      buttonsubmittext
      bgimgtint
      showshadowbgthankyou
      buttonprefix
      buttoncaption
      buttoncaptiontoggle
      videobackgroundurl
      videobackgroundtype
      iswizard
    }
  }
`;


export const UPDATE_STYLE = gql`
  mutation updateStyle(
    $myform: String!, 
    $font: String, 
    $primaryColor: String, 
    $selectionColor: String,
    $labelColor: String,
    $formStyle: String,
    $backgroundColor: String,
    $pagelayout: String,
    $headerTextAlign: String,
    $fieldbgcolor: String,
    $logo: String,
    $logosize: String,
    $logourl: String
    $bgimgposition: String
    $bgimgurl: String
    $backgroundformcolor: String
    $buttonsubmittext: String
    $bgimgtint: Boolean
    $showshadowbgthankyou: Boolean
    $buttonprefix: String
    $buttoncaption: String
    $buttoncaptiontoggle: Boolean
    $videobackgroundurl: String
    $videobackgroundtype: String

  ) {
    updateStyle(
      myform: $myform
      font: $font
      primaryColor: $primaryColor
      selectionColor: $selectionColor
      labelColor: $labelColor
      formStyle: $formStyle
      backgroundColor: $backgroundColor
      pagelayout: $pagelayout
      headerTextAlign: $headerTextAlign
      fieldbgcolor: $fieldbgcolor
      logo: $logo,
      logosize: $logosize
      logourl: $logourl
      bgimgposition: $bgimgposition
      bgimgurl: $bgimgurl
      backgroundformcolor: $backgroundformcolor
      buttonsubmittext: $buttonsubmittext
      bgimgtint: $bgimgtint
      showshadowbgthankyou: $showshadowbgthankyou
      buttonprefix: $buttonprefix
      buttoncaption: $buttoncaption
      buttoncaptiontoggle: $buttoncaptiontoggle
      videobackgroundtype: $videobackgroundtype
      videobackgroundurl: $videobackgroundurl
    ) {
      id
    }
  }
`;
