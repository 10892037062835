import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MainHeader from './MainHeader'
import FAQ from './Components/FAQ'
import MainFooter from './MainFooter'
import ArticleBrowserItem from './DumbComponents/ArticleBrowserItem'
import article1 from './images/mockuuups-1.jpg'
import article2 from './images/article-2.jpg'
import article3 from './images/article-3.jpg'
import article4 from './images/inspired.jpg'
import article5 from './images/cxarticle.jpg'
import article6 from './images/agilearticle.jpg'
import article7 from './images/iPad.jpg'
import article8 from './images/productmarketfit.jpg'
import article9 from './images/article-9.jpg'
import article10 from './images/product.jpg'

import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'

import blog from './css/Blog.module.scss'

const articlefeatures = [
    {title:"4 Automation and AI Fails that prove We Still Need Human Touch in Customer Success", category: "Blog", image:"https://cdn-images-1.medium.com/max/1600/1*kfr-TOzrM3Tv7_T_e42_qA.png", links: [{ title: "Read article", link: "/article/4-automation-ai-fails" }] },  
    {title:"The Ultimate No-Frills CSM Guide to Customer Onboarding", category: "Blog", image:"https://cdn-images-1.medium.com/max/1600/1*ENrbXqFP5cJrI3OpTcXefA.png", links: [{ title: "Read article", link: "/article/ultimate-customer-onboarding-guide-for-csm" }] },  
    {title:"4 Remarkable Customer Stories That Will Restore Your Faith in Humanity", category: "Blog", image:"https://cdn-images-1.medium.com/max/800/1*JJAvcWm9bSVAU833vfAuug.png", links: [{ title: "Read article", link: "/article/remarkable-customer-stories" }] },  
    {title:"15 of the Funniest Overheard Quotes During Our Product Launch D-Days", category: "Blog", image:"https://cdn-images-1.medium.com/max/1600/0*9aMrizF1MHne_0vR", links: [{ title: "Read article", link: "/article/15-funny-product-launch-quotes" }] },  
    {title:"7 Figma Workflow Shortcuts That Will Speed You Up", category: "Blog", image:"https://miro.medium.com/v2/resize:fit:720/format:webp/1*htGvO8RCxeWzXm9ULGqGqg.png", links: [{ title: "Read article", link: "/article/figma-shortcuts" }] },  
    {title:"3 Reasons Raging Customers Are a Blessing for Business Growth", category: "Blog", image:"https://miro.medium.com/v2/resize:fit:720/format:webp/1*w3uusOprnoaKlO94pmp6Mg.png", links: [{ title: "Read article", link: "/article/Raging-Customers" }] },  
    {title:"Give Your Team a Voice, Be a Better Boss", category: "Blog", image:"https://miro.medium.com/v2/resize:fit:1400/format:webp/1*1oHHHe19Hhp9nGvPkkOarg.png", links: [{ title: "Read article", link: "/article/Be-a-Better-Boss" }] },  
    {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", image:article3, links: [{ title: "Read article", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
    {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", image:article4, links: [{ title: "Read article", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
    {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", image:article8, links: [{ title: "Read article", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
    {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", image:article7, links: [{ title: "Read article", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
    {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", image:article1, links: [{ title: "Read article", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
    {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", image:article2, links: [{ title: "Read article", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
    {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", image:article5, links: [{ title: "Read article", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
    {title:"A Super Guide to Conducting Team Health Checks for High-Performing Agile Teams", category: "Blog", image:article6, links: [{ title: "Read article", link: "/article/a-super-guide-to-conducting-team-health-checks-for-high-performing-agile-teams" }] },  
    {title:"Customer Success Stories: How Forms Can Capture and Share Success Stories", category: "Blog", image:article9, links: [{ title: "Read article", link: "/article/customer-success-stories-how-forms-can-capture-and-share-success-stories" }] },  
    {title:" Inclusivity Matters: 7 Survey Questions to Unlock Actionable Insights for Inclusive Design", category: "Blog", image:article10, links: [{ title: "Read article", link: "/article/inclusivity-matters" }] },  
]
  
  

const faqlist = [
  { title: 'Can I switch from the free plan to the premium plan at any time?', description: "Absolutely! We understand that your needs may change over time, and we want to give you the freedom to switch between our premium and free plans as per your requirements. You can easily manage your plan by logging into your account and accessing your account setting." },
  { title: 'Are there any limitations on the number of surveys or responses in the premium plan?', description: "No, there are no limitations on the number of surveys or responses you can create or collect with our premium plan. We believe in empowering our users to gather as much feedback and insights as they need, without any restrictions." },
  { title: 'Can I cancel my premium subscription at any time?', description: "Absolutely! We understand that circumstances may change, and you may need to adjust your subscription. With Pepperform, you have full control over your premium subscription, and you can cancel it at any time. Simply visit your account settings and follow the straightforward cancellation process. Please note that you will continue to have access to the premium features until the end of your current billing period, and after that, your subscription will be switched to our free plan." },
]




function Articles() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });

  useEffect(() => {
    document.title = 'Explore Engaging Blog Articles for Survey Enthusiasts';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        <section className={blog.articles__intro}>
            <h1>Blog</h1>
            <p>What we've been up to</p>
        </section>
        <section className={blog.articles}>
            {articlefeatures.map((article, index)=>{
                return <article key={index}><ArticleBrowserItem article={article} context="blog-post" /></article>
              })} 
        </section>

      </main>

    </div>
  );
}

export default Articles;
