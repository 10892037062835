// import React, { useState, useEffect } from 'react';

// import Fade from 'react-reveal/Fade';

// import MainHeader from './MainHeader'
// import { Helmet } from 'react-helmet';


// import ArticleBrowser from './Components/ArticleBrowsers'
// import FAQ from './Components/FAQ'
// import TemplatesCarousel from './Components/TemplatesCarousel'
// import MainFooter from './MainFooter'



// import mainNav from './css/MainNav.module.scss'
// import layout from './css/Layout.module.scss'
// import plaintext from './css/PlainTextBlock.module.scss'
// import twocolblock from './css/TwoColBlock.module.scss'
// import featurehighlight from './css/FeatureHighlight.module.scss'


// import drag from './images/drag.gif'
// import feature_highlight from './images/hero_desktop_2.jpg'
// import mockupImage from './images/multichoice.jpg'
// import customerSupportImage from './images/hero_desktop_1.jpg'


// const articlefeatures = [
//   // {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
//   // {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
//   // {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
//   {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
//   {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
//   {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
//   {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
//   {title:"Customer Success Stories: How Forms Can Capture and Share Success Stories", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/customer-success-stories-how-forms-can-capture-and-share-success-stories" }] },  
// ]


// const faqlist = [
//   { title: 'Why use Pepperform?', description: "Pepperform is the go-to online form builder for creating engaging, and visually captivating forms. Whether you're creating interactive surveys, contact lists, shopping carts, or quizzes, Pepperform offers a versatile platform with powerful design tools and data management tools." },
//   { title: 'How do I create and send an online customer survey?', description: "Creating and sending an online customer survey with Pepperform is a simple and efficient process. Start by signing in to your account or creating a new one if you don't have an existing account. Once logged in, click on the Create Form button to begin building your survey. You have the option to start from scratch or use a pre-designed template. Customize your survey by adding various types of questions to gather the desired information. Personalize the survey's appearance by customizing the branding, including your logos and colors. Once your survey is ready, click on the Share button to start collecting responses. You can easily track and monitor the survey results in real-time through your reporting dashboard." },
//   { title: 'How much does Pepperform cost?', description: "We offer both free and premium plans, allowing you to choose the option that best fits your requirements." },
//   { title: 'What is the benefit of an online survey builder?', description: "Online surveys are a game-changer for those who want to do their own research. They're a time and money saver, delivering speedy results. You don't need developers or tech skills to get your survey up and ready to share. With just a few clicks, you can create engaging surveys that perfectly fit your needs." },
//   { title: 'How can Pepperform help improve our customer satisfaction levels?', description: "By collecting their insights, you gain a deeper understanding of their needs and preferences, allowing you to tailor your products, services, and customer experiences to meet their expectations. Pepperform offers a comprehensive set of features and tools designed to enhance customer satisfaction. With our intuitive survey builder, you can create engaging and personalized surveys to gather valuable feedback from your customers." },
//   { title: 'What support options are available if we encounter any issues with Pepperform?', description: "At Pepperform, we prioritize customer support and ensure that assistance is readily available when you need it. Our dedicated support team is ready to help you with any questions, technical issues, or concerns you may have." },
// ]


// function SolutionRSVP() {

//   const [state, setNav] = useState({ isDrawerOpen: false, section: '' });

//   useEffect(() => {
//     document.title = 'Elevate Events with our User-Friendly Survey Builder';
//   }, []);

//   return (
//     <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
//     <Helmet>
//         <link rel="canonical" href="https://pepperform.net/Solution/RSVP-Forms" />
//         <meta name="description" content="Elevate your event experience with RSVP forms that capture attendee insights and preferences. Create unforgettable gatherings with Pepperform's user-friendly online survey builder tool." />
//         <meta property="og:title" content="Elevate Events with our User-Friendly Survey Builder" />
//         <meta property="og:description" content="Transform events with RSVP forms powered by Pepperform. Capture attendee insights, preferences, and bookings using our free online survey builder tool." />
//     </Helmet>

//       <MainHeader page="features" setNav={setNav} state={state} />
//       <main className={layout.body_wrapper}>
//         <section className={plaintext.containerHero + " " + plaintext.hero}>
//           <h2>RSVP forms</h2>
//           <Fade up distance={"12px"}>
//             <h1>Surveys to Elevate Your Event Game</h1>
//           </Fade>
//           <Fade delay={500}>
//             <h3>Book Your Customers' RSVP with Ease

// </h3>
//             <p>Our free online survey builder tool makes it a breeze to design professional and customized surveys without breaking a sweat. Whether it's an epic party, a networking extravaganza, or a heartwarming family gathering, we've got you covered!</p>
//           </Fade>
//         </section>

//         <section className={featurehighlight.wrapper} style={{ backgroundColor: "#98522a" }}>
//         <div className={featurehighlight.container}>
//             <div className={featurehighlight.images}>
//               <img src={feature_highlight} alt="Feature highlight" loading="lazy" />
//             </div>
//             <header className={featurehighlight.header}>
//               <Fade up distance={"32px"}>
//                 <h2>Create RSVP forms for free</h2>
//               </Fade>
//               <Fade up distance={"32px"} delay="250">
//                 <h3 style={{lineHeight:1.65}}>RSVP: Répondez s'il vous plaît - Reply If You Please!</h3>
//               <p>An event RSVP form is your key to unlocking event success. Born from the French phrase "Répondez s'il vous plaît," RSVP forms are your modern-day enchanters, making event planning a delightful experience. From parties to conferences, weddings to meetups, these forms help you gather essential attendee information, ensuring that your event is meticulously crafted for each and every guest.</p>
//               </Fade>
//             </header>

//           </div>

//           <div className={featurehighlight.containerRight +" "+ featurehighlight.container}>

//             <header className={featurehighlight.header}>
//               <Fade down><h2>RSVP Your Way: Craft, Customize, and Build for Free</h2></Fade>
//               <Fade left distance="32px">
//                 <p>Ready to rock your RSVP game? Create surveys online that are as unique as your event itself. From funky color schemes to personalized questions, our platform lets you build a survey tailored to your event's vibe. Customize surveys online to your heart's content – it's your canvas, and we're just here to make it epic!</p>
//               </Fade>
//               <Fade left distance="32px" delay="250">
//                 <h3>Seamless and Stylish: Your RSVP Form, Your Way</h3>
//                 <p>Why settle for basic when you can go bold? Our RSVP Forms give you the power to customize surveys and design them to perfection. Your attendees will love the seamless experience, and you'll love how your event's brand shines through every question. It's your show, and we're here to make it unforgettable!</p>
//               </Fade>
//               <Fade left distance="32px" delay="250">
//                 <h3>Elevate Engagement: The Ultimate RSVP Tool</h3>
//                 <p>Say goodbye to dull spreadsheets and hello to interactive engagement! Our RSVP Forms are more than just surveys – they're the ultimate tool to captivate your audience. From quirky multiple-choice questions to delightful image uploads, you'll gather insights while keeping your attendees entertained.</p>
//               </Fade>
//             </header>

//           </div>
//             <div className={featurehighlight.fullImage}>
//               <img src={mockupImage} alt="Feature highlight" loading="lazy" />
//             </div> 

//         </section>
//         <section className={twocolblock.container + " " + twocolblock.left_image}>

//           <div className={twocolblock.image_area} style={{ backgroundColor: "#153330" }}>
//             <div className={twocolblock.image}>
//               <img src={drag} alt="Customise to your heart's content" />
//             </div>
//             <div className={twocolblock.image_description_container}>
//               <div className={twocolblock.image_description}>
//                 <h2>24</h2>
//                 <p>Question types</p>
//               </div>
//               <div className={twocolblock.image_description}>
//                 <h2>Unlimited</h2>
//                 <p>Responses</p>
//               </div>
//             </div>
//           </div>
//           <div className={twocolblock.description_area}>
//               <Fade left distance="32px">
//                 <h2>Crafting Your Event's Guest List</h2>
//               </Fade>
//               <Fade left distance="32px" delay="250">
//                 <h3>"The details are not the details. They make the design." - Charles Eames</h3>
//                 <p>
//                 An event RSVP form lets you create magic by capturing attendee names, email addresses, and even their intentions to grace your event. With this information, you can weave an enchanting experience that leaves your guests dazzled.
//                 </p>
//               </Fade>
//           </div>

//         </section>
//         <TemplatesCarousel title="booking" />

//         <section className={twocolblock.container + " " + twocolblock.right_image} >
          
//           <div className={twocolblock.description_area}>
//             <h2>Effortless Event Planning</h2>
//             <p>With RSVP forms, event planning becomes a seamless journey. Keep track of RSVPs, gauge attendee numbers, and make informed decisions on everything from seating arrangements to catering. No more last-minute surprises; only well-orchestrated, enchanting events that leave a lasting impression.</p>
//           </div>
//           <div className={twocolblock.image_area} style={{ backgroundColor: '#a4c3c4' }}>
//             <div className={twocolblock.image +" "+twocolblock.image_full }>
//               <img src={customerSupportImage} alt="Customer support" loading='lazy' />
//             </div>

//           </div>

//         </section>
//         <FAQ source={faqlist} />
//         <MainFooter />

//       </main>

//     </div>
//   );
// }

// export default SolutionRSVP;

import React, { useState, useEffect } from 'react';

import Fade from 'react-reveal/Fade';

import MainHeader from './MainHeader'
import { Helmet } from 'react-helmet';

import reportImage from './images/custom.png'

import ArticleBrowser from './Components/ArticleBrowsers'
import FAQ from './Components/FAQ'
import TemplatesCarousel from './Components/TemplatesCarousel'
import MainFooter from './MainFooter'
import styles from "./css/NewHomepage.module.scss";
import unlimited from "./images/unlimited.svg";
import notification from "./images/notification.svg";
import lifebuoy from "./images/lifebuoy.svg";
import formIcon from "./images/contact-form.svg";

import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'
import plaintext from './css/PlainTextBlock.module.scss'
import twocolblock from './css/TwoColBlock.module.scss'
import featurehighlight from './css/FeatureHighlight.module.scss'


import drag from './images/fields.gif'
import feature_highlight from './images/mockuuups-1.jpg'
import mockupImage from './images/event.jpg'
import customerSupportImage from './images/hero_desktop_1.jpg'


const articlefeatures = [
  // {title:"5 Customer Insights for a Winning Marketing and Sales Strategy", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-customer-insights-for-a-winning-marketing-and-sales-strategy" }] },  
  // {title:"7 Essential Customer Insights Every Product Manager Should Gather - Insights Inspired by Marty Cagan's Book – Inspired", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-essential-customer-insights-every-product-manager-should-gather---insights-inspired-by-marty-cagans-book-inspired" }] },  
  // {title:"36 Best Survey Questions to Explore Product-Market Fit: Unveiling the Key to Success", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/36-best-survey-questions-to-explore-product-market-fit-unveiling-the-key-to-success" }] },  
  {title:"6 Questions to Increase Customer Conversion: A Must-Have Guide for Onboarding New Customers", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/6-questions-to-increase-customer-Conversion-a-must-have-guide-for-onboarding-new-customers" }] },  
  {title: "7 Typical customer feedback questions to ask in an NPS survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/7-typical-customer-feedback-questions-to-ask-in-an-NPS-survey" }] },  
  {title:"8 powerful questions to ask in your next customer satisfaction survey", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/8-powerful-questions-to-ask-in-your-next-customer-satisfaction-survey" }] },
  {title:"5 Game-Changing Questions Used by the Best Customer Managers to Transform Customer Touchpoints", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/5-game-changing-questions-used-by-the-best-customer-managers-to-transform-customer-touchpoints" }] },  
  {title:"Customer Success Stories: How Forms Can Capture and Share Success Stories", category: "Blog", links: [{ title: "NPS Survey Template", link: "/article/customer-success-stories-how-forms-can-capture-and-share-success-stories" }] },  
]


const faqlist = [
  {
    "title": "How can Pepperform enhance my event planning process?",
    "description": "Pepperform simplifies event planning by offering custom survey solutions. You can collect attendee preferences, opinions, and feedback, helping you tailor your events to perfection."
  },
  {
    "title": "Can I use Pepperform for real-time audience engagement during my events?",
    "description": "Yes, Pepperform allows you to engage your audience with live surveys and polls. Keep the energy high and gather instant feedback to make your events more interactive and memorable."
  },
  {
    "title": "What happens after my event is over?",
    "description": "Pepperform's post-event surveys are invaluable. They help you measure your event's impact, collect valuable insights, and identify areas for improvement. This data ensures your future events are even better."
  },
  {
    "title": "Can I customize the look and feel of my event surveys?",
    "description": "Absolutely. Pepperform provides extensive customization options. You can brand your surveys with logos and colors to match your event's theme and aesthetics."
  },
  {
    "title": "How can Pepperform help me optimize my event's success?",
    "description": "Pepperform empowers you to gather data and feedback at every stage of your event, enabling data-driven decisions. With insights from pre-event, live, and post-event surveys, you can optimize your event for maximum impact."
  },
  {
    "title": "Is Pepperform suitable for events of all sizes?",
    "description": "Yes, Pepperform is versatile and adaptable, making it suitable for events of all scales, from small gatherings to large conferences and festivals."
  }
];



function SolutionRSVP() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });
  const [scrollY, setScrollY] = useState(0);
  const [thumbnails, setThumbnails] = useState([]); // Define the thumbnails state variable
  const [browserHeight, setBrowserHeight] = useState(false); // Initialize with false for each word

  useEffect(() => {

    // Add a scroll event listener to update the scrollY state
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const abc = window.innerHeight;
      setBrowserHeight(abc);
    };

    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    document.title = 'Transform Contact Forms into a Brand Experience';
  }, []);

  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <div className={featurehighlight.content_solution}>
     <Helmet>
         <link rel="canonical" href="https://pepperform.net/Solution/RSVP-Forms" />
         <meta name="description" content="Elevate your event experience with RSVP forms that capture attendee insights and preferences. Create unforgettable gatherings with Pepperform's user-friendly online survey builder tool." />
         <meta property="og:title" content="Run Events with the Pepperform Survey Maker" />
         <meta property="og:description" content="Transform events with RSVP forms powered by Pepperform. Capture attendee insights, preferences, and bookings using our free online survey builder tool." />
     </Helmet>
        <MainHeader  scrolled={scrollY > 100 && true} page="solution" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        <section 
        style={{backgroundSize:'cover', backgroundPosition:'center',background:`url('https://images.unsplash.com/photo-1618556658017-fd9c732d1360?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2864&q=80')`}}
        className={plaintext.containerHero + " " + plaintext.hero}>
          <div className={plaintext.containerHero__content}>
            <div>
              <h2>RSVP forms</h2>
              <h1>Collect Insights For Your Event </h1>
            </div>
            <div>
              {/* <h3>Effortless Feedback Capture with Customizable Contact Form Templates</h3> */}
              <p>Our free online survey builder tool makes it a breeze to design professional and customized surveys in an instant. Whether it's an epic party, or a networking extravaganza we've got you covered!</p>
            </div>
          </div>
        </section>

        <section className={featurehighlight.wrapper}>
        {/* <div className={featurehighlight.container}>
            <div className={featurehighlight.images}>
              <img src={feature_highlight} alt="Feature highlight" loading="lazy" />
            </div>
            <header className={featurehighlight.header}>
              <Fade up distance={"32px"}>
                <h2>Give your customer a voice</h2>
              </Fade>
              <Fade up distance={"32px"} delay="250">
                <h3 style={{lineHeight:1.65}}>Unveiling the Power of Contact Forms: Direct Dialogue, Deeper Connection</h3>
              <p>Imagine your online product as a bustling marketplace of ideas, where visitors' thoughts can be heard through the medium of contact forms. This provides an avenue for individuals to engage with your brand on their own terms, without the pressure of immediate response.</p>
              <p>Our contact forms permit visitors to communicate with your brand based on their conditions and act as a platform for genuine connections. By bridging the gap between you and your customers, this open discussion uncovers preferences, issues, and objectives that can guide your items and strengthen your brand.
                
              </p>
              <p><a style={{color:"white"}} href="https://app.pepperform.net/templates">View this template</a> to see what you can create with our Survey Builder tool.</p>

              </Fade>
            </header>

          </div> */}

          <div className={featurehighlight.container} style={{paddingBottom:'0'}}>
          <Fade down><h2>Optimize and Be Data-Driven in Your Next Event with Branded Custom Surveys</h2>
          <img className={featurehighlight.image_report} src={reportImage} alt="Picture highlighting the Pepperform Survey Maker Editing Feature" loading="lazy" />
          </Fade>

          </div>

          <header className={featurehighlight.feature_content}>
            <div>
            <Fade left distance="32px">
                <h3>Pre–event Data Collection</h3>
                <p>Before the event even begins, gather valuable insights and preferences from your attendees. Tailor your event to their expectations and ensure a memorable experience from the get-go.</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>Collect Data During Event</h3>
                <p>Maximize engagement and interaction during your event with real-time surveys. Pepperform allows you to collect instant feedback, conduct live polls, and gauge audience sentiment.</p>
              </Fade>
            </div>
            <div>
            <Fade left distance="32px" delay="250">
                <h3>Post event data collection</h3>
                <p>The event isn't over when it ends. With Pepperform's post-event surveys, you can continue to gather feedback and measure the impact of your event. Analyze data, assess attendee satisfaction, and identify areas for improvement.</p>
              </Fade>
            </div>
            </header>

            <div className={featurehighlight.fullImage}>
              <img src={mockupImage} alt="A person viewing their responsive Pepperform Survey Slideshow in a tablet iPad" loading="lazy" />
            </div> 
            <div className={`${styles.screenWidePanel}`}>
        <div>
          <div
            className={styles.screenWidePanel_content__small}

          >
            <h2>Our Freemium Doesn't Suck</h2>
            <p>
              A lot of free online survey form services these days are too
              limited. You're pushed to upgrade to paid plans to access even
              basic features. At Pepperform, we provide you with access to a
              free, unlimited version that includes all the meaningful features.
            </p>
          </div>
          <ul className={styles.freemiumDetails}>
            <li>
              <img src={formIcon} />
              <p>
                <strong>5 Free Forms</strong>
                Create 5 forms for free, no questions asked.
              </p>
            </li>
            <li>
              <img src={unlimited} />
              <p>
                <strong>Unlimited Responses</strong>
                Collect as many responses as you need
              </p>
            </li>
            <li>
              <img src={notification} />
              <p>
                <strong>Be notified</strong>
                Access the full reporting features
              </p>
            </li>
            <li>
              <img src={lifebuoy} />
              <p>
                <strong>Be supported</strong>
                Our support team is here to help
              </p>
            </li>
          </ul>
          <a href="https://app.pepperform.net/register" className={styles.primaryCta}>Sign up FREE</a>
          <span className={styles.primaryCta_helper}>
            No credit card required
          </span>
        </div>
      </div>
        </section>
        <section className={twocolblock.container + " " + twocolblock.left_image}>

          <div className={twocolblock.image_area} style={{ backgroundColor: "#D8B863" }}>
            <div className={twocolblock.image}>
              <img style={{margin:"20px"}} src={'https://pepper-uploads.s3.ap-southeast-2.amazonaws.com/upload_images/templates/rsvp.jpg'} alt="An image of a Pepperform Event RSVP Survey Template" />
            </div>
            <div className={twocolblock.image_description_container}>
              <div className={twocolblock.image_description}>
                <h2>24</h2>
                <p>Question types</p>
              </div>
              <div className={twocolblock.image_description}>
                <h2>Unlimited</h2>
                <p>Responses</p>
              </div>
            </div>
          </div>
          <div className={twocolblock.description_area}>
              <Fade left distance="32px">
                <h2>Start with a Template or Craft it all from scratch</h2>
              </Fade>
              <Fade left distance="32px" delay="250">
                <p style={{paddingTop:"80px"}}>
                With our Event Survey Templates, you can streamline the process of gathering attendee feedback. Design surveys tailored to your specific event needs and collect valuable insights throughout the entire event journey.

                <a href="https://app.pepperform.net/templates">Browse our Survey Form Templates</a>
                </p>
              </Fade>
          </div>

        </section>
        {/* <ArticleBrowser articles={articlefeatures} /> */}
        {/* <TemplatesCarousel title="feedback" /> */}

        <section className={twocolblock.container + " " + twocolblock.right_image} >
          
          <div className={twocolblock.description_area}>
            <h2>Engage your audience, tell a story</h2>
            <p>Pepperform empowers you to step beyond the ordinary and transform your event surveys into compelling narratives. With our platform, it's not just about gathering data; it's about telling your event's story. Effortlessly incorporate text, images, and videos into your surveys to captivate your attendees and convey your event's message with impact.

            <a href="https://app.pepperform.net/templates">Browse our Survey Form Templates</a>

              .</p>
          </div>
          <div className={twocolblock.image_area} style={{ backgroundColor: '#a4c3c4' }}>
            <div className={twocolblock.image +" "+twocolblock.image_full }>
              <img src={customerSupportImage} alt="Pepperform Survey on a Desktop app" loading='lazy' />
            </div>

          </div>

        </section>
        <FAQ source={faqlist} />
        <MainFooter />

      </main>
      </div>
    </div>
  );
}

export default SolutionRSVP;
