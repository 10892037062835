import React, { useState } from 'react';


import MainHeader from './MainHeader'

import FAQ from './Components/FAQ'

import MainFooter from './MainFooter'



import mainNav from './css/MainNav.module.scss'
import layout from './css/Layout.module.scss'

import privacy from './css/Privacy.module.scss'



const faqlist = [
  { title: 'Can I switch from the free plan to the premium plan at any time?', description: "Absolutely! We understand that your needs may change over time, and we want to give you the freedom to switch between our premium and free plans as per your requirements. You can easily manage your plan by logging into your account and accessing your account setting." },
  { title: 'Are there any limitations on the number of surveys or responses in the premium plan?', description: "No, there are no limitations on the number of surveys or responses you can create or collect with our premium plan. We believe in empowering our users to gather as much feedback and insights as they need, without any restrictions." },
  { title: 'Can I cancel my premium subscription at any time?', description: "Absolutely! We understand that circumstances may change, and you may need to adjust your subscription. With Pepperform, you have full control over your premium subscription, and you can cancel it at any time. Simply visit your account settings and follow the straightforward cancellation process. Please note that you will continue to have access to the premium features until the end of your current billing period, and after that, your subscription will be switched to our free plan." },
]




function Privacy() {

  const [state, setNav] = useState({ isDrawerOpen: false, section: '' });


  return (
    <div className={state.isDrawerOpen === true ? mainNav.frozePage : 'App'}>
      <MainHeader scrolled={true} page="features" setNav={setNav} state={state} />
      <main className={layout.body_wrapper}>
        <section className={privacy.intro}>
            <h1>Privacy Policy</h1>
            <i>This Privacy Policy was last updated on June 5, 2023.</i>
        </section>


        <section  className={privacy.content}>
          <h2>At Pepperform, we prioritize the protection of your privacy.</h2>
          <p>This Privacy Policy outlines how Pepperform collects, uses, stores, shares, and safeguards your personal information in relation to your usage of our platform available through the www.pepperform.net domain (referred to as the "Site") and the services provided by us, including form creation and related services (referred to collectively as the "Services"). Furthermore, this Privacy Policy explains how we handle information about individuals who directly or indirectly provide services to us, or individuals who have contacted us or whom we need to contact due to conflicts or issues with our Services or Company.</p>
          <p>Please refer to the policy below if you are using our Services and are interested in understanding how we process any personal data we collect on your behalf.</p>
          <p>For respondents (individuals who have completed a form, quiz, or questionnaire created on our platform), please note that we are not responsible for the processing of data. We simply provide services to the person or organization that sent you the form. We recommend carefully reviewing the terms, conditions, and privacy policy of the entity or person who sent you the form, as they govern the processing of your personal data. If you have any questions, please contact that person or organization directly. Also, depending on how the sender has configured the form, your data may be shared or made public. To learn more, please contact the entity or person who sent you the form.</p>
        <div>
          
          <h3>Customers: How We Process Your Data</h3>
          <p>
          As mentioned earlier, if you use our Services or Site by creating an Account, this Privacy Policy outlines how we process your personal data. While using the Site, you are not required to provide any personal information unless you choose to access features that require such information (such as subscribing to a newsletter). However, using the Services requires signing up and creating an account, as detailed in the Service Terms and Conditions.
          </p>
          <p>
          Any personal information you provide us while using the Site and/or Services is subject to this Privacy Policy, and you will be prompted to read and accept it.
          </p>
            <h3>Who processes personal information? (Data controller)</h3>
            <p>
            Personal information is processed by Pepperform, a company dedicated to providing form creation and related services. You can reach us at the following contact details:
            <br />Pepperform <br />
            info@pepperform.com
            </p>
          <h3>Purposes of data processing, legal basis, and storage periods</h3>
          <p>
          We process your data when necessary to fulfill a contract, and we will process your data as long as the contractual relationship with you remains in effect and for a period of five years following the end of said relationship. This includes processing your data to provide you with the Services and fulfill our obligations under the Services Terms and Conditions.
          </p>
          <p>
          With your consent, and unless you withdraw such consent, we may also process your data for the following purposes:
          <ol>
            <li>Sending you electronic commercial communications (if you subscribe to a newsletter) or responding to your inquiries when you contact us;</li>
            <li>Processing information obtained through cookies, as detailed in the Cookie Policy, and subject to the terms outlined therein;</li>
            <li>Obtaining confirmation of your identity and other information from third-party social media platforms if you choose to log in or sign up through them;</li>
            <li>Profiling based on your behavior and browsing patterns to improve our Services and personalize content;</li>
            <li>Enriching your data by obtaining information from select third parties with your permission.</li>

          </ol>
          We may also process your data to comply with legal obligations, protect our legitimate interests, and perform statistical analysis. Data processed for legal obligations will be retained for the period required by applicable laws
          </p>
          <h3>Access to personal information</h3>
          <p>We may share your information with third-party service providers who assist us in providing the Services, subject to their adherence to our privacy and security standards. Additionally, we may share your data with other companies if you provide explicit consent when requested. In such cases, the sharing of data and its subsequent processing will be governed by the terms and conditions specified during the consent process. If you consent to the use of cookies, your data may be processed by third parties as detailed in the Cookie Policy.</p>
          <p>We may also disclose your information to competent courts and authorities as required by law or to protect our rights or the rights of third parties. If you choose to make the results of a form public, the specific responses provided by respondents may be shared with the parties you select.</p>

          <h3>Territorial scope of processing</h3>
          <p>Your data may be transferred, processed, and stored in countries with different data protection laws than those of your jurisdiction. By agreeing to this Privacy Policy and submitting your information, you acknowledge and consent to such transfers, processing, and storage of your data.</p>

          <p>This Privacy Policy is designed to help you understand how we collect, use, store, share, and protect your personal information. Please review it carefully, and if you have any questions or concerns, feel free to contact us.




</p>
        </div>
        </section>

        {/* <FAQ headlline={"yes"} source={faqlist} /> */}
        <MainFooter />


      </main>

    </div>
  );
}

export default Privacy;
